// Core react imports
import React, {
  useEffect,
  useState,
  Fragment,
  useCallback,
  useRef,
} from "react";
// Mui imports
import {
  Button,
  Checkbox,
  Radio,
  ClickAwayListener,
  FormControl,
  FormControlLabel,
  FormGroup,
  Typography,
  TextField,
  Grid,
  Box,
} from "@material-ui/core";
// Redux imports
import { useDispatch, useSelector } from "react-redux";
import { fetchCategories } from "../../../redux/category/categoryActions";
// Custom components and icon imports
import Toast from "../../../components/Layout/Toast";
import { DownArrowIcon } from "../../../icons";
import { CancelOutlined, ChevronRight } from "@material-ui/icons";
// util imports
import { getStoreIdFromUrl } from "../../../utils/storeUtil";
import useStyles from "../styles";
import { rowFlexAlignCenter } from "../../../styles/common";
import PaymentMethodFilter from "./filters/PaymentMethodFilter";

// Declaring component
const ProductFilter = ({
  filterSlugs,
  setFilterSlugs,
  onFilterApply,
  filterValues,
  setFilterValues,
  sortBy,
  setSortBy,
  onSortChange,
  filterStatus,
  setFilterStatus,
  filterMode,
  setFilterMode,
  filterMinQty,
  filterMaxQty,
  setFilterQty,
  inputKeyword,
}) => {
  const classes = useStyles();
  const [openCatSheet, setOpenCatSheet] = useState(false);
  const [openStatusSheet, setOpenStatusSheet] = useState(false);
  const [openSort, setOpenSort] = useState(false);
  const [openStockSheet, setOpenStockSheet] = useState(false);
  const storeId = getStoreIdFromUrl();
  const state = useSelector((state) => state);
  const [selectedCategories, setSelectedCategories] = useState(
    new Map(filterValues)
  );
  const isFirstRender = useRef(true);
  const categories = state.categories.categories;
  let parentCategories = categories.filter((cat) => cat.level === 0);
  let childCategories = categories.filter((cat) => cat.level !== 0);
  let catMap = {};
  childCategories.forEach((childCat) => {
    if (catMap.hasOwnProperty(childCat.root_cat_slug)) {
      catMap[childCat.root_cat_slug].push(childCat.slug);
    } else {
      catMap[childCat.root_cat_slug] = [childCat.slug];
    }
  });
  const [focusedCat, setFocusedCat] = useState(null);
  const [reload, setReload] = useState(false);
  const [status, setStatus] = useState(filterStatus);
  const [mode, setMode] = useState(filterMode);
  const [minQty, setMinQty] = useState(filterMinQty);
  const [maxQty, setMaxQty] = useState(filterMaxQty);
  const [isCustomStock, setIsCustomStock] = useState(false);
  const [qtyError, setQtyError] = useState(false);
  const dispatch = useDispatch();
  const sortCriterias = [
    { display_name: "Recently Added", query: "createdAt,desc" },
    { display_name: "Price: High to Low", query: "salePrice,desc" },
    { display_name: "Price: Low to High", query: "salePrice,asc" },
    { display_name: "Alphabetically", query: "displayName,asc" },
    { display_name: "Quantity: High to Low", query: "qty,desc" },
    { display_name: "Quantity: Low to High", query: "qty,asc" },
  ];

  useEffect(() => {
    if (categories.length === 0) {
      dispatch(fetchCategories());
    }
    // eslint-disable-next-line
  }, [storeId]);

  useEffect(() => {
    setMaxQty(filterMaxQty);
    setMinQty(filterMinQty);
    // eslint-disable-next-line
  }, [filterMaxQty, filterMinQty]);

  const onFilterClick = useCallback(() => {
    if (minQty && maxQty && minQty > maxQty) {
      setQtyError(true);
      return;
    }
    let tempSelectedSlugs = [];
    selectedCategories.forEach((val, key) => {
      if (val.length === 0) {
        tempSelectedSlugs.push(key);
      } else {
        tempSelectedSlugs = [...tempSelectedSlugs, ...val];
      }
    });
    dispatch(setFilterSlugs(tempSelectedSlugs));
    dispatch(setFilterStatus(status));
    dispatch(setFilterMode(mode));
    setFilterValues(selectedCategories);
    setFocusedCat(null);
    dispatch(setFilterQty(minQty, maxQty));
    onFilterApply(tempSelectedSlugs, status, mode, minQty, maxQty);
    // eslint-disable-next-line
  }, [status, mode, minQty, maxQty, selectedCategories]);

  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
      return;
    }
    if (minQty !== "" && maxQty !== "" && !isCustomStock) {
      onFilterClick();
    }
    // eslint-disable-next-line
  }, [status, mode, minQty, maxQty, selectedCategories]);

  return (
    <div
      style={{
        display: "flex",
        marginLeft: 16,
        flex: 1,
        justifyContent: "space-between",
      }}
    >
      <div style={{ display: "flex" }}>
        <ClickAwayListener
          onClickAway={() => {
            setOpenCatSheet(false);
          }}
        >
          <div style={{ position: "relative" }}>
            <Button
              className={classes.selector}
              style={{
                color: filterSlugs.length > 0 ? "#1A1A1A" : "#999999",
                outline: "none",
                whiteSpace: "nowrap",
              }}
              endIcon={<DownArrowIcon stroke="#1A1A1A" />}
              onClick={() => {
                setOpenCatSheet(true);
              }}
            >
              {filterSlugs.length > 0 ? (
                <Box
                  className={rowFlexAlignCenter}
                  padding="6px 4px"
                  border="1px solid rgba(153,153,153,0.5)"
                  borderRadius="4px"
                >
                  <span
                    style={{
                      fontSize: "12px",
                      lineHeight: "16px",
                      color: "#000",
                    }}
                  >
                    {filterSlugs.length}&nbsp; Categories
                  </span>
                  <CancelOutlined
                    htmlColor="#1614DB"
                    style={{ fontSize: "16px", marginLeft: "6px" }}
                    onClick={(e) => {
                      e.stopPropagation();
                      setFocusedCat(null);
                      setSelectedCategories(new Map());
                    }}
                  />
                </Box>
              ) : (
                "Category"
              )}
            </Button>
            <div
              className={classes.catSheet}
              style={{
                visibility: openCatSheet ? "visible" : "hidden",
              }}
            >
              {parentCategories.length > 0 && (
                <div style={{ display: "flex" }}>
                  <div>
                    <FormControl
                      style={{
                        width: 194,
                        maxHeight: 400,
                        overflow: "overlay",
                      }}
                    >
                      <FormGroup className="custom-scrollbar">
                        {parentCategories.map((cat, i) => {
                          return (
                            <Fragment key={i}>
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    checked={selectedCategories.has(cat.slug)}
                                    onChange={(e) => {
                                      if (e.target.checked) {
                                        setReload(!reload);
                                        setSelectedCategories(
                                          selectedCategories.set(cat.slug, [])
                                        );
                                        setFocusedCat(cat);
                                      } else {
                                        let temp = selectedCategories;
                                        temp.delete(cat.slug);
                                        setSelectedCategories(temp);
                                        setReload(!reload);
                                        setFocusedCat(null);
                                      }
                                    }}
                                    name={cat.slug}
                                    color="secondary"
                                  />
                                }
                                style={{
                                  margin: 0,
                                  fontWeight: 400,
                                  background:
                                    focusedCat !== cat ? "#FFFFFF" : "#F5F7FC",
                                  borderBottom: "1px solid #F6F6F6",
                                }}
                                label={
                                  <div
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                      justifyContent: "space-between",
                                    }}
                                  >
                                    <span
                                      style={{
                                        fontWeight:
                                          focusedCat !== cat ? 400 : 600,
                                      }}
                                    >
                                      {cat.name}
                                    </span>
                                    {focusedCat !== cat && catMap[cat.slug] ? (
                                      <ChevronRight />
                                    ) : (
                                      <></>
                                    )}
                                  </div>
                                }
                                classes={{ label: classes.label }}
                                key={i}
                              />
                            </Fragment>
                          );
                        })}
                      </FormGroup>
                    </FormControl>
                    <Box
                      display="flex"
                      alignItems="center"
                      justifyContent="space-between"
                      padding="12px"
                      borderTop="1px solid #E6E6E6"
                    >
                      <Typography
                        style={{
                          fontSize: 12,
                          fontWeight: 400,
                          backgroundColor: "#1641DB",
                          color: "white",
                          padding: "5px 15px",
                          borderRadius: "4px",
                        }}
                        role="button"
                        onClick={() => {
                          onFilterClick();
                          setOpenCatSheet(false);
                        }}
                      >
                        Apply
                      </Typography>
                      <Typography
                        color="secondary"
                        style={{
                          fontSize: 12,
                          fontWeight: 400,
                        }}
                        role="button"
                        onClick={() => {
                          setFocusedCat(null);
                          setSelectedCategories(new Map());
                          setOpenCatSheet(false);
                        }}
                      >
                        Clear All
                      </Typography>
                    </Box>
                  </div>
                  {focusedCat && catMap[focusedCat.slug] && (
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        background: "#FFFFFF",
                        width: 194,
                        borderRadius: 6,
                        maxHeight: 442,
                        overflow: "overlay",
                      }}
                    >
                      <FormControl>
                        <FormGroup>
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={
                                  selectedCategories.has(focusedCat.slug) &&
                                  selectedCategories.get(focusedCat.slug)
                                    .length === 0
                                }
                                onChange={(e) => {
                                  if (e.target.checked) {
                                    setSelectedCategories(
                                      selectedCategories.set(
                                        focusedCat.slug,
                                        []
                                      )
                                    );
                                    setReload(!reload);
                                  } else {
                                    let temp = selectedCategories;
                                    temp.delete(focusedCat.slug);
                                    setFocusedCat(null);
                                    setSelectedCategories(temp);
                                    setReload(!reload);
                                  }
                                }}
                                color="secondary"
                              />
                            }
                            style={{
                              margin: 0,
                              backgroundColor:
                                selectedCategories.has(focusedCat.slug) &&
                                selectedCategories.get(focusedCat.slug)
                                  .length === 0
                                  ? "#F5F7FC"
                                  : "#FFF",
                              borderBottom: "1px solid #F6F6F6",
                            }}
                            label="All Products"
                            classes={{ label: classes.label }}
                          />

                          {childCategories.map((childCat, i) => {
                            if (childCat.root_cat_slug !== focusedCat.slug)
                              return <Fragment key={i} />;
                            return (
                              <FormControlLabel
                                key={i}
                                control={
                                  <Checkbox
                                    checked={selectedCategories
                                      .get(focusedCat.slug)
                                      .includes(childCat.slug)}
                                    onChange={(e) => {
                                      if (e.target.checked) {
                                        setSelectedCategories(
                                          selectedCategories.set(
                                            focusedCat.slug,
                                            [
                                              ...selectedCategories.get(
                                                focusedCat.slug
                                              ),
                                              childCat.slug,
                                            ]
                                          )
                                        );
                                        setReload(!reload);
                                      } else {
                                        let temp = selectedCategories.get(
                                          focusedCat.slug
                                        );
                                        temp = temp.filter(
                                          (t) => t !== childCat.slug
                                        );
                                        setSelectedCategories(
                                          selectedCategories.set(
                                            focusedCat.slug,
                                            temp
                                          )
                                        );
                                        setReload(!reload);
                                      }
                                    }}
                                    name={childCat.slug}
                                    color="secondary"
                                  />
                                }
                                style={{
                                  margin: 0,
                                  backgroundColor: selectedCategories
                                    .get(focusedCat.slug)
                                    .includes(childCat.slug)
                                    ? "#F5F7FC"
                                    : "#FFF",
                                  borderBottom: "1px solid #F6F6F6",
                                }}
                                label={childCat.name}
                                classes={{ label: classes.label }}
                              />
                            );
                          })}
                        </FormGroup>
                      </FormControl>
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>
        </ClickAwayListener>
        <ClickAwayListener
          onClickAway={() => {
            setOpenStatusSheet(false);
          }}
        >
          <div style={{ position: "relative", marginLeft: 10 }}>
            <Button
              className={classes.selector}
              style={{
                color: filterStatus ? "#1A1A1A" : "#999999",
                outline: "none",
                minWidth: 93,
                whiteSpace: "nowrap",
              }}
              endIcon={<DownArrowIcon stroke="#1A1A1A" />}
              onClick={() => {
                setOpenStatusSheet(true);
              }}
            >
              {filterStatus ? (
                filterStatus === "online" ? (
                  <Box
                    className={rowFlexAlignCenter}
                    padding="6px 4px"
                    border="1px solid rgba(153,153,153,0.5)"
                    borderRadius="4px"
                  >
                    <span
                      style={{
                        fontSize: "12px",
                        lineHeight: "16px",
                        color: "#000",
                      }}
                    >
                      Online
                    </span>
                    <CancelOutlined
                      htmlColor="#1614DB"
                      style={{ fontSize: "16px", marginLeft: "6px" }}
                      onClick={(e) => {
                        e.stopPropagation();
                        setStatus(null);
                      }}
                    />
                  </Box>
                ) : (
                  <Box
                    className={rowFlexAlignCenter}
                    padding="6px 4px"
                    border="1px solid rgba(153,153,153,0.5)"
                    borderRadius="4px"
                  >
                    <span
                      style={{
                        fontSize: "12px",
                        lineHeight: "16px",
                        color: "#000",
                      }}
                    >
                      Offline
                    </span>
                    <CancelOutlined
                      htmlColor="#1614DB"
                      style={{ fontSize: "16px", marginLeft: "6px" }}
                      onClick={(e) => {
                        e.stopPropagation();
                        setStatus(null);
                      }}
                    />
                  </Box>
                )
              ) : (
                "Status"
              )}
            </Button>
            <div
              className={classes.catSheet}
              style={{
                visibility: openStatusSheet ? "visible" : "hidden",
              }}
            >
              <FormControl style={{ width: 153, padding: 4 }}>
                <FormGroup
                  style={{
                    background: status === "online" ? "#F5F7FC" : "transparent",
                    borderRadius: 6,
                  }}
                >
                  <FormControlLabel
                    control={
                      <Radio
                        checked={status === "online"}
                        onChange={(e) => {
                          if (e.target.checked) {
                            setStatus("online");
                          } else {
                            setStatus(null);
                          }
                        }}
                        name="online"
                        color="secondary"
                      />
                    }
                    style={{
                      margin: 0,
                      fontWeight: 400,
                      borderBottom: "1px solid #F6F6F6",
                    }}
                    label="Online"
                    classes={{ label: classes.label }}
                  />
                </FormGroup>
                <FormGroup
                  style={{
                    background:
                      status === "offline" ? "#F5F7FC" : "transparent",
                    borderRadius: 6,
                  }}
                >
                  <FormControlLabel
                    control={
                      <Radio
                        checked={status === "offline"}
                        onChange={(e) => {
                          if (e.target.checked) {
                            setStatus("offline");
                          } else {
                            setStatus(null);
                          }
                        }}
                        name="offline"
                        color="secondary"
                      />
                    }
                    style={{
                      margin: 0,
                      fontWeight: 400,
                    }}
                    label="Offline"
                    classes={{ label: classes.label }}
                  />
                </FormGroup>
              </FormControl>
            </div>
          </div>
        </ClickAwayListener>
        <ClickAwayListener
          onClickAway={() => {
            setOpenStockSheet(false);
          }}
        >
          <div style={{ position: "relative", marginLeft: 10 }}>
            <Button
              className={classes.selector}
              style={{
                color:
                  filterMinQty !== null || filterMaxQty !== null
                    ? "#1A1A1A"
                    : "#999999",
                outline: "none",
                minWidth: 140,
                maxWidth: 140,
                lineHeight: "15px",
              }}
              endIcon={<DownArrowIcon stroke="#1A1A1A" />}
              onClick={() => {
                setOpenStockSheet(true);
              }}
            >
              {filterMinQty !== null || filterMaxQty !== null ? (
                filterMinQty !== null && filterMaxQty !== null ? (
                  <Box
                    className={rowFlexAlignCenter}
                    padding="6px 4px"
                    border="1px solid rgba(153,153,153,0.5)"
                    borderRadius="4px"
                    whiteSpace="noWrap"
                  >
                    <span
                      style={{
                        fontSize: "12px",
                        lineHeight: "16px",
                        color: "#000",
                      }}
                    >
                      Custom
                    </span>
                    <CancelOutlined
                      htmlColor="#1614DB"
                      style={{ fontSize: "16px", marginLeft: "6px" }}
                      onClick={(e) => {
                        e.stopPropagation();
                        setMinQty(null);
                        setMaxQty(null);
                      }}
                    />
                  </Box>
                ) : filterMaxQty !== null ? (
                  <Box
                    className={rowFlexAlignCenter}
                    padding="6px 4px"
                    border="1px solid rgba(153,153,153,0.5)"
                    borderRadius="4px"
                    whiteSpace="noWrap"
                  >
                    <span
                      style={{
                        fontSize: "12px",
                        lineHeight: "16px",
                        color: "#000",
                      }}
                    >
                      Out of Stock
                    </span>
                    <CancelOutlined
                      htmlColor="#1614DB"
                      style={{ fontSize: "16px", marginLeft: "6px" }}
                      onClick={(e) => {
                        e.stopPropagation();
                        setMinQty(null);
                        setMaxQty(null);
                      }}
                    />
                  </Box>
                ) : (
                  <Box
                    className={rowFlexAlignCenter}
                    padding="6px 4px"
                    border="1px solid rgba(153,153,153,0.5)"
                    borderRadius="4px"
                    whiteSpace="noWrap"
                  >
                    <span
                      style={{
                        fontSize: "12px",
                        lineHeight: "16px",
                        color: "#000",
                      }}
                    >
                      In Stock
                    </span>
                    <CancelOutlined
                      htmlColor="#1614DB"
                      style={{ fontSize: "16px", marginLeft: "6px" }}
                      onClick={(e) => {
                        e.stopPropagation();
                        setMinQty(null);
                        setMaxQty(null);
                      }}
                    />
                  </Box>
                )
              ) : (
                "Stock"
              )}
            </Button>
            <div
              className={classes.catSheet}
              style={{
                visibility: openStockSheet ? "visible" : "hidden",
              }}
            >
              <FormControl style={{ width: 200, padding: 4 }}>
                <FormGroup
                  style={{
                    background:
                      minQty === null && maxQty === 0
                        ? "#F5F7FC"
                        : "transparent",
                    borderRadius: 6,
                  }}
                >
                  <FormControlLabel
                    control={
                      <Radio
                        checked={minQty === null && maxQty === 0}
                        onChange={(e) => {
                          setIsCustomStock(false);
                          if (e.target.checked) {
                            setMinQty(null);
                            setMaxQty(0);
                          } else {
                            setMinQty(null);
                            setMaxQty(null);
                          }
                        }}
                        name="online"
                        color="secondary"
                      />
                    }
                    style={{
                      margin: 0,
                      fontWeight: 400,
                      borderBottom: "1px solid #F6F6F6",
                    }}
                    label="Out of stock"
                    classes={{ label: classes.label }}
                  />
                </FormGroup>
                <FormGroup
                  style={{
                    background:
                      minQty === 1 && maxQty === null
                        ? "#F5F7FC"
                        : "transparent",
                    borderRadius: 6,
                  }}
                >
                  <FormControlLabel
                    control={
                      <Radio
                        checked={minQty === 1 && maxQty === null}
                        onChange={(e) => {
                          setIsCustomStock(false);
                          if (e.target.checked) {
                            setMinQty(1);
                            setMaxQty(null);
                          } else {
                            setMinQty(null);
                            setMaxQty(null);
                          }
                        }}
                        name="offline"
                        color="secondary"
                      />
                    }
                    style={{
                      margin: 0,
                      fontWeight: 400,
                      borderBottom: "1px solid #F6F6F6",
                    }}
                    label="In stock"
                    classes={{ label: classes.label }}
                  />
                </FormGroup>
                <FormGroup
                  style={{
                    background:
                      minQty !== null && maxQty !== null
                        ? "#F5F7FC"
                        : "transparent",
                    borderRadius: 6,
                  }}
                >
                  <FormControlLabel
                    control={
                      <Radio
                        checked={minQty !== null && maxQty !== null}
                        onChange={(e) => {
                          setIsCustomStock(true);
                          if (e.target.checked) {
                            setMinQty("");
                            setMaxQty("");
                          } else {
                            setMinQty(null);
                            setMaxQty(null);
                          }
                        }}
                        name="offline"
                        color="secondary"
                      />
                    }
                    style={{
                      margin: 0,
                      fontWeight: 400,
                    }}
                    label="Custom"
                    classes={{ label: classes.label }}
                  />
                </FormGroup>
                {minQty !== null && maxQty !== null && (
                  <Grid container>
                    <Grid item xs={6} style={{ padding: 8 }}>
                      <Typography
                        style={{
                          fontSize: 12,
                          fontWeight: 600,
                          marginBottom: 4,
                        }}
                      >
                        Min
                      </Typography>
                      <TextField
                        variant="outlined"
                        type="number"
                        style={{ background: "#FFFFFF" }}
                        inputProps={{
                          style: { fontSize: 12 },
                          onClick: (event) => event.target.select(),
                        }}
                        value={minQty}
                        onChange={(e) => setMinQty(Number(e.target.value))}
                        color="secondary"
                      />
                    </Grid>
                    <Grid item xs={6} style={{ padding: 8 }}>
                      <Typography
                        style={{
                          fontSize: 12,
                          fontWeight: 600,
                          marginBottom: 4,
                        }}
                      >
                        Max
                      </Typography>
                      <TextField
                        variant="outlined"
                        type="number"
                        style={{ background: "#FFFFFF" }}
                        inputProps={{
                          style: { fontSize: 12 },
                          onClick: (event) => event.target.select(),
                        }}
                        value={maxQty}
                        onChange={(e) => setMaxQty(Number(e.target.value))}
                        color="secondary"
                      />
                    </Grid>
                    <Grid item xs={12} style={{ padding: 8 }}>
                      <Button
                        variant="contained"
                        className={classes.applyBtn}
                        onClick={() => {
                          setIsCustomStock(false);
                          setOpenStockSheet(false);
                          onFilterClick();
                        }}
                      >
                        Apply
                      </Button>
                    </Grid>
                  </Grid>
                )}
              </FormControl>
            </div>
          </div>
        </ClickAwayListener>
        <PaymentMethodFilter filterMethod={mode} setFilterMethod={setMode} />

        <Button
          className={classes.clearBtn}
          onClick={() => {
            dispatch(setFilterSlugs([]));
            setSelectedCategories(new Map());
            setFilterValues(new Map(), null, null, null);
            onFilterApply([], null, null, null, true);
            setFocusedCat(null);
            setStatus(null);
            setMinQty(null);
            setMaxQty(null);
            dispatch(setFilterStatus(null));
            dispatch(setFilterQty(null, null));
            dispatch(setFilterMode(null));
          }}
          disabled={
            filterSlugs !== null &&
            filterSlugs.length === 0 &&
            !filterStatus &&
            filterMode === null &&
            filterMinQty === null &&
            filterMaxQty === null &&
            inputKeyword !== null &&
            !inputKeyword.length
          }
          style={{ outline: "none" }}
        >
          Clear
        </Button>
      </div>
      <ClickAwayListener onClickAway={() => setOpenSort(false)}>
        <div style={{ position: "relative" }}>
          <Button
            style={{ minWidth: 88, marginLeft: 10 }}
            className={classes.selector}
            onClick={() => setOpenSort(true)}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                width: "100%",
              }}
            >
              <div style={{ textAlign: "left" }}>
                <Typography
                  style={{
                    color: "#999999",
                    fontSize: 12,
                    fontWeight: 400,
                    outline: "none",
                  }}
                >
                  Sort By
                </Typography>
              </div>
              <span>
                <DownArrowIcon stroke="#1A1A1A" />
              </span>
            </div>
          </Button>
          {openSort && (
            <div
              className={classes.catSheet}
              style={{ flexDirection: "column", width: 156, left: -8 }}
            >
              {sortCriterias.map((filter, i) => {
                return (
                  <div
                    style={{
                      padding: "8px 10px",
                      cursor: "pointer",
                    }}
                    className={classes.hover}
                    onClick={() => {
                      setOpenSort(false);
                      onSortChange(filter.query);
                      dispatch(setSortBy(filter.query));
                    }}
                    key={i}
                  >
                    <Typography
                      style={{
                        fontSize: 12,
                        fontWeight: sortBy === filter.query ? 600 : 500,
                      }}
                    >
                      {filter.display_name}
                    </Typography>
                  </div>
                );
              })}
            </div>
          )}
        </div>
      </ClickAwayListener>
      <Toast
        open={qtyError}
        close={() => setQtyError(false)}
        severity="error"
        message="Max quantity can't be less than min quantity"
      />
    </div>
  );
};
// Exporting component as default
export default ProductFilter;
