import React from "react";
import ChatWindow from "./components/ChatWindow";
import styles from "./index.module.css";
import ChatBubble from "./components/ChatBubble";
import { useOutputData } from "./hooks/useOutputData";

const Right = () => {
  const { cssData } = useOutputData();
  return (
    <div className={styles.container} style={{ flex: "none" }}>
      <style dangerouslySetInnerHTML={{ __html: cssData }}></style>
      <ChatWindow />
      <ChatBubble />
    </div>
  );
};

export default Right;
