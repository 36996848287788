import { useContext } from "react";
import { ChatWeightContext } from "../../../context/Context";

export const useRevalidate = () => {
  const {
    formData,
    onBlur,
    chatBubbleStates: { bubbleTextConfig, bubbleText, bubbleTextDispatch },
  } = useContext(ChatWeightContext);

  const reValidator = () => {
    let isAllValid = true;

    Object.entries(formData).forEach(([key, entry]) => {
      isAllValid =
        onBlur({
          target: {
            name: key,
            value: entry.value,
          },
        }) && isAllValid;
    });

    /* Bubble Text Validation */
    // eslint-disable-next-line eqeqeq
    if (bubbleTextConfig.bubble_text == true) {
      if (bubbleText.value.trim() === "") {
        bubbleTextDispatch((bubbleText) => ({
          ...bubbleText,
          error: "This field is required",
        }));
        isAllValid = false;
      } else {
        bubbleTextDispatch((bubbleText) => ({
          ...bubbleText,
          error: null,
        }));
        isAllValid = true && isAllValid;
      }
      // eslint-disable-next-line eqeqeq
    } else if (bubbleTextConfig.bubble_text == false) {
      bubbleTextDispatch((bubbleText) => ({
        ...bubbleText,
        error: null,
      }));
      isAllValid = true && isAllValid;
    }

    return isAllValid;
  };

  return { reValidator };
};
