import { useContext, useState } from "react";
import { useDispatch } from "react-redux";
import { postPluginConfig } from "../../../../../../../redux/apps/appActions";
import { ChatWeightContext } from "../../../context/Context";
import { useOutputHtml } from "./useOutputHtml";
import { useRevalidate } from "./useRevalidate";

export const useHandleSave = () => {
  const [showSuccess, setShowSuccess] = useState(false);
  const [showError, setShowError] = useState(false);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const {
    formData,
    chatWindowStates: { chatWindowColors, textFont },
    chatBubbleStates: {
      chatBubbleColors,
      chatBubblePosition,
      bubbleStyle,
      bubbleTextConfig,
      bubbleText,
    },
    pluginEnabled,
  } = useContext(ChatWeightContext);
  const { reValidator } = useRevalidate();

  const html = useOutputHtml();

  const handleSave = async () => {
    if (reValidator()) {
      try {
        setLoading(true);
        dispatch(
          postPluginConfig("whatsapp-chat-bubble", {
            html: pluginEnabled ? html : "",
            profile_icon: formData.profile_icon.value,
            name: formData.name.value,
            welcome_message: formData.welcome_message.value,
            button_text: formData.button_text.value,
            font_family: textFont,
            plugin_enabled: pluginEnabled,
            whatsapp_number: formData.whatsapp_number.value,
            bubble_position: chatBubblePosition,
            bubble_style: bubbleStyle,
            bubble_text: bubbleText.value,
            bubble_text_enabled: bubbleTextConfig.bubble_text,
            extend_bubble_when_hovered:
              bubbleTextConfig.extend_bubble_when_hovered,
            ...Object.fromEntries(
              Object.entries(chatWindowColors).map(([key, value]) => [
                key,
                value.value,
              ])
            ),
            ...Object.fromEntries(
              Object.entries(chatBubbleColors).map(([key, value]) => [
                key,
                value.value,
              ])
            ),
          })
        ).then((cobj) => {
          if (cobj.error) {
            setShowError(true);
          } else {
            setShowSuccess(true);
          }
          setLoading(false);
        });
      } catch (error) {
        setLoading(false);
        setShowError(true);
      }
    }
  };

  return {
    handleSave,
    showSuccess,
    showError,
    loading,
    setShowSuccess,
    setShowError,
  };
};
