import React, { useEffect } from "react";
import { CircularProgress, Typography } from "@material-ui/core";
import Controls from "../../../../components/controls/Controls";
import useStyles from "../../components/ConfigStyles";
import { useState } from "react";
import { TemplateForm } from "./TemplateForm";
import CombinedButtons from "../../../../components/buttons/CombinedButtons";
import { postPluginConfig } from "../../../../redux/apps/appActions";
import { useDispatch, useSelector } from "react-redux";
import Toast from "../../../../components/Layout/Toast";
import { useHistory } from "react-router-dom";
import {
  FieldExist,
  getChannelsOptions,
  getInitialParameters,
  headerFormat,
  messageTemplates,
  requestChannels,
  requestLanguage,
  requestParams,
  requestTemplates,
  requestText,
  requestresult,
} from "./BaatHelper";

export const SettingsMessage = () => {
  const classes = useStyles();
  const history = useHistory();

  const initialState = {
    order_placed: {
      show: false,
      parameters: {},
      template_name: "",
    },
    order_confirmed: {
      show: false,
      parameters: {},
      template_name: "",
    },
    order_dispatched: {
      show: false,
      parameters: {},
      template_name: "",
    },
    order_delivered: {
      show: false,
      parameters: {},
      template_name: "",
    },
    otp_message: {
      show: false,
      parameters: {},
      template_name: "",
    },
    order_cancelled: {
      show: false,
      parameters: {},
      template_name: "",
    },
    abandoned_cart: {
      show: false,
      parameters: {},
      template_name: "",
    },
    new_order_received: {
      show: false,
      parameters: {},
      template_name: "",
    },
  };

  const [channels, setChannels] = useState([]);
  const [selectChannel, setSelectChannel] = useState({ channel: "none" });
  const [templates, setTemplates] = useState([]);
  const [templatesData, setTemplatesData] = useState(initialState);

  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();
  const appState = useSelector((state) => state.apps);

  const [showError, setShowError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [showSuccess, setShowSuccess] = useState(false);

  useEffect(() => {
    requestChannels(appState).then((data) => {
      setChannels(data);
      if (appState.baat.channel_id) {
        setSelectChannel({
          channel: appState.baat.channel_id,
        });
      }
    });
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (selectChannel.channel !== "none") {
      setLoading(true);
      requestTemplates(appState, selectChannel.channel)
        .then((data) => {
          setTemplates(data);
          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
        });
      setTemplatesData(requestresult(appState, templatesData));
    }
    // eslint-disable-next-line
  }, [selectChannel.channel]);

  const onSwitchChange = (e) => {
    let name = e.target.name;
    let value = e.target.checked;
    let tempData = templatesData[e.target.name];
    if (tempData.show) {
      setTemplatesData({
        ...templatesData,
        [name]: { ...tempData, show: value, template_name: "", parameters: {} },
      });
    } else {
      setTemplatesData({
        ...templatesData,
        [name]: { ...tempData, show: value },
      });
    }
  };

  const onTemplateChange = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    let tempData = templatesData[e.target.name];
    setTemplatesData({
      ...templatesData,
      [name]: {
        ...tempData,
        template_name: value,
        parameters: {
          ...getInitialParameters(templates, name, value),
        },
      },
    });
  };

  const onParamsChange = (e) => {
    let name = e.target.name;
    let templateId = name.split("|")[0];
    let tempData = templatesData[templateId];
    setTemplatesData({
      ...templatesData,
      [templateId]: {
        ...tempData,
        parameters: {
          ...tempData.parameters,
          [name]: { value: e.target.value, error: false },
        },
      },
    });
  };

  const formValiadtion = () => {
    let error = false;
    let errorMap = {};
    Object.keys(templatesData).forEach((template) => {
      if (templatesData[template].show) {
        if (templatesData[template].template_name === "") {
          errorMap[template] = true;
          error = true;
        }
        Object.keys(templatesData[template].parameters).forEach((param) => {
          if (templatesData[template].parameters[param].value === "") {
            errorMap[template + "^" + param] = true;
            error = true;
          }
        });
      }
    });

    if (Object.keys(errorMap).length > 0) {
      let newTemplateData = { ...templatesData };
      Object.keys(errorMap).forEach((key) => {
        let toks = key.split("^");
        if (toks.length > 1) {
          newTemplateData[toks[0]].parameters[toks[1]].error = true;
          error = true;
        } else {
          newTemplateData[key].error = true;
          error = true;
        }
      });
      setTemplatesData(newTemplateData);
    }
    return error;
  };

  const makePayload = () => {
    const returnSendDuration = (template) => {
      let obj = {
        days:
          templatesData[template].parameters["abandoned_cart|TIME|{{2}}"]
            .value === "days"
            ? parseInt(
                templatesData[template].parameters["abandoned_cart|TIME|{{1}}"]
                  .value
              )
            : 0,

        hours:
          templatesData[template].parameters["abandoned_cart|TIME|{{2}}"]
            .value === "hours"
            ? parseInt(
                templatesData[template].parameters["abandoned_cart|TIME|{{1}}"]
                  .value
              )
            : 0,
        mintues:
          templatesData[template].parameters["abandoned_cart|TIME|{{2}}"]
            .value === "mintues"
            ? parseInt(
                templatesData[template].parameters["abandoned_cart|TIME|{{1}}"]
                  .value
              )
            : 0,
      };
      return obj;
    };

    let payload = {};
    payload["X-API-KEY"] = appState.baat["X-API-KEY"];
    payload["channel_id"] = selectChannel.channel;

    Object.keys(templatesData).forEach((template) => {
      if (templatesData[template].show) {
        payload[template] = JSON.stringify({
          message: {
            template_name: templatesData[template].template_name,

            ...(FieldExist(
              templates,
              templatesData[template].template_name,
              "BODY"
            ) && {
              body: requestText(
                templates,
                templatesData[template].template_name,
                "BODY"
              ),
              body_params: requestParams(templatesData, template, "BODY"),
            }),

            ...(FieldExist(
              templates,
              templatesData[template].template_name,
              "HEADER"
            ) && {
              header: {
                format: headerFormat(
                  templates,
                  templatesData[template].template_name
                ),
                ...(headerFormat(
                  templates,
                  templatesData[template].template_name
                ) === "TEXT" && {
                  text: requestText(
                    templates,
                    templatesData[template].template_name,
                    "HEADER"
                  ),
                }),
              },
              header_params: requestParams(templatesData, template, "HEADER"),
            }),

            ...(FieldExist(
              templates,
              templatesData[template].template_name,
              "BUTTONS"
            ) && {
              buttons: requestText(
                templates,
                templatesData[template].template_name,
                "BUTTONS"
              ),
              button_params: requestParams(templatesData, template, "BUTTONS"),
            }),

            language_code: requestLanguage(
              templates,
              templatesData[template].template_name
            ),
          },
          ...(template === "abandoned_cart" && {
            send_duration: returnSendDuration(template),
          }),
        });
      }
    });

    Object.keys(appState.baat).forEach((key) => {
      if (
        key !== "X-API-KEY" &&
        key !== "channel_id" &&
        key !== "type" &&
        payload.hasOwnProperty(key) === false
      ) {
        payload[key] = null;
      }
    });

    return payload;
  };

  const handleOutlined = () => {
    history.goBack();
  };

  const handleSave = async () => {
    let isError = formValiadtion();
    if (!isError) {
      let payload = makePayload();

      dispatch(postPluginConfig("baat", payload)).then((cobj) => {
        if (cobj.error) {
          setShowError(true);
          setErrorMessage(cobj.error);
        } else {
          setShowSuccess(true);
        }
      });
    }
  };

  return (
    <>
      <Controls.Select
        name="channel"
        label="Select Message Channel"
        labelPlacement="top"
        value={selectChannel.channel}
        placeholder="Select Channel"
        imgClass={classes.imgClass}
        onChange={(e) => setSelectChannel({ channel: e.target.value })}
        options={getChannelsOptions(channels)}
      />

      {loading && (
        <div className={classes.loading}>
          <CircularProgress style={{ color: "#1641db" }} />
        </div>
      )}

      {!loading && (
        <>
          {selectChannel.channel !== "none" && (
            <div>
              <Typography className={classes.formSubHead}>
                Messages settings
              </Typography>
              {messageTemplates.map((template) => (
                <TemplateForm
                  template={template}
                  templates={templates}
                  templateData={templatesData[template.id]}
                  onTemplateChange={onTemplateChange}
                  onParamsChange={onParamsChange}
                  onSwitchChange={onSwitchChange}
                  key={template.id}
                />
              ))}
            </div>
          )}
        </>
      )}

      <div className={classes.button}>
        <CombinedButtons
          outlinedBtnText="Back"
          outlinedBtnAction={handleOutlined}
          solidBtnAction={handleSave}
        />
      </div>

      <Toast
        message={errorMessage ? errorMessage : "Settings Message did not save"}
        open={showError}
        close={() => setShowError(false)}
        severity="error"
        autoClose
      />
      <Toast
        message={"Settings Message Saved successfully"}
        open={showSuccess}
        close={() => setShowSuccess(false)}
        autoClose
      />
    </>
  );
};
