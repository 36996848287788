import React from "react";
import { ChatWeightContext } from "./Context";
import { useContextState } from "./useContextState";

const Provider = ({ children }) => {
  const value = useContextState();
  return (
    <ChatWeightContext.Provider value={value}>
      {children}
    </ChatWeightContext.Provider>
  );
};

export default Provider;
