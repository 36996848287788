import React from "react";
import { ClickAwayListener, makeStyles } from "@material-ui/core";
import OutlinedButton from "../buttons/OutlinedButton";
import { ExpandLess, ExpandMore } from "@material-ui/icons";
import FixedToast from "./FixedToast";

const useStyles = makeStyles(() => ({
  bulkAction: {
    fontWeight: 400,
    fontSize: 12,
    borderRadius: 6,
    padding: 9,
    cursor: "pointer",
    "&:hover": {
      background: "#F5F7FC",
    },
    display: "flex",
    alignItems: "center",
  },
  sheet: {
    position: "absolute",
    top: 50,
    display: "flex",
    borderRadius: 6,
    background: "#FFFFFF",
    overflow: "hidden",
    zIndex: 1,
    boxShadow: "0px 1px 2px rgba(0, 0, 0, 0.25)",
  },
}));

const BulkActions = ({ disabled, list, toastTxt }) => {
  const classes = useStyles();
  const [openDropdown, setOpenDropdown] = React.useState(false);
  const [showToast, toggleToast] = React.useState(false);

  return (
    <ClickAwayListener
      onClickAway={() => {
        setOpenDropdown(false);
      }}
    >
      <div style={{ position: "relative" }}>
        <OutlinedButton
          style={{
            width: 150,
            ...(disabled && {
              borderColor: "#C4C4C4",
              color: "#666666",
            }),
          }}
          endIcon={openDropdown ? <ExpandLess /> : <ExpandMore />}
          onClick={() => {
            if (disabled) {
              toggleToast(true);
              return;
            }
            toggleToast(false);
            setOpenDropdown(!openDropdown);
          }}
        >
          Bulk Actions
        </OutlinedButton>
        {showToast && (
          <FixedToast
            text={toastTxt}
            onAnimationEnd={() => {
              toggleToast(false);
            }}
          />
        )}
        <div
          className={classes.sheet}
          style={{
            visibility: openDropdown ? "visible" : "hidden",
            display: "block",
            width: "100%",
            padding: 4,
            top: 54,
          }}
        >
          {list.map((obj, i) => {
            return (
              <div
                key={i}
                className={classes.bulkAction}
                onClick={(e) => {
                  obj.onClick(e);
                  setOpenDropdown(false);
                }}
              >
                {obj.icon} <span style={{ marginLeft: 12 }}>{obj.name}</span>
              </div>
            );
          })}
        </div>
      </div>
    </ClickAwayListener>
  );
};

export default BulkActions;
