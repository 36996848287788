import { createColor } from "material-ui-color";
import { useState } from "react";

export const useChatWindowState = () => {
  const [chatWindowColors, setChatWindowColors] = useState({
    header_bg_color: {
      label: "Header Background Color",
      value: createColor("#01816A").css.backgroundColor,
      touched: false,
      error: "",
      name: "header_bg_color",
      color: createColor("#01816A"),
    },
    header_text_color: {
      label: "Header Text Color",
      value: createColor("#ffffff").css.backgroundColor,
      touchec: false,
      error: "",
      name: "header_text_color",
      color: createColor("#ffffff"),
    },
    button_bg_color: {
      label: "Button Background Color",
      value: createColor("#4DC247").css.backgroundColor,
      touchec: false,
      error: "",
      name: "button_bg_color",
      color: createColor("#4DC247"),
    },
    button_text_color: {
      label: "Button Text Color",
      value: createColor("#ffffff").css.backgroundColor,
      touchec: false,
      error: "",
      name: "button_text_color",
      color: createColor("#ffffff"),
    },
    footer_bg_color: {
      label: "Footer Background Color",
      value: createColor("#ffffff").css.backgroundColor,
      touchec: false,
      error: "",
      name: "footer_bg_color",
      color: createColor("#ffffff"),
    },
  });

  const [textFont, setTextFont] = useState("Roboto");

  /* Color */
  const chatWidgetColorDispatch = (name, payload) => {
    setChatWindowColors((chatWindowColors) => ({
      ...chatWindowColors,
      [name]: {
        ...chatWindowColors[name],
        ...payload,
      },
    }));
  };

  return {
    chatWindowColors,
    chatWidgetColorDispatch,
    setChatWindowColors,
    textFont,
    textFontDispatch: setTextFont,
  };
};
