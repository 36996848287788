import React from "react";
import BigCardIcon from "../../icons/BIgCardIcon";
import ListCardIcon from "../../icons/ListCardIcon";
import TwoCardIcon from "../../icons/TwoCardIcon";

export const getNoticeColor = (color) => {
  switch (color?.toLowerCase()) {
    case "#f30020":
      return {
        noticeBackgroundColor: "#DCDCDC",
        noticeColor: "#000000",
      };

    case "#f6740a":
      return {
        noticeBackgroundColor: "#33363D",
        noticeColor: "#FFFFFF",
      };

    case "#296fc2":
      return {
        noticeBackgroundColor: "#296fc2",
        noticeColor: "#FFFFFF",
      };

    case "#fbb4c2":
      return {
        noticeBackgroundColor: "#fbb4c2",
        noticeColor: "#FFFFFF",
      };

    case "#f33396":
      return {
        noticeBackgroundColor: "#f33396",
        noticeColor: "#FFFFFF",
      };

    case "#e02858":
      return {
        noticeBackgroundColor: "#e02858",
        noticeColor: "#FFFFFF",
      };

    case "#2a5ee1":
      return {
        noticeBackgroundColor: "#FFFFFF",
        noticeColor: "#2a5ee1",
      };

    case "#654321":
      return {
        noticeBackgroundColor: "#654321",
        noticeColor: "#FFFFFF",
      };

    case "#444444":
      return {
        noticeBackgroundColor: "#444444",
        noticeColor: "#FFFFFF",
      };
    case "#29a56c":
      return {
        noticeBackgroundColor: "#E8FFF1",
        noticeColor: "#29a56c",
      };
    case "#f6740c":
      return {
        noticeBackgroundColor: "#3A3E46",
        noticeColor: "#FFFFFF",
      };
    default:
      return {
        noticeBackgroundColor: "#FFFFFF",
        noticeColor: color,
      };
  }
};

// Homepage select options

export const catTypeOptions = [
  {
    img: "https://img.clevup.in/_static/app-img/ct-grid.png",
    title: "Grid Based",
    id: "GRID_BASED",
  },
  {
    img: "https://img.clevup.in/_static/app-img/ct-scroll.png",
    title: "Single Row with Scroll",
    id: "SINGLE_ROW_WITH_SCROLL",
  },
];

export const catLayMobOptions = [
  {
    img: "https://img.clevup.in/_static/app-img/cl-mob-2c.png",
    title: "2 Column",
    id: "2",
  },
  {
    img: "https://img.clevup.in/_static/app-img/cl-mob-3c.png",
    title: "3 Column",
    id: "3",
  },
  {
    img: "https://img.clevup.in/_static/app-img/cl-mob-4c.png",
    title: "4 Column",
    id: "4",
  },
  {
    img: "https://img.clevup.in/_static/app-img/cl-mob-5c.png",
    title: "5 Column",
    id: "5",
  },
];

export const prodLayDesktopOptions = [
  {
    id: "2",
    title: "2 Columns",
  },
  {
    id: "3",
    title: "3 Columns",
  },
  {
    id: "4",
    title: "4 Columns",
  },
  {
    id: "5",
    title: "5 Columns",
  },
  {
    id: "6",
    title: "6 Columns",
  },
  {
    id: "7",
    title: "7 Columns",
  },
  {
    id: "8",
    title: "8 Columns",
  },
];

export const prodLayMobileOptions = [
  {
    id: "1",
    title: "1 Column",
  },
  {
    id: "2",
    title: "2 Columns",
  },
];

export const catLayDeskOptions = [
  {
    img: "https://img.clevup.in/_static/app-img/cl-desk-2c.png",
    title: "2 Column",
    id: "2",
  },
  {
    img: "https://img.clevup.in/_static/app-img/cl-desk-3c.png",
    title: "3 Column",
    id: "3",
  },
  {
    img: "https://img.clevup.in/_static/app-img/cl-desk-4c.png",
    title: "4 Column",
    id: "4",
  },
  {
    img: "https://img.clevup.in/_static/app-img/cl-desk-5c.png",
    title: "5 Column",
    id: "5",
  },
  {
    img: "https://img.clevup.in/_static/app-img/cl-desk-6c.png",
    title: "6 Column",
    id: "6",
  },
  {
    img: "https://img.clevup.in/_static/app-img/cl-desk-7c.png",
    title: "7 Column",
    id: "7",
  },
  {
    img: "https://img.clevup.in/_static/app-img/cl-desk-8c.png",
    title: "8 Column",
    id: "8",
  },
  {
    img: "https://img.clevup.in/_static/app-img/cl-desk-9c.png",
    title: "9 Column",
    id: "9",
  },
  {
    img: "https://img.clevup.in/_static/app-img/cl-desk-10c.png",
    title: "10 Column",
    id: "10",
  },
];

export const deskWidthOptions = [
  {
    img: "https://img.clevup.in/_static/app-img/cl-desk-4c.png",
    title: "Boxed",
    id: "BOXED",
  },
  {
    img: "https://img.clevup.in/_static/app-img/cl-desk-10c.png",
    title: "Full Width",
    id: "FULL_WIDTH",
  },
];

export const shapeOptions = [
  { title: "Rounded", id: "ROUNDED" },
  { title: "Square", id: "SQUARE" },
];

export const sortOptions = [
  { title: "Recently Added", id: "createdAt,desc" },
  { title: "Price: High to Low", id: "salePrice,desc" },
  { title: "Price: Low to High", id: "salePrice,asc" },
  { title: "Alphabetically", id: "displayName,asc" },
  { title: "By Category", id: "catName,asc" },
  { title: "Quantity: High to Low", id: "qty,desc" },
  { title: "Quantity: Low to High", id: "qty,asc" },
];

export const productCards = [
  {
    icon: <ListCardIcon />,
    title: "List Card",
    id: "LIST_CARD",
  },
  {
    icon: <BigCardIcon />,
    title: "Big Card",
    id: "BIG_CARD",
  },
  {
    icon: <TwoCardIcon />,
    title: "Two Card",
    id: "TWO_CARD",
  },
];

export const bannerHeightOptions = [
  { title: "Boxed Height", id: "BOXED" },
  { title: "Adapt to Image", id: "FREE" },
];

export const productCardPriceOprions = [
  { title: "Sale Price with Tax Amount", id: "WITH_TAX" },
  { title: "Sale Price without Tax Amount", id: "WITHOUT_TAX" },
];

export const productPagePriceOprions = [
  { title: "Sale Price with Tax Amount", id: "WITH_TAX" },
  { title: "Sale Price without Tax Amount", id: "WITHOUT_TAX" },
  {
    title: "Sale Price without Tax Amount & Sale Price with Tax Amount",
    id: "BOTH",
  },
];

export const prodPageVariantSelectorTypeOprions = [
  { title: "Dropdown", id: "dropdown" },
  { title: "Chips", id: "chips" },
];

export const borderRadiusForButtonsOprions = [
  {
    title: "Square",
    id: "square",
    icon: (
      <div
        style={{
          width: "28px",
          height: "28px",
          background: "#8994FF",
          borderRadius: "0",
          marginRight: "12px",
        }}
      ></div>
    ),
  },
  {
    title: "Soft Rounded",
    id: "medium",
    icon: (
      <div
        style={{
          width: "28px",
          height: "28px",
          background: "#8994FF",
          borderRadius: "8px",
          marginRight: "12px",
        }}
      ></div>
    ),
  },
  {
    title: "Rounded",
    id: "rounded",
    icon: (
      <div
        style={{
          width: "28px",
          height: "28px",
          background: "#8994FF",
          borderRadius: "100px",
          marginRight: "12px",
        }}
      ></div>
    ),
  },
];

export const staffNotificationConfig = [
  {
    id: "store_new",
    title: "New Order Received",
    helperText: "Admins get notified via SMS for new online orders",
  },
];

export const customerNotificationConfig = [
  {
    id: "otp",
    title: "OTP Message",
    helperText:
      "Customers will receive an OTP message only if this setting is enabled.",
  },
  {
    id: "order_new",
    title: "Order Received SMS",
    helperText:
      "Send customers an SMS when their order is received by the store.",
  },
  {
    id: "order_confirmation",
    title: "Order Confirmation SMS",
    helperText:
      "Send customers an SMS when their order is confirmed by the store.",
  },
  {
    id: "order_delivery",
    title: "Order Dispatched SMS",
    helperText:
      "Send customers an SMS when their order is dispatched and in transit.",
  },
  {
    id: "order_complete",
    title: "Order Delivered SMS",
    helperText: "Send customers an SMS when their order is delivered.",
  },
  {
    id: "order_cancellation",
    title: "Order Cancellation SMS",
    helperText:
      "Send customers an SMS when their order is cancelled after being received.",
  },
];

export const initQtyMultiplyerOprions = [
  {
    id: "single",
    title: "Single Increment After Minimum",
    helperText:
      "Quantities increase by 1 after reaching the minimum quantity. For example, if the minimum quantity is 5, the next quantities would be 6, 7, 8, etc.",
  },
  {
    id: "multiple",
    title: "Multiple of Minimum Quantity",
    helperText:
      "Quantities increase by multiples of the minimum quantity. For example, if the minimum quantity is 5, the next quantities would be 10, 15, 20, etc.",
  },
];
