import {
  Drawer,
  Grid,
  IconButton,
  makeStyles,
  Typography,
} from "@material-ui/core";
import React from "react";
import CloseIcon from "../../../icons/CloseIcon";
import "react-image-crop/dist/ReactCrop.css";
import StatusDotIcon from "../../../icons/StatusDotIcon";
import ProductNoPicIcon from "../../../icons/NoPicIcon";
import OrderChargeRow from "../../orders/components/OrderChargeRow";
import Moment from "react-moment";
import HomeOutlinedIcon from "@material-ui/icons/HomeOutlined";

const useStyles = makeStyles(() => ({
  dividerGrid: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    paddingTop: 24,
    paddingBottom: 24,
    borderBottom: "1px solid #E6E6E6",
    width: "100%",
  },
  flexBox: {
    display: "flex",
    justifyContent: "space-between",
    alignContent: "space-between",
    width: "100%",
    alignItems: "center",
  },
  subHeading: {
    fontWeight: 600,
    fontSize: 14,
    color: "#1A1A1A",
    paddingTop: 0,
  },
  nopicctn: {
    border: "1px solid #E5E5E5",
    boxSizing: "border-box",
    borderRadius: "6px",
    width: 60,
    height: 60,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  prodimage: {
    height: "60px",
    width: "60px",
    borderRadius: "6px",
  },
}));

const ViewCart = ({ openViewCart, setOpenViewCart, setAnchoredCart, cart }) => {
  const classes = useStyles();

  const statusToText = (status) => {
    if (status === "PENDING") return { text: "Pending", fill: "#FF0000" };
    if (status === "COMPLETED") return { text: "Completed", fill: "#72C472" };
    return { text: "-", fill: "white" };
  };

  const getItemTotal = () => {
    return cart.cart_items
      .reduce((total, item) => {
        var price = item.sp_tax_included
          ? item.sale_price
          : item.sale_price + (item.tax ? item.tax : 0);
        return total + price * item.quantity;
      }, 0)
      .toFixed(2);
  };

  return (
    <Drawer
      open={openViewCart}
      onClose={() => {
        setOpenViewCart(false);
        setAnchoredCart(null);
      }}
      anchor="right"
      PaperProps={{
        style: {
          borderTopLeftRadius: 16,
          borderBottomLeftRadius: 16,
          padding: 52,
          paddingRight: 42,
          width: 570,
        },
      }}
    >
      <Grid container style={{ position: "relative" }}>
        <Grid
          item
          xs={12}
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignContent: "space-between",
            width: "100%",
            alignItems: "center",
          }}
        >
          <p style={{ fontWeight: 700, fontSize: 24, marginTop: -10 }}>
            CRT-{cart.id}
          </p>
          <IconButton
            aria-label="Close"
            aria-haspopup="true"
            color="inherit"
            onClick={() => {
              setOpenViewCart(false);
              setAnchoredCart(null);
            }}
            style={{ marginTop: -26, outline: "none" }}
          >
            <CloseIcon />
          </IconButton>
        </Grid>
        <Grid className={classes.dividerGrid}>
          <Typography className={classes.subHeading}>Cart Status</Typography>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              textTransform: "capitalize",
            }}
          >
            <StatusDotIcon fill={statusToText(cart.status).fill} />
            &nbsp; &nbsp;
            {statusToText(cart.status).text}
          </div>
        </Grid>
        <Grid className={classes.dividerGrid}>
          <Typography className={classes.subHeading}>Cart Date</Typography>
          <Moment format="DD-MMM-YYYY, hh:mm A">{cart.created_at}</Moment>
        </Grid>
        <Grid className={classes.dividerGrid} style={{ padding: "20px 0px" }}>
          <Typography className={classes.subHeading}>Mode</Typography>
          <span
            style={{
              padding: "4px 8px",
              marginLeft: 4,
              borderRadius: 15,
              height: 28,
              fontSize: 14,
              background:
                cart.delivery_mode === "PICKUP"
                  ? "rgba(255,114,34,0.15)"
                  : "rgba(34,215,255,0.15)",
              color: "#646464",
              textTransform: "none",
              minWidth: 0,
            }}
          >
            {cart.delivery_mode === "PICKUP" ? "Pick Up" : "Delivery"}
          </span>
        </Grid>

        <Grid className={classes.dividerGrid} style={{ padding: "20px 0px" }}>
          <Typography className={classes.subHeading}>Payment Mode</Typography>
          <span
            style={{
              padding: "4px 8px",
              marginLeft: 4,
              borderRadius: 15,
              height: 28,
              fontSize: 14,
              background:
                cart.payment_mode === "COD"
                  ? "rgba(121,4,242,0.15)"
                  : "rgba(11,156,49,0.15)",
              color: "#646464",
              textTransform: "none",
              minWidth: 0,
            }}
          >
            {cart.payment_mode === "COD" ? "COD" : "Online"}
          </span>
        </Grid>
        {cart.id && (
          <Grid className={classes.dividerGrid}>
            <Typography className={classes.subHeading}>Cart Number</Typography>
            <span style={{ color: "#1641DB", cursor: "pointer" }}>
              CRT-{cart.id}
            </span>
          </Grid>
        )}
        <Grid
          className={classes.dividerGrid}
          style={{
            borderBottom: "1px dashed rgb(240,240,240)",
            paddingBottom: 18,
          }}
        >
          <Typography className={classes.subHeading}>Customer</Typography>
        </Grid>
        <div>
          <Typography
            className={classes.subHeading}
            style={{ marginBottom: 8 }}
          >
            Bill To
          </Typography>
          <Typography
            className={classes.subHeading}
            style={{
              fontWeight: "normal",
              marginTop: 8,
              textTransform: "capitalize",
            }}
          >
            {cart.customer_name && `${cart.customer_name}, `}
            {cart.customer_mobile && `${cart.customer_mobile}`}
          </Typography>
          {cart.delivery_mode !== "PICKUP" && cart.pincode && (
            <div style={{ marginTop: 8 }}>
              <Typography
                className={classes.subHeading}
                style={{ marginBottom: 16 }}
              >
                Shipping Address
              </Typography>
              <div style={{ display: "flex" }}>
                <div style={{ marginRight: 12 }}>
                  - <HomeOutlinedIcon color="secondary" />-{" "}
                </div>
                <div>
                  <div>
                    <Typography
                      className={classes.subHeading}
                      style={{ fontWeight: "normal" }}
                    >
                      {cart.customer_name && `${cart.customer_name}, `}
                      {cart.customer_mobile && `${cart.customer_mobile}`}
                    </Typography>
                    <Typography
                      className={classes.subHeading}
                      style={{ fontWeight: "normal" }}
                    >
                      {cart.street1}, {cart.street2}
                    </Typography>
                    <Typography
                      className={classes.subHeading}
                      style={{ fontWeight: "normal", color: "#666666" }}
                    >
                      {cart.city}, {cart.state},{cart.pincode}
                    </Typography>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
        <div style={{ borderBottom: "1px solid #E6E6E6", width: "100%" }}>
          &nbsp;
        </div>

        {cart?.customer_notes && (
          <Grid style={{ display: "block" }} className={classes.dividerGrid}>
            <Typography
              className={classes.subHeading}
              style={{
                borderBottom: "1px dashed rgb(245,245,245)",
                paddingBottom: 8,
              }}
            >
              Customer Note
            </Typography>
            <Typography
              className={classes.subHeading}
              style={{ fontWeight: 500 }}
            >
              {cart.customer_notes}
            </Typography>
          </Grid>
        )}
        <Grid style={{ display: "block" }} className={classes.dividerGrid}>
          <Typography
            className={classes.subHeading}
            style={{
              borderBottom: "1px dashed rgb(245,245,245)",
              paddingBottom: 18,
            }}
          >
            Products
          </Typography>
          {cart.cart_items.map((item, i) => {
            return (
              <div
                style={{
                  paddingBottom: 20,
                  marginTop: 20,
                  borderBottom: "1px dashed rgb(240,240,240) ",
                }}
                key={i}
              >
                <div className={classes.flexBox}>
                  {item.image ? (
                    <img
                      className={classes.prodimage}
                      alt="Thumbnail"
                      src={item.image}
                    />
                  ) : (
                    <div className={classes.nopicctn}>
                      <ProductNoPicIcon />
                    </div>
                  )}
                  <div style={{ width: "84%" }}>
                    <Typography
                      className={classes.subHeading}
                      style={{ fontWeight: 500 }}
                    >
                      {item.display_name}
                      {item.product_id && (
                        <span>&nbsp; ({item.product_id})</span>
                      )}
                    </Typography>
                    <div className={classes.flexBox}>
                      <div>
                        <Typography
                          className={classes.subHeading}
                          style={{
                            fontWeight: "normal",
                            marginTop: 8,
                            color: "#666666",
                            paddingBottom: 5,
                          }}
                        >
                          MRP: ₹ {item.mrp}
                        </Typography>
                        {item.tax_group_id && (
                          <Typography
                            className={classes.subHeading}
                            style={{
                              fontWeight: "normal",
                              color: "#666666",
                              paddingBottom: 5,
                            }}
                          >
                            Tax: ₹ {item.tax ? item.tax.toFixed(2) : 0} (
                            {item.total_tax_in_per}
                            %)
                          </Typography>
                        )}
                      </div>
                      <div
                        style={{
                          height: "100%",
                          display: "flex",
                          alignItems: "flex-end",
                        }}
                      >
                        <Typography
                          className={classes.subHeading}
                          style={{
                            fontWeight: "normal",
                            marginTop: 8,
                            color: "#666666",
                            paddingBottom: 5,
                            borderBottom: "1px solid #EFEFEF",
                          }}
                        >
                          {item.sp_tax_included
                            ? `₹ ${item.sale_price} x ${item.quantity}`
                            : `₹ ${item.sale_price + (item.tax ? item.tax : 0)} x ${
                                item.quantity
                              }`}
                        </Typography>
                      </div>
                    </div>
                  </div>
                </div>
                <Typography
                  style={{
                    width: "100%",
                    textAlign: "right",
                    fontWeight: 500,
                    paddingTop: 0,
                    marginTop: item.tax_group_id ? -10 : 0,
                  }}
                  className={classes.subHeading}
                >
                  ₹{" "}
                  {item.sp_tax_included
                    ? item.sale_price * item.quantity
                    : (item.sale_price + (item.tax ? item.tax : 0)) * item.quantity}
                </Typography>
              </div>
            );
          })}
          <Grid
            className={classes.flexBox}
            style={{
              borderBottom: "1px dashed rgb(245,245,245)",
              width: "100%",
              padding: "18px 0px",
            }}
          >
            <Typography
              className={classes.subHeading}
              style={{ fontWeight: "normal", color: "#666666" }}
            >
              Item Total
            </Typography>
            <Typography
              className={classes.subHeading}
              style={{ color: "#666666" }}
            >
              ₹ {getItemTotal()}
            </Typography>
          </Grid>
          {cart.coupon_discount > 0 && (
            <OrderChargeRow
              chargeName={`Coupon - ${cart.coupon_code ? cart.coupon_code : ""}`}
              chargeAmount={cart.coupon_discount}
            />
          )}
          {cart.shipping_charge > 0 && (
            <OrderChargeRow
              chargeName="Shipping Charge"
              chargeAmount={cart.shipping_charge}
            />
          )}
          {cart.packaging_charge > 0 && (
            <OrderChargeRow
              chargeName="Packaging Charge"
              chargeAmount={cart.packaging_charge}
            />
          )}
          {cart.round_value > 0 && (
            <OrderChargeRow
              chargeName="Round Off"
              chargeAmount={cart.round_value}
            />
          )}
          <div
            style={{
              width: "100%",
              paddingTop: "18",
            }}
            className={classes.flexBox}
          >
            <Typography
              className={classes.subHeading}
              style={{ paddingTop: 18 }}
            >
              Total Amount
            </Typography>
            <Typography
              className={classes.subHeading}
              style={{ paddingTop: 18 }}
            >
              ₹{" "}
              {(
                cart.sale_price +
                cart.shipping_charge +
                cart.packaging_charge -
                cart.coupon_discount
              ).toFixed(2)}
            </Typography>
          </div>
        </Grid>
      </Grid>
    </Drawer>
  );
};

export default ViewCart;
