import { Typography, MenuItem } from "@material-ui/core";
import React, { useContext } from "react";
import { widgetIconGenerator } from "../../../../utils/widgetIconGenerator";
import Input from "../../../../../../../../components/controls/Input";
import { ChatWeightContext } from "../../../../context/Context";
import AdvancedSettings from "./components/AdvancedSettings";

const ChatBubble = () => {
  const {
    chatBubbleStates: {
      bubbleStyle,
      bubbleStyleDispatch,
      chatBubbleColors,
      chatBubblePosition,
      chatBubblePositionDispatch,
    },
  } = useContext(ChatWeightContext);
  return (
    <div>
      <Typography
        style={{
          fontSize: "14px",
          fontWeight: "600",
          marginBottom: "6px",
        }}
      >
        Chat Bubble
      </Typography>
      <Typography
        style={{
          fontSize: "12px",
          fontWeight: "400",
          marginBottom: "24px",
          color: "#666666",
        }}
      >
        Shown when window is minimised or closed
      </Typography>

      <Input
        select
        name="bubble_style"
        label="Chat Bubble Style"
        value={bubbleStyle}
        onChange={(e) => bubbleStyleDispatch(e.target.value)}
        style={{ marginBottom: 24 }}
        fullWidth
        labelFontWeight={400}
        labelPlacement="top"
        hideHelperText
      >
        {Object.keys(widgetIconGenerator()["WeightIconsWithBG"]).map(
          (style) => (
            <MenuItem key={style} value={style}>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  flexDirection: "row",
                }}
              >
                <div
                  style={{ marginRight: "8px" }}
                  dangerouslySetInnerHTML={{
                    __html: widgetIconGenerator(
                      chatBubbleColors.chat_bubble_color.value,
                      chatBubbleColors.chat_icon_text_color.value,
                      24
                    )["WeightIconsWithBG"][style],
                  }}
                ></div>
                {style.replace("style", "Style ")}
              </div>
            </MenuItem>
          )
        )}
      </Input>

      <Input
        select
        name="bubble_position"
        label="Chat Bubble Position"
        value={chatBubblePosition}
        onChange={(e) => chatBubblePositionDispatch(e.target.value)}
        fullWidth
        labelFontWeight={400}
        labelPlacement="top"
        hideHelperText
        style={{ marginBottom: 24 }}
      >
        {["left", "right"].map((position) => (
          <MenuItem key={position} value={position}>
            {position.replace(
              position.charAt(0),
              position.charAt(0).toUpperCase()
            )}
          </MenuItem>
        ))}
      </Input>

      <AdvancedSettings />
    </div>
  );
};

export default ChatBubble;
