export const getFontStylesCSS = (fontFamily) => {
  const googleFonts = [
    "Alegreya",
    "Amatic SC",
    "Bree Serif",
    "Caveat",
    "Comfortaa",
    "Dancing Script",
    "EB Garamond",
    "Lobster",
    "Lora",
    "Merriweather",
    "Montserrat",
    "Noto Sans",
    "Nunito",
    "Open Sans",
    "Oswald",
    "Pacifico",
    "Permanent Marker",
    "Pinyon Script",
    "Playfair Display",
    "Roboto",
    "Roboto Mono",
    "Source Code Pro",
    "Spectral",
    "Ultra",
    "Varela Round",
  ];

  if (googleFonts.includes(fontFamily)) {
    return `@import url('https://fonts.googleapis.com/css2?family=${fontFamily.replace(
      " ",
      "+"
    )}:wght@400;700&display=swap');`;
  }
  return "";
};
