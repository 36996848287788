import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";

const CustomerIcon = ({ size = 16 }) => {
  return (
    <SvgIcon viewBox={"0 0 16 16"} style={{ width: size, height: size }}>
      <svg fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M7.99992 7.03992C7.4936 7.03992 6.99865 6.88978 6.57766 6.60849C6.15667 6.32719 5.82855 5.92737 5.63479 5.45959C5.44103 4.99181 5.39033 4.47708 5.48911 3.98049C5.58789 3.4839 5.83171 3.02775 6.18973 2.66973C6.54775 2.31171 7.0039 2.06789 7.50049 1.96911C7.99708 1.87033 8.51181 1.92103 8.97959 2.11479C9.44737 2.30855 9.84719 2.63667 10.1285 3.05766C10.4098 3.47865 10.5599 3.9736 10.5599 4.47992C10.5599 5.15888 10.2902 5.81002 9.81012 6.29012C9.33002 6.77021 8.67888 7.03992 7.99992 7.03992ZM7.99992 2.55992C7.62018 2.55992 7.24897 2.67253 6.93323 2.8835C6.61748 3.09447 6.37139 3.39434 6.22607 3.74517C6.08075 4.096 6.04273 4.48205 6.11681 4.8545C6.1909 5.22694 6.37376 5.56905 6.64228 5.83757C6.91079 6.10609 7.2529 6.28895 7.62535 6.36303C7.99779 6.43711 8.38384 6.39909 8.73467 6.25377C9.08551 6.10845 9.38537 5.86236 9.59634 5.54662C9.80732 5.23088 9.91992 4.85966 9.91992 4.47992C9.91992 3.97071 9.71764 3.48235 9.35757 3.12228C8.9975 2.76221 8.50914 2.55992 7.99992 2.55992ZM14.0799 14.0799H13.4399V13.7599C13.4399 12.3171 12.8668 10.9335 11.8466 9.91326C10.8264 8.89306 9.4427 8.31992 7.99992 8.31992C6.55714 8.31992 5.17346 8.89306 4.15326 9.91326C3.13306 10.9335 2.55992 12.3171 2.55992 13.7599V14.0799H1.91992V13.7599C1.91992 12.1474 2.56049 10.6009 3.70071 9.46071C4.84093 8.32049 6.38741 7.67992 7.99992 7.67992C9.61244 7.67992 11.1589 8.32049 12.2991 9.46071C13.4394 10.6009 14.0799 12.1474 14.0799 13.7599V14.0799Z"
          fill="black"
        />
      </svg>
    </SvgIcon>
  );
};

export default CustomerIcon;
