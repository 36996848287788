import { useContext } from "react";
import { ChatWeightContext } from "../../../context/Context";
import { getFontStylesCSS } from "./getFontStylesCSS";

export const useOutputData = () => {
  const {
    formData,
    chatWindowStates: { textFont, chatWindowColors },
    chatBubbleStates: {
      bubbleStyle,
      chatBubbleColors,
      chatBubblePosition,
      bubbleTextConfig,
      bubbleText,
    },
  } = useContext(ChatWeightContext);

  const jsdata = {
    wpa_number: formData.whatsapp_number.value,
    profile_pic: formData.profile_icon.value,
    orgnization_name: formData.name.value,
    default_message: formData.welcome_message.value,
    cw_btn_text: formData.button_text.value,
    cw_header_bg_color: chatWindowColors.header_bg_color,
    cw_header_text_color: chatWindowColors.header_text_color,
    cw_btn_bg_color: chatWindowColors.button_bg_color,
    cw_btn_text_color: chatWindowColors.button_text_color,
    cw_footer_bg_color: chatWindowColors.footer_bg_color,
    font_family: textFont,
    cb_size: 64,
    cb_style: bubbleStyle,
    cb_position: chatBubblePosition,
    cb_bg_color: chatBubbleColors.chat_bubble_color,
    cb_text_color: chatBubbleColors.chat_icon_text_color,
    cb_text_enable: bubbleTextConfig.bubble_text,
    cb_text: bubbleText.value,
    cb_extend_on_hover: bubbleTextConfig.extend_bubble_when_hovered,
  };

  const cssData = `${getFontStylesCSS(
    jsdata.font_family
  )} .cb12_wrapper {--cw-header-bg-color: ${
    jsdata.cw_header_bg_color.value
  };--cw-header-text-color: ${
    jsdata.cw_header_text_color.value
  };--cw-btn-bg-color: ${jsdata.cw_btn_bg_color.value};--cw-btn-text-color: ${
    jsdata.cw_btn_text_color.value
  };--cw-footer-bg-color: ${jsdata.cw_footer_bg_color.value};--cb-size: ${
    jsdata.cb_size
  }px;--cb-bg-color: ${jsdata.cb_bg_color.value};--cb-text-color: ${
    jsdata.cb_text_color.value
  };--cw-font-family: "${jsdata.font_family}", sans-sarif;}`;

  return {
    jsdata,
    cssData,
  };
};
