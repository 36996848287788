import {
  FETCH_STORE,
  UPDATE_STORE,
  SET_STORE_LOADER,
  FETCH_PLANS,
  UPDATE_SETTING,
  STORE_PLAN_RENEW_INFO,
  STORE_PINCODE_INFO,
} from "./storeTypes";
import axios from "axios";
import { getDefaultStore } from "../../utils/storeUtil";

export const fetchStore = () => (dispatch) => {
  let accessToken = localStorage.getItem("access_token");
  let store = getDefaultStore();
  if (store && store.store_id) {
    axios
      .get(
        `${process.env.REACT_APP_API}/api/v1/store/${store.store_id}?fetch-setting=true`,
        {
          headers: {
            "X-Requested-With": "XMLHttpRequest",
            Authorization: `Bearer ${accessToken}`,
          },
        }
      )

      .then((response) => {
        dispatch({
          type: FETCH_STORE,
          payload: response.data.payload,
        });
      });
  }
};

export const fetchPlans = () => async (dispatch) => {
  let accessToken = localStorage.getItem("access_token");
  let store = getDefaultStore();

  try {
    const response = await fetch(
      `${process.env.REACT_APP_API}/api/v1/store/${store.store_id}/subscription-plans`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );

    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }

    const data = await response.json();

    dispatch({
      type: FETCH_PLANS,
      payload: data.payload,
    });
  } catch (error) {
    console.error("Error fetching plans:", error);
  }
};

export const updateStore =
  (data, isUpdateStoreReducer = true) =>
  async (dispatch) => {
    startStoreLoader();
    let accessToken = localStorage.getItem("access_token");
    let store = getDefaultStore();
    return await axios
      .patch(
        `${process.env.REACT_APP_API}/api/v1/store/${store.store_id}`,
        data,
        {
          headers: {
            "X-Requested-With": "XMLHttpRequest",
            Authorization: `Bearer ${accessToken}`,
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        if (isUpdateStoreReducer) {
          dispatch({
            type: UPDATE_STORE,
            payload: response.data.payload,
          });
        }
        stopStoreLoader();
        return response.data.payload;
      })
      .catch((error) => {
        stopStoreLoader();
      });
  };

export const updateSetting = (data, settingId) => async (dispatch) => {
  startStoreLoader();
  let accessToken = localStorage.getItem("access_token");
  let store = getDefaultStore();
  return await axios
    .patch(
      `${process.env.REACT_APP_API}/api/v1/store/${store.store_id}/settings/${settingId}`,
      data,
      {
        headers: {
          "X-Requested-With": "XMLHttpRequest",
          Authorization: `Bearer ${accessToken}`,
          "Content-Type": "application/json",
        },
      }
    )
    .then((response) => {
      dispatch({
        type: UPDATE_SETTING,
        payload: response.data.payload,
      });
      stopStoreLoader();
    })
    .catch((error) => {
      stopStoreLoader();
    });
};

export const getPlanRenewalInfo = (storeId) => async (dispatch) => {
  let accessToken = localStorage.getItem("access_token");
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API}/api/v1/business/store/${storeId}/subscriptions/renew`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
    dispatch({
      type: STORE_PLAN_RENEW_INFO,
      payload: response.data.payload,
    });

    return response.data.payload;
  } catch (error) {
    console.error("Error fetching plan renewal info:", error);
  }
};
export const fetchAddressByPincode = (pincode) => async () => {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_API}/api/v1/locations/?pincode=${pincode}`,
      {}
    );
    return await response.json();
  } catch (error) {
    console.error("Error fetching address data:", error);
  }
};
export const startStoreLoader = () => (dispatch) => {
  dispatch({
    type: SET_STORE_LOADER,
    payload: true,
  });
};

export const stopStoreLoader = () => (dispatch) => {
  dispatch({
    type: SET_STORE_LOADER,
    payload: false,
  });
};
