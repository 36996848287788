import React, { useContext } from "react";
import Switch from "../../../../../../../../components/controls/Switch";
import { ChatWeightContext } from "../../../../context/Context";

const PluginSwitch = () => {
  const { pluginEnabled, setPluginEnabled } = useContext(ChatWeightContext);

  const handleChange = () => {
    setPluginEnabled(!pluginEnabled);
  };
  return (
    <Switch
      name="bubblebtn"
      label="Enable Chat Bubble"
      labelFontWeight={400}
      helperText="Switching on will enable Whatsapp Chat Bubble on your store"
      color="secondary"
      value={pluginEnabled}
      onChange={handleChange}
      width="100%"
      style={{ margin: 0, marginLeft: "8px" }}
    />
  );
};

export default PluginSwitch;
