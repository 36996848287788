import React from "react";

export const InputGridContainer = ({ children, style = {} }) => {
  return (
    <div style={{ overflow: "hidden", ...style }}>
      <div style={{ margin: -10, display: "flex", flexWrap: "wrap" }}>
        {children}
      </div>
    </div>
  );
};

export const InputGridItem = ({ children, style = {} }) => {
  return <div style={{ width: "50%", padding: 10, ...style }}>{children}</div>;
};
