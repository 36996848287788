import React from "react";

const BubbleBackdrop = () => {
  return (
    <svg
      width="425"
      height="215"
      viewBox="0 0 425 215"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="425" height="215" rx="8" fill="#F4F4F4" />
      <path
        d="M0 8C0 3.58173 3.58172 0 8 0H417C421.418 0 425 3.58172 425 8V21.3838H0V8Z"
        fill="#EAEAEA"
      />
      <rect
        x="9.78906"
        y="29.9373"
        width="405.422"
        height="100.504"
        rx="10"
        fill="#E5E5E5"
      />
      <path
        d="M198 92.6667H226L217.25 81L210.25 90.3333L205 83.3333L198 92.6667ZM191 102V60H233V102H191Z"
        fill="#F7F7F7"
      />
      <rect
        x="22"
        y="143.87"
        width="54.5144"
        height="51.2598"
        rx="25.6299"
        fill="#EAEAEA"
      />
      <rect
        x="105.274"
        y="143.87"
        width="54.5144"
        height="51.2598"
        rx="25.6299"
        fill="#EAEAEA"
      />
      <rect
        x="188.549"
        y="143.87"
        width="55.3281"
        height="51.2598"
        rx="25.6299"
        fill="#EAEAEA"
      />
      <rect
        x="272.637"
        y="143.87"
        width="54.5144"
        height="51.2598"
        rx="25.6299"
        fill="#EAEAEA"
      />
      <rect
        x="355.911"
        y="143.87"
        width="52.0735"
        height="51.2598"
        rx="25.6299"
        fill="#EAEAEA"
      />
    </svg>
  );
};

export default BubbleBackdrop;
