import { Typography } from "@material-ui/core";
import React, { useContext } from "react";
import { ChatWeightContext } from "../../../../context/Context";
import ProfilePicture from "./components/ProfilePicture";
import Input from "../../../../../../../../components/controls/Input";
import AdvancedSettings from "./components/AdvancedSettings";

const ChatWindow = () => {
  const { formData, formDispatch, onBlur, onFocus } =
    useContext(ChatWeightContext);

  const handleChange = (e) => {
    formDispatch("value", {
      name: e.target.name,
      value: e.target.value,
    });
  };

  return (
    <div>
      <Typography
        style={{
          fontSize: "14px",
          fontWeight: "600",
          mb: "12px",
        }}
      >
        Chat Window
      </Typography>

      <ProfilePicture />

      <Input
        value={formData.name.value}
        onChange={handleChange}
        onBlur={onBlur}
        onFocus={onFocus}
        name="name"
        error={!!formData.name.error}
        helperText={formData.name.error}
        style={{ marginBottom: "24px" }}
        placeholder="Name"
        labelFontWeight={400}
        labelPlacement="top"
        hideHelperText
      />

      <Input
        label="Default Welcome Message"
        value={formData.welcome_message.value}
        onChange={handleChange}
        onBlur={onBlur}
        onFocus={onFocus}
        name="welcome_message"
        error={!!formData.welcome_message.error}
        helperText={formData.welcome_message.error}
        style={{ marginBottom: "24px" }}
        placeholder="Hello, How can I help you"
        labelFontWeight={400}
        labelPlacement="top"
        hideHelperText
      />

      <Input
        label="Button Text"
        value={formData.button_text.value}
        onChange={handleChange}
        onBlur={onBlur}
        onFocus={onFocus}
        name="button_text"
        error={!!formData.button_text.error}
        helperText={formData.button_text.error}
        style={{ marginBottom: "24px" }}
        placeholder="Start Chat"
        labelFontWeight={400}
        labelPlacement="top"
        hideHelperText
      />

      <AdvancedSettings />
    </div>
  );
};

export default ChatWindow;
