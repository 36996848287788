import React, { Fragment, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  clearProduct,
  clearProductError,
  deleteProduct,
  fetchProducts,
  clearProductData,
  setFilterSlugs,
  setSortCriteria,
  setFilterStatus,
  setFilterQty,
  setProductsPerPage,
  onlineOfflineProducts,
  setFilterMode,
} from "../../redux/product/productActions";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import Paginator from "../../components/Paginator/Paginator";
import {
  CircularProgress,
  Grid,
  LinearProgress,
  Snackbar,
  Typography,
} from "@material-ui/core";
import NoProduct from "../../components/Product/NoProduct";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import Checkbox from "@material-ui/core/Checkbox";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import SearchIcon from "@material-ui/icons/Search";
import { Drawer } from "@material-ui/core";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import ProductForm from "./components/ProductForm";
import BulkUpload from "./components/BulkUpload";
import useStyles from "./styles";
import AddIcon from "../../icons/AddIcon";
import ExportProdIcon from "../../icons/ExportProdIcon";
import { cdnUrl } from "../../utils/cdnUtils";
import {
  PRODUCTS_PER_PAGE,
  DEFAULT_PRODUCTS_PER_PAGE,
  PRODUCTS_KEY,
} from "../../utils/utils";
import AdditionalFields from "./components/AdditionalFields";
import CloseIcon from "@material-ui/icons/Close";
import NoPicIcon from "../../icons/NoPicIcon";
import VariantForm from "./components/VariantForm";
import Alert from "@material-ui/lab/Alert";
import { getStoreIdFromUrl } from "../../utils/storeUtil";
import StockModal from "./components/StockModal";
import { fetchAdditionalFields } from "../../redux/additionalFields/additionalFieldsActions";
import {
  setReloadFalse,
  setReloadTrue,
} from "../../redux/reload/reloadActions";
import UploadIcon from "../../icons/UploadIcon";
import SEOModal from "../../components/common/SEOModal";
import ProductFilter from "./components/ProductFilter";
import Toast from "../../components/Layout/Toast";
import { exportProductPdf, exportBulkProduct } from "../../utils/productUtils";
import {
  layoutBodyBox,
  layoutContainer,
  layoutLoaderHeader,
  layoutSubHeader,
} from "../../styles/common";
import OutlinedButton from "../../components/buttons/OutlinedButton";
import PageLoader from "../../components/Layout/PageLoader";
import BulkEdit from "./components/BulkEdit";
import BulkActions from "../../components/common/BulkActions";
import {
  CategoryIcon,
  DeleteIcon,
  EditIcon,
  OfflineIcon,
  OnlineIcon,
  SettingsIcon,
} from "../../icons";
import DropdownButton from "../../components/buttons/DropdownButton";
import PaidTab from "../../components/common/PaidTab";
import PaidPopUp from "../../components/PaidPopUp";
import { BUSINESS_APP_PRO, BASIC, PLANS } from "../plans/constants";
import FormModal from "../../components/common/FormModal";
import HeaderBreadcrumbs from "../../components/common/HeaderBreadcrumbs";
import { saveCsvDocument, saveFileDocument } from "../../utils/documentUtil";
import { useHistory, useLocation } from "react-router-dom";

const Products = () => {
  const classes = useStyles();
  const history = useHistory();
  const state = useSelector((state) => state);
  const store = state.store.store;
  const categories = state.categories.categories;
  const additionalFields = state.additionalFields.fields;
  const productsState = state.products;
  const progress = state.products.progress;
  const products = productsState.products;
  const loader = productsState.loader;
  const prodError = productsState.error;
  const opProduct = productsState.product;
  const numberOfPages = productsState.numberOfPages;
  const deleteSuccess = productsState.deleteSuccess;
  const filterSlugs = productsState.filterSlugs;
  const sortBy = productsState.sortBy;
  const status = productsState.status;
  const mode = productsState.mode;
  const minQty = productsState.minQty;
  const maxQty = productsState.maxQty;
  const productsPerPage = productsState.productsPerPage || PRODUCTS_PER_PAGE;
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = useState(null);
  const [showAdditionalFields, setShowAdditionalFields] = useState();
  const [showVariantForm, setShowVariantForm] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [isOpen, setIsOpen] = useState(false);
  const [stockModal, setStockModal] = useState({ visible: false, mode: "IN" });
  const [anchoredProduct, setAnchoredProduct] = useState(null);
  const [anchoredCategories, setAnchoredCategories] = useState(null);
  const [selectedItems, setselectedItems] = useState(new Set([]));
  const [maxPageSoFar, setMaxPageSoFar] = useState(0);
  const [searchKeyword, setSearchKeyword] = useState(null);
  const [inputKeyword, setInputKeyword] = useState(null);
  const storeId = getStoreIdFromUrl();
  const [openBulkUpload, setOpenBulkUpload] = useState(false);
  const [openSeoSetting, setOpenSeoSetting] = useState(false);
  const [toast, setToast] = useState(false);
  const [message, setMessage] = useState("");
  const [filterValues, setFilterValues] = useState(new Map());
  const [timeoutId, setTimeoutId] = useState(null);
  const [commonModal, setCommonModal] = useState(false);
  const [forModal, setForModal] = useState(null);
  const [circularProgress, setCircularProgress] = useState(null);
  const [showBulkEdit, setShowBulkEdit] = useState(false);
  const [openPremiumModal, setOpenPremiumModal] = useState(false);
  const [bulkAction, setBulkAction] = useState(null);

  const params = new URLSearchParams(useLocation().search);
  const filterQuery = params.get("filter") ? params.get("filter") : null;

  const onChangeProductsPerPage = (updatedProductsPerPage) => {
    localStorage.setItem(PRODUCTS_KEY, updatedProductsPerPage);
    dispatch(clearProductData());
    setCurrentPage(0);
    setMaxPageSoFar(0);
    dispatch(setProductsPerPage(updatedProductsPerPage));
    dispatch(
      fetchProducts(
        0,
        updatedProductsPerPage,
        searchKeyword.trim(),
        filterSlugs,
        sortBy,
        status,
        mode,
        minQty,
        maxQty
      )
    );
    setselectedItems(new Set([]));
  };

  const onFilterApply = (
    selectedSlugs,
    filterStatus,
    filterMode,
    filterMinQty,
    filterMaxQty,
    clearFilters = false
  ) => {
    let searchTerm = searchKeyword.trim()
      ? searchKeyword.trim()
      : sessionStorage.getItem("product_search_term") || "";
    if (clearFilters) {
      setSearchKeyword("");
      setInputKeyword("");
      searchTerm = "";
    }
    if (filterQuery && (clearFilters || filterMaxQty !== 0)) {
      history.replace(`/${storeId}/products`);
    }

    dispatch(clearProductData());
    setCurrentPage(0);
    setMaxPageSoFar(0);
    dispatch(
      fetchProducts(
        0,
        productsPerPage,
        searchTerm,
        selectedSlugs,
        sortBy,
        filterStatus,
        filterMode,
        filterMinQty,
        filterMaxQty
      )
    );
  };

  const onSortChange = (sortValue) => {
    dispatch(clearProductData());
    setCurrentPage(0);
    setMaxPageSoFar(0);
    dispatch(
      fetchProducts(
        0,
        productsPerPage,
        searchKeyword.trim(),
        filterSlugs,
        sortValue,
        status,
        mode,
        minQty,
        maxQty
      )
    );
  };

  useEffect(() => {
    let searchTerm = sessionStorage.getItem("product_search_term") || "";
    setInputKeyword(searchTerm);
    setSearchKeyword(searchTerm);
    if (products.length === 0 && !filterQuery) {
      dispatch(
        fetchProducts(
          0,
          productsPerPage,
          searchTerm,
          null,
          sortBy,
          status,
          mode,
          minQty,
          maxQty
        )
      );
    } else if (filterQuery && filterQuery === "lowstock") {
      dispatch(clearProductData());
      setCurrentPage(0);
      setMaxPageSoFar(0);
      let maxParam = params.get("max");
      let maxVal = maxParam != null ? maxParam : 0;
      dispatch(setFilterQty(null, maxVal));
      dispatch(
        fetchProducts(
          0,
          productsPerPage,
          searchTerm,
          filterSlugs,
          sortBy,
          status,
          mode,
          null,
          maxVal
        )
      );
    }
    // eslint-disable-next-line
  }, [storeId, filterQuery]);

  useEffect(() => {
    if (additionalFields.length === 0) {
      dispatch(fetchAdditionalFields());
    }
    // eslint-disable-next-line
  }, [storeId]);

  additionalFields.forEach((fld) => {
    if (fld.slug === "product_id") {
    }
  });
  useEffect(() => {
    if (filterSlugs.length > 0) {
      let tempFilerValues = new Map();
      filterSlugs.forEach((slug) => {
        categories.every((cat) => {
          if (cat.slug === slug) {
            if (cat.level === 0) {
              if (!tempFilerValues.has(slug)) {
                tempFilerValues.set(slug, []);
              }
            } else {
              if (!tempFilerValues.has(cat.root_cat_slug)) {
                tempFilerValues.set(cat.root_cat_slug, [slug]);
              } else {
                tempFilerValues.set(cat.root_cat_slug, [
                  ...tempFilerValues.get(cat.root_cat_slug),
                  slug,
                ]);
              }
            }
            return false;
          }
          return true;
        });
      });
      setFilterValues(tempFilerValues);
    }
    // eslint-disable-next-line
  }, [categories]);

  useEffect(() => {
    if (!productsPerPage) {
      dispatch(setProductsPerPage(PRODUCTS_PER_PAGE));
    }
    // eslint-disable-next-line
  }, [productsPerPage]);

  const onNextPage = () => {
    setselectedItems(new Set([]));
    if (maxPageSoFar < currentPage + 1) {
      dispatch(
        fetchProducts(
          currentPage + 1,
          productsPerPage,
          searchKeyword.trim(),
          filterSlugs,
          sortBy,
          status,
          mode,
          minQty,
          maxQty
        )
      );
      setMaxPageSoFar(
        maxPageSoFar < currentPage + 1 ? currentPage + 1 : maxPageSoFar
      );
      setCurrentPage(currentPage + 1);
    } else {
      setCurrentPage(currentPage + 1);
    }
  };

  const onPrevPage = () => {
    setselectedItems(new Set([]));
    setCurrentPage(currentPage - 1);
  };

  const handleClick = (event, tableitem) => {
    setAnchoredProduct(tableitem);
    setAnchoredCategories(tableitem?.categories || []);
    setAnchorEl(event.currentTarget);
  };

  const onProductSave = (add) => {
    setAnchoredProduct(null);
    if (add) {
      setCurrentPage(0);
    }
  };

  const onVariantSave = () => {
    setMessage("Variant Created Successfully");
    setToast(true);
    setShowVariantForm(false);
    setAnchoredProduct(null);
    setCurrentPage(0);
  };

  const viewProduct = () => {
    setIsOpen(true);
  };

  const onAddPopupClose = () => {
    setIsOpen(false);
    setAnchoredProduct(null);
  };

  const onVariantPopupClose = () => {
    setShowVariantForm(false);
    setAnchoredProduct(null);
  };

  const handleClose = () => {
    dispatch(setReloadFalse());
    setAnchoredProduct(null);
    setAnchorEl(null);
  };

  const onSelect = (sku) => {
    let tempSet = new Set(selectedItems);
    tempSet.add(sku);
    setselectedItems(tempSet);
  };

  const onUnselect = (sku) => {
    let tempSet = new Set(selectedItems);
    tempSet.delete(sku);
    setselectedItems(tempSet);
  };

  const onSearch = (keyword) => {
    setMaxPageSoFar(0);
    setCurrentPage(0);
    sessionStorage.setItem("product_search_term", keyword);
    dispatch(
      fetchProducts(
        0,
        productsPerPage,
        keyword,
        filterSlugs,
        sortBy,
        status,
        mode,
        minQty,
        maxQty
      )
    );
  };

  const onExport = () => {
    setCircularProgress(true);
    setMessage(
      `${productsState.numberOfElements} ${
        productsState.numberOfElements > 1 ? "Products" : "Product"
      } Exported Successfully`
    );
    exportBulkProduct(searchKeyword, filterSlugs, status, minQty, maxQty).then(
      (res) => {
        saveCsvDocument(res.data, `${store.name} - Exported Products.csv`);
        setCircularProgress(false);
        setToast(true);
        setCommonModal(false);
      }
    );
  };

  const onGeneratePdf = () => {
    setCircularProgress(true);
    if (selectedItems.size > 0) {
      setMessage(
        `${selectedItems.size} ${
          selectedItems.size > 1 ? "Products" : "Product"
        } PDF Generated Successfully`
      );
      exportProductPdf(store, Array.from(selectedItems)).then((res) => {
        saveFileDocument(res.data, `${store.name} - Products.pdf`);
        setCircularProgress(false);
        setToast(true);
        setCommonModal(false);
      });
    }
  };

  const removeMultipleProd = () => {
    setCircularProgress(true);
    if (selectedItems.size > 0) {
      setMessage(
        `${selectedItems.size} ${
          selectedItems.size > 1 ? "Products" : "Product"
        } Deleted Successfully`
      );
      dispatch(deleteProduct(Array.from(selectedItems))).then(() => {
        setCircularProgress(false);
        setToast(true);
        setCommonModal(false);
      });
    }
  };

  const onOnlineOfflineProds = (value) => {
    setCircularProgress(true);
    if (selectedItems.size > 0) {
      setMessage(
        `${selectedItems.size} ${
          selectedItems.size > 1 ? "Products" : "Product"
        } Updated Successfully.`
      );
      dispatch(onlineOfflineProducts(Array.from(selectedItems), value)).then(
        () => {
          setCircularProgress(false);
          setToast(true);
          setCommonModal(false);
        }
      );
    }
  };

  const removeSingleProd = (product) => {
    setCommonModal(false);
    setAnchoredProduct(null);
    setAnchorEl(null);
    setMessage("Product Deleted Successfully");
    dispatch(deleteProduct([product.sku], productsPerPage)).then(() =>
      setToast(true)
    );
  };

  const onConfirmCommonModal = () => {
    if (forModal === "EXPORT_CSV") {
      onExport();
    }
    if (forModal === "EXPORT_PDF") {
      onGeneratePdf();
    }
    if (forModal === "DELETE_MULTI_PROD") {
      removeMultipleProd();
    }
    if (forModal === "MARK_OFFLINE_PROD" || forModal === "MARK_ONLINE_PROD") {
      onOnlineOfflineProds(forModal === "MARK_ONLINE_PROD");
    }
    if (forModal === "DELETE_PROD") {
      removeSingleProd(anchoredProduct);
    }
  };

  if (opProduct) {
    let availablePages = Math.floor(products.length / productsPerPage);
    setMaxPageSoFar(availablePages > 1 ? availablePages - 1 : 0);
    dispatch(clearProduct());
  }

  if (deleteSuccess) {
    let delCount = selectedItems.size;
    let pageToRefresh = delCount > 1 ? 0 : currentPage;
    dispatch(
      fetchProducts(
        pageToRefresh,
        productsPerPage,
        searchKeyword,
        filterSlugs,
        sortBy,
        status,
        mode,
        minQty,
        maxQty
      )
    );
    dispatch(clearProduct());
    setselectedItems(new Set([]));
    setCommonModal(false);
    setCurrentPage(pageToRefresh);
    setMaxPageSoFar(pageToRefresh);
  }

  const getCommonModalText = () => {
    if (forModal === "EXPORT_CSV") {
      return (
        <>
          <p style={{ fontSize: 16 }}>
            Export
            <b>
              {filterSlugs.length === 0 &&
              minQty === null &&
              maxQty === null &&
              status === null &&
              !searchKeyword
                ? " All Products?"
                : ` ${productsState.numberOfElements} ${
                    productsState.numberOfElements > 1
                      ? "Products?"
                      : "Product?"
                  }`}
            </b>
          </p>
          {filterSlugs.length > 0 ? (
            <p style={{ marginBottom: 4 }}>Category filter applied</p>
          ) : (
            ""
          )}
          {minQty !== null || maxQty !== null ? (
            <p style={{ marginBottom: 4 }}>Quantity filter applied</p>
          ) : (
            ""
          )}
          {status !== null ? (
            <p style={{ marginBottom: 4 }}>Status filter applied</p>
          ) : (
            ""
          )}
        </>
      );
    }
    if (forModal === "EXPORT_PDF") {
      return (
        <p style={{ fontSize: 16 }}>
          Generate{" "}
          <b>
            {" "}
            {selectedItems.size}{" "}
            {selectedItems.size > 1 ? "Products" : "Product"}
          </b>{" "}
          PDF?
        </p>
      );
    }
    if (forModal === "DELETE_MULTI_PROD") {
      return (
        <p style={{ fontSize: 16 }}>
          Do you really want to delete{" "}
          <b>
            {" "}
            {selectedItems.size}{" "}
            {selectedItems.size > 1 ? "Products" : "Product"}
          </b>
          ?
        </p>
      );
    }
    if (forModal === "MARK_OFFLINE_PROD" || forModal === "MARK_ONLINE_PROD") {
      return (
        <p style={{ fontSize: 16 }}>
          Do you want to make{" "}
          <b>
            {" "}
            {selectedItems.size}{" "}
            {selectedItems.size > 1 ? "Products" : "Product"}{" "}
            {forModal === "MARK_OFFLINE_PROD" ? "Offline" : "Online"}
          </b>
          ?
        </p>
      );
    }
    if (forModal === "DELETE_PROD") {
      return (
        <p style={{ fontSize: 16 }}>
          Do you really want to delete{" "}
          <b> {anchoredProduct && anchoredProduct.name}</b>?
        </p>
      );
    }
  };

  let startIndex = currentPage * productsPerPage;
  let endIndex =
    startIndex + productsPerPage < products.length
      ? startIndex + productsPerPage
      : products.length;

  const onView = () => {
    viewProduct();
    setAnchorEl(null);
  };

  const onAddVarient = () => {
    setShowVariantForm(true);
    setAnchorEl(null);
  };

  const onStockIn = () => {
    setStockModal({
      mode: "IN",
      visible: true,
    });
    setAnchorEl(null);
  };

  const onStockOut = () => {
    setStockModal({
      mode: "OUT",
      visible: true,
    });

    setAnchorEl(null);
  };

  const onProductSEO = () => {
    setOpenSeoSetting(true);
    setAnchorEl(null);
  };

  const onRemove = () => {
    setCommonModal(true);
    setForModal("DELETE_PROD");
    setAnchorEl(null);
  };

  if (progress && products.length === 0) {
    return (
      <Grid container spacing={2}>
        <Grid item xs={12} className={layoutLoaderHeader}>
          <div className={layoutContainer}>
            <HeaderBreadcrumbs list={[{ label: "Products" }]} />
            <PageLoader />
          </div>
        </Grid>
      </Grid>
    );
  }

  return (
    <div>
      <Grid container spacing={2}>
        {loader && (
          <div
            style={{
              position: "fixed",
              left: "50%",
              top: "50%",
            }}
          >
            <CircularProgress color="secondary" />
          </div>
        )}
        <Grid
          item
          xs={12}
          style={{ display: "flex", justifyContent: "center" }}
        >
          <div className={layoutContainer}>
            <div className={layoutSubHeader}>
              <HeaderBreadcrumbs list={[{ label: "Products" }]} />
              {products.length > 0 && (
                <BulkActions
                  toastTxt="Select products to perform bulk actions"
                  disabled={selectedItems.size === 0}
                  list={[
                    {
                      name: "Generate PDF",
                      icon: <CategoryIcon size={16} stroke="#666666" />,
                      onClick: () => {
                        setCommonModal(true);
                        setForModal("EXPORT_PDF");
                      },
                    },
                    {
                      name: "Mark Online",
                      icon: <OnlineIcon stroke="#666666" />,
                      onClick: () => {
                        setCommonModal(true);
                        setForModal("MARK_ONLINE_PROD");
                      },
                    },
                    {
                      name: "Mark Offline",
                      icon: <OfflineIcon stroke="#666666" />,
                      onClick: () => {
                        setCommonModal(true);
                        setForModal("MARK_OFFLINE_PROD");
                      },
                    },
                    {
                      name: "Delete",
                      icon: <DeleteIcon stroke="#666666" />,
                      onClick: () => {
                        setCommonModal(true);
                        setForModal("DELETE_MULTI_PROD");
                      },
                    },
                  ]}
                />
              )}

              <DropdownButton
                style={{ marginLeft: 20 }}
                list={[
                  {
                    label: "Add Product",
                    icon: <AddIcon />,
                    onClick: () => setIsOpen(true),
                  },
                  {
                    label: (
                      <span>
                        Bulk Import <PaidTab plan={BASIC} />
                      </span>
                    ),
                    icon: <UploadIcon />,
                    onClick: () => {
                      if (PLANS[store.plan].weight < PLANS.BASIC.weight) {
                        setBulkAction("IMPORT");
                        setOpenPremiumModal(true);
                        return;
                      }
                      setOpenBulkUpload(true);
                    },
                  },
                  {
                    label: (
                      <span>
                        Bulk Update <PaidTab plan={BUSINESS_APP_PRO} />
                      </span>
                    ),
                    icon: <EditIcon stroke="#1641DB" size={14} />,
                    onClick: () => {
                      if (
                        PLANS[store.plan].weight < PLANS.BUSINESS_APP_PRO.weight
                      ) {
                        setBulkAction("UPDATE");
                        setOpenPremiumModal(true);
                        return;
                      }
                      setShowBulkEdit(true);
                    },
                    hidden: products.length === 0,
                  },
                  {
                    label: "Export Data",
                    icon: (
                      <ExportProdIcon
                        stroke={
                          productsState.numberOfElements > 0
                            ? "#1641DB"
                            : "#999999"
                        }
                      />
                    ),
                    onClick: () => {
                      setCommonModal(true);
                      setForModal("EXPORT_CSV");
                    },
                    hidden: products.length === 0,
                  },
                ]}
              />
              <OutlinedButton
                onClick={() => setShowAdditionalFields(true)}
                style={{
                  marginLeft: 20,
                  width: 46,
                  minWidth: 0,
                }}
                className={classes.bulkActionBtn}
              >
                <SettingsIcon stroke="#1641DB" />
              </OutlinedButton>
            </div>
            {products.length === 0 &&
            filterSlugs.length === 0 &&
            !status &&
            minQty === null &&
            maxQty === null &&
            mode === null &&
            !searchKeyword ? (
              <NoProduct />
            ) : (
              <div>
                <div className={layoutBodyBox}>
                  <div className={classes.tableHeader}>
                    <div className={classes.search}>
                      <SearchIcon />
                      <input
                        type="text"
                        placeholder="Enter Product Name"
                        className={classes.searchBox}
                        value={inputKeyword}
                        onChange={(e) => {
                          let searchValue = e.target.value;
                          setSearchKeyword(searchValue);
                          setInputKeyword(searchValue);
                          if (timeoutId) {
                            clearTimeout(timeoutId);
                          }
                          let timer = setTimeout(
                            () => onSearch(searchValue.trim()),
                            1000
                          );
                          setTimeoutId(timer);
                        }}
                        onKeyDown={(e) => {
                          if (e.key === "Escape") {
                            setSearchKeyword("");
                            setInputKeyword("");
                            setMaxPageSoFar(0);
                            setCurrentPage(0);
                            sessionStorage.removeItem("product_search_term");
                            dispatch(
                              fetchProducts(
                                0,
                                productsPerPage,
                                null,
                                filterSlugs,
                                sortBy,
                                status,
                                mode,
                                minQty,
                                maxQty
                              )
                            );
                          }
                        }}
                      />
                      {searchKeyword !== "" && (
                        <span
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            setSearchKeyword("");
                            setInputKeyword("");
                            setMaxPageSoFar(0);
                            setCurrentPage(0);
                            sessionStorage.removeItem("product_search_term");
                            dispatch(
                              fetchProducts(
                                0,
                                productsPerPage,
                                null,
                                filterSlugs,
                                sortBy,
                                status,
                                mode,
                                minQty,
                                maxQty
                              )
                            );
                          }}
                        >
                          <CloseIcon fontSize="small" />
                        </span>
                      )}
                    </div>
                    <ProductFilter
                      filterSlugs={filterSlugs}
                      setFilterSlugs={setFilterSlugs}
                      onFilterApply={onFilterApply}
                      filterValues={filterValues}
                      setFilterValues={setFilterValues}
                      sortBy={sortBy}
                      setSortBy={setSortCriteria}
                      onSortChange={onSortChange}
                      filterStatus={status}
                      setFilterStatus={setFilterStatus}
                      filterMode={mode}
                      setFilterMode={setFilterMode}
                      filterMinQty={minQty}
                      filterMaxQty={maxQty}
                      setFilterQty={setFilterQty}
                      inputKeyword={inputKeyword}
                    />
                  </div>
                  <TableContainer>
                    <Table>
                      <TableHead className={classes.tablehead}>
                        <TableRow className={classes.headrow}>
                          <TableCell style={{ width: "5%" }}>
                            <Checkbox
                              style={{ padding: 0 }}
                              checked={
                                selectedItems.size !== 0 &&
                                selectedItems.size === endIndex - startIndex
                              }
                              onChange={(e) => {
                                const selected = products
                                  .slice(startIndex, endIndex)
                                  .map((item) => item.sku);

                                if (e.target.checked) {
                                  setselectedItems(new Set(selected));
                                } else {
                                  setselectedItems(new Set([]));
                                }
                              }}
                            />
                          </TableCell>
                          <TableCell style={{ width: "36%" }}>
                            Product
                          </TableCell>
                          <TableCell style={{ width: "21%" }}>
                            Category
                          </TableCell>
                          <TableCell style={{ width: "12%" }}>
                            Sale Price
                          </TableCell>
                          <TableCell style={{ width: "12%" }}>MRP</TableCell>
                          <TableCell style={{ width: "10%" }}>
                            Quantity
                          </TableCell>
                          <TableCell style={{ width: "4%" }}>Actions</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {products
                          .slice(startIndex, endIndex)
                          .map((tableitem, index) => (
                            <TableRow
                              key={tableitem.id}
                              style={{
                                borderBottom:
                                  (index + 1) % productsPerPage === 0
                                    ? "none"
                                    : "auto",
                              }}
                            >
                              <TableCell>
                                <Checkbox
                                  style={{ padding: 0 }}
                                  checked={selectedItems.has(tableitem.sku)}
                                  onChange={(e) => {
                                    if (e.target.checked)
                                      onSelect(tableitem.sku);
                                    else onUnselect(tableitem.sku);
                                  }}
                                />
                              </TableCell>
                              <TableCell align="left">
                                <div
                                  className=""
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    gap: "10px",
                                  }}
                                >
                                  <div>
                                    {tableitem.images &&
                                    tableitem.images.length > 0 ? (
                                      <img
                                        className={classes.prodimage}
                                        alt="Thumbnail"
                                        src={cdnUrl(
                                          tableitem.images[0],
                                          200,
                                          200,
                                          1
                                        )}
                                      ></img>
                                    ) : (
                                      <div className={classes.noPicIcon}>
                                        <NoPicIcon />
                                      </div>
                                    )}
                                  </div>
                                  <div>
                                    <div
                                      style={{
                                        cursor: "pointer",
                                        color: "#1641DB",
                                        marginBottom: 4,
                                      }}
                                      onClick={() => {
                                        setAnchoredProduct(tableitem);
                                        viewProduct();
                                      }}
                                    >
                                      {tableitem.display_name}
                                    </div>
                                    <div
                                      style={{
                                        display: "flex",
                                        alignItems: "center",
                                        gap: "5px",
                                      }}
                                    >
                                      <div>{tableitem.sku}</div>
                                      {tableitem.product_id && (
                                        <div
                                          style={{
                                            color: "rgb(136, 136, 136)",
                                          }}
                                        >
                                          ({tableitem.product_id})
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </TableCell>
                              <TableCell align="left">
                                {tableitem.categories &&
                                tableitem.categories.length > 0
                                  ? tableitem.categories
                                      .map((category) => `${category.cat_name}`)
                                      .join(", ")
                                  : "--"}
                              </TableCell>
                              <TableCell align="left">
                                {tableitem.sale_price.toFixed(2)}
                              </TableCell>
                              <TableCell align="left">
                                {tableitem.mrp.toFixed(2)}
                              </TableCell>
                              <TableCell align="left">
                                {tableitem.qty}
                              </TableCell>
                              <TableCell align="center">
                                <MoreHorizIcon
                                  style={{ cursor: "pointer" }}
                                  aria-controls="simple-menu"
                                  aria-haspopup="true"
                                  onClick={(event) => {
                                    dispatch(setReloadTrue());
                                    handleClick(event, tableitem);
                                  }}
                                />
                                <Menu
                                  id="simple-menu"
                                  anchorEl={anchorEl}
                                  keepMounted
                                  open={Boolean(anchorEl)}
                                  onClose={handleClose}
                                  className={classes.anchor}
                                >
                                  <MenuItem onClick={onView}>View</MenuItem>
                                  <MenuItem onClick={onAddVarient}>
                                    Add Variant
                                  </MenuItem>
                                  <MenuItem onClick={onStockIn}>
                                    Stock In
                                  </MenuItem>
                                  <MenuItem onClick={onStockOut}>
                                    Stock Out
                                  </MenuItem>
                                  <MenuItem onClick={onProductSEO}>
                                    Product SEO
                                  </MenuItem>
                                  <MenuItem onClick={onRemove}>Remove</MenuItem>
                                </Menu>
                              </TableCell>
                            </TableRow>
                          ))}
                      </TableBody>
                    </Table>
                    {progress && (
                      <Fragment>
                        <div
                          style={{
                            height: 93 * productsPerPage - 4,
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        ></div>
                        <LinearProgress color="secondary" />
                      </Fragment>
                    )}
                    {!progress && products.length === 0 && (
                      <Fragment>
                        <div
                          style={{
                            height: 93 * 6,
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <Typography
                            style={{
                              fontSize: 18,
                              fontWeight: 600,
                              marginTop: -34,
                            }}
                          >
                            No Products Found
                          </Typography>
                        </div>
                      </Fragment>
                    )}
                  </TableContainer>
                </div>
                <Paginator
                  changeRecordsPerPage={onChangeProductsPerPage}
                  possibleRecordsPerPage={[
                    DEFAULT_PRODUCTS_PER_PAGE,
                    20,
                    50,
                    100,
                  ]}
                  totalCount={productsState.numberOfElements}
                  recordsPerPage={productsPerPage}
                  onNext={onNextPage}
                  onPrev={onPrevPage}
                  total={numberOfPages === 0 ? 1 : numberOfPages}
                  currentPage={currentPage}
                />
              </div>
            )}
            {prodError && (
              <Snackbar
                open={prodError ? true : false}
                autoHideDuration={4000}
                onClose={() => {
                  dispatch(clearProductError());
                }}
              >
                <Alert
                  elevation={6}
                  variant="filled"
                  severity="error"
                  style={{
                    marginBottom: 30,
                    borderRadius: 6,
                    background: "#FF0000",
                    boxShadow: "0px 6px 10px 0px rgba(0,0,0,0.09",
                  }}
                >
                  {prodError}
                </Alert>
              </Snackbar>
            )}
          </div>
        </Grid>
      </Grid>
      <StockModal
        record={anchoredProduct}
        stockModal={stockModal}
        onClose={() => {
          setStockModal({ ...stockModal, visible: false });
          setAnchoredProduct(null);
        }}
        setToast={setToast}
        setMessage={setMessage}
      />
      <BulkEdit
        open={showBulkEdit}
        toggleState={setShowBulkEdit}
        onExport={onExport}
        loading={circularProgress}
        qty={
          filterSlugs.length === 0 &&
          minQty === null &&
          maxQty === null &&
          status === null &&
          !searchKeyword
            ? "All"
            : productsState.numberOfElements
        }
      />
      <BulkUpload
        openBulkUpload={openBulkUpload}
        setOpenBulkUpload={setOpenBulkUpload}
      />
      <Drawer
        open={isOpen}
        onClose={onAddPopupClose}
        anchor="right"
        PaperProps={{
          style: {
            paddingLeft: 28,
            paddingRight: 28,
            paddingBottom: 28,
            paddingTop: 20,
            width: 570,
          },
        }}
      >
        <ProductForm
          productSku={anchoredProduct?.sku}
          recordsPerPage={productsPerPage}
          onClose={onAddPopupClose}
          onProductSave={onProductSave}
          setToast={setToast}
          setMessage={setMessage}
        />
      </Drawer>

      {showAdditionalFields && (
        <Drawer
          open={showAdditionalFields}
          onClose={() => setShowAdditionalFields(false)}
          anchor="right"
          PaperProps={{
            style: {
              paddingLeft: 28,
              paddingRight: 28,
              paddingBottom: 28,
              paddingTop: 20,
              width: 570,
            },
          }}
        >
          <AdditionalFields
            setOpenFields={setShowAdditionalFields}
            setToast={setToast}
            setMessage={setMessage}
          />
        </Drawer>
      )}
      <Drawer
        open={showVariantForm}
        onClose={onVariantPopupClose}
        anchor="right"
        PaperProps={{
          style: {
            paddingLeft: 28,
            paddingRight: 28,
            paddingBottom: 28,
            paddingTop: 20,
            width: 570,
          },
        }}
      >
        {anchoredProduct && (
          <VariantForm
            original={anchoredProduct}
            categories={anchoredCategories}
            storeId={storeId}
            onClose={onVariantPopupClose}
            onVariantSave={onVariantSave}
          />
        )}
      </Drawer>

      {commonModal && (
        <FormModal
          open={commonModal}
          onClose={() => setCommonModal(false)}
          btnTxt={
            <>
              {forModal === "EXPORT_CSV" && "Export"}
              {forModal === "EXPORT_PDF" && "Generate"}
              {(forModal === "MARK_OFFLINE_PROD" ||
                forModal === "MARK_ONLINE_PROD") &&
                "Update"}
              {(forModal === "DELETE_MULTI_PROD" ||
                forModal === "DELETE_PROD") &&
                "Delete"}
            </>
          }
          loading={circularProgress}
          isDelete={
            forModal === "DELETE_MULTI_PROD" || forModal === "DELETE_PROD"
          }
          onSave={onConfirmCommonModal}
          heading={
            <>
              {forModal === "EXPORT_CSV" && "Export Products"}
              {forModal === "EXPORT_PDF" && "Generate PDF"}
              {(forModal === "MARK_OFFLINE_PROD" ||
                forModal === "MARK_ONLINE_PROD") &&
                `Update ${selectedItems.size > 1 ? "Products" : "Product"}`}
              {(forModal === "DELETE_MULTI_PROD" ||
                forModal === "DELETE_PROD") &&
                `Delete ${selectedItems.size > 1 ? "Products" : "Product"}`}
            </>
          }
        >
          {getCommonModalText()}
        </FormModal>
      )}

      {openSeoSetting && anchoredProduct && (
        <SEOModal
          open={openSeoSetting}
          setOpen={setOpenSeoSetting}
          sku={anchoredProduct.sku}
          setToast={setToast}
          setMessage={setMessage}
        />
      )}
      <PaidPopUp
        open={openPremiumModal}
        close={() => setOpenPremiumModal(false)}
        plan={bulkAction === "IMPORT" ? BASIC : BUSINESS_APP_PRO}
        subtxt={
          bulkAction === "IMPORT"
            ? "Add products in Bulk using CSV file"
            : "Update products in bulk for easy inventory management"
        }
      />
      <Toast open={toast} close={setToast} message={message} />
    </div>
  );
};
export default Products;
