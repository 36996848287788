import React, { Suspense, useEffect, useState } from "react";
import {
  Route,
  Switch,
  withRouter,
  useHistory,
  useLocation,
  matchPath,
} from "react-router-dom";
import Sidebar from "../Sidebar/Sidebar";
import Dashboard from "../../pages/dashboard/Dashboard";
import Analytics from "../../pages/dashboard/Analytics";
import Categories from "../../pages/categories/Categories";
import Customers from "../../pages/customers/Customers";
import Invoices from "../../pages/invoices/Invoices";
import Orders from "../../pages/orders/Orders";
import Products from "../../pages/products/Products";
import Coupons from "../../pages/coupons";
import AppsStore from "../../pages/appstore";
import AppPage from "../../pages/appstore/AppPage";
import CheckoutFields from "../../pages/appstore/CheckoutFields";
import useStyles from "./styles";
import Reports from "../../pages/reports/Reports";
import Plans from "../../pages/plans/index";
import InvoicePage from "../../pages/invoices/components/InvoicePage";
import EditInvoice from "../../pages/invoices/EditInvoice";
import CreateInvoice from "../../pages/invoices/CreateInvoice";
import CreateOrder from "../../pages/orders/CreateOrder";
import EditOrder from "../../pages/orders/EditOrder";
import RazorpayOAuth from "../../pages/appstore/razorpay/RazorpayOAuth";
import RazorpayConnect from "../../pages/appstore/razorpay/RazorpayConnect";
import RazorpayOAuthCallback from "../../pages/appstore/razorpay/RazorpayOAuthCallback";
import RenewalPage from "../../pages/subscribe/RenewalPage";
import CouponForm from "../../pages/coupons/Components/CouponForm/index";
import Purchases from "../../pages/orders/Purchases";
import CreatePurchase from "../../pages/orders/CreatePurchase";
import Estimates from "../../pages/orders/Estimates";
import CreateEstimate from "../../pages/orders/CreateEstimate";
import ViewPurchase from "../../pages/orders/ViewPurchase";
import EditPurchase from "../../pages/orders/EditPurchase";
import ViewEstimate from "../../pages/orders/ViewEstimate";
import EditEstimate from "../../pages/orders/EditEstimate";
import Carts from "../../pages/carts/Carts";
import Header from "../Header/Header";
import Banners from "../../pages/banners/Banners";
import AddBanner from "../../pages/banners/components/AddBanner";
import EditBanner from "../../pages/banners/components/EditBanner";
import RenewConfirmPage from "../../pages/subscribe/RenewConfirmPage";
import Users from "../../pages/usersandroles/Users";
import AddUser from "../../pages/usersandroles/components/AddUser";
import {
  Landing as Collections,
  Create as CollectionCreate,
  Edit as CollectionEdit,
} from "../../pages/collections";
import {
  getDefaultStore,
  getHostedSubPath,
  getStoreIdFromUrl,
} from "../../utils/storeUtil";
import { Typography } from "@material-ui/core";
import PushNotification from "./PushNotification";
import ViewCustomer from "../../pages/customers/ViewCustomer";
import CustomerWalletTransactions from "../../pages/customers/CustomerWalletTransactions";
import WalletSettings from "../../pages/customers/components/WalletSettings";
import AddCustomer from "../../pages/customers/components/AddCustomer";
import EditCustomer from "../../pages/customers/components/EditCustomer";
import CustomerStoreTransactions from "../../pages/customers/CustomerStoreTransactions";
import { useDispatch, useSelector } from "react-redux";
import PaidPopUpV2 from "../PaidPopUpV2";
import { customerLogout } from "../../redux/customer/customerActions";
import { CLEAR_COMPLETE } from "../../redux/commom/commonTypes";
import PageLoader from "./PageLoader";
import Menu from "../../pages/settings/components/HeaderMenu/Menu";
import { isCustomDomain } from "../../utils/utils";
import { Helmet } from "react-helmet";
import { getUserMessagingToken } from "../../firebase";
import ReferAndEarn from "../../pages/referandearn/ReferAndEarn";
import ReferralConfig from "../../pages/referandearn/components/ReferralConfig";
import IndiamartConnect from "../../pages/appstore/indiamart/IndiamartConnect";
import XpressbeesConfig from "../../pages/appstore/xpressbees/XpressbeesConfig";
import IThinkCfg from "../../pages/appstore/ithink/IThinkCfg";
import ShiprocketConfig from "../../pages/appstore/shiprocket/ShiprocketConfig";
import { FacebookDomConfig } from "../../pages/appstore/fbdomain/FacebookDomConfig";
import { BaatConfig } from "../../pages/appstore/baat/BaatConfig";
import { WhatsappChatBubble } from "../../pages/appstore/whatsappChatBubble/WhatsappChatBubble";
import { GoogleSiteConfig } from "../../pages/appstore/googleSite/GoogleSiteConfig";
import { GoogleAdsPixelConfig } from "../../pages/appstore/googleAds/GoogleAdsPixelConfig";
import { MicrosoftClarityConfig } from "../../pages/appstore/MicrosoftClarity/MicrosoftClarityConfig";
import ConversionApiConfig from "../../pages/appstore/conversionApi/ConversionApiConfig";
import DelhiveryLogistics from "../../pages/appstore/delhivery/DelhiveryLogistics";
import GmcConfig from "../../pages/appstore/gmc/GmcConfig";
import FacebookPixelConfig from "../../pages/appstore/pixel/FacebookPixelConfig";
import TawkToConfig from "../../pages/appstore/pixel/TawkToConfig";
import GoogleAnalyticsConfig from "../../pages/appstore/pixel/GoogleAnalyticsConfig";
import ImageTest from "../../pages/image-test";
import Shipway from "../../pages/appstore/shipway/Shipway";
import Eshipz from "../../pages/appstore/eshipz";
import Wallet from "../../pages/wallet/Wallet";
import SettingsLayout from "../../pages/settings/SettingsLayout";
import Display from "../../pages/store-display/display";

const ThemeSetting = React.lazy(() => import("../../pages/store-theme"));
const ThemePreview = React.lazy(() =>
  import("../../pages/store-theme/preview")
);

const Blogs = React.lazy(() => import("../../pages/blogs"));
const BlogPage = React.lazy(() => import("../../pages/blogs/BlogPage"));
const ContentPage = React.lazy(() => import("../../pages/pages/ContentPage"));
const Pages = React.lazy(() => import("../../pages/pages/index"));

const Layout = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const location = useLocation();
  const store = useSelector((state) => state.store.store);
  const storeId = getStoreIdFromUrl();
  const history = useHistory();
  const [showToast, toggleToast] = useState(false);
  const [payload, setPayload] = useState(null);
  const [allowed, setAllowed] = useState(true);
  const [openPlanModal, setOpenPlanModal] = useState(false);
  const customDomain = isCustomDomain();

  useEffect(() => {
    document.addEventListener("wheel", function () {
      if (document.activeElement.type === "number") {
        document.activeElement.blur();
      }
    });
  }, []);

  useEffect(() => {
    setAllowed(true);
    if (storeId) {
      let storeUser = getDefaultStore();
      if (
        storeUser &&
        storeUser.role &&
        storeUser.role.indexOf("DELIVERY_BOY") >= 0
      ) {
        setAllowed(false);
      }
    }
  }, [storeId]);

  const onMessage = (_payload) => {
    setPayload(_payload);
    new Audio(
      "https://storage.googleapis.com/shy-pub/_static/audio/beep_audio.mp3"
    ).play();
    toggleToast(true);
  };

  if (!customDomain) {
    getUserMessagingToken(onMessage);
  }

  const handleLogOut = () => {
    dispatch(customerLogout());
    dispatch({
      type: CLEAR_COMPLETE,
      payload: {},
    });
    history.push(isCustomDomain() ? "/admin/login" : "/login");
  };

  const hideLayout = matchPath(location.pathname, {
    path: [
      "/:storeId/themes/preview",
      "/admin/:storeId/themes/preview",
      "/:storeId/blog/posts/:postId",
      "/admin/:storeId/blog/posts/:postId",
      "/:storeId/pages/:pageId",
      "/admin/:storeId/pages/:pageId",
    ],
  })?.isExact;

  if (!allowed) {
    return (
      <div>
        <Sidebar />
        <Header />
        <div className={classes.content}>
          <Typography>Not authorized to access the Store</Typography>
        </div>
      </div>
    );
  }
  return (
    <>
      <Helmet>
        <title>
          {customDomain && store.name ? store.name : "Shoopy"} Desktop
        </title>
      </Helmet>
      <div>
        <div style={{ ...(hideLayout && { display: "none" }) }}>
          <Sidebar />
          <Header />
        </div>
        <Suspense fallback={<PageLoader />}>
          <div
            className={classes.content}
            style={{
              ...(hideLayout && {
                marginLeft: 0,
                padding: 0,
              }),
            }}
          >
            <Switch>
              <Route
                exact
                path={["/admin/:storeId/dashboard", "/:storeId/dashboard"]}
                component={Dashboard}
              />
              <Route
                exact
                path={[
                  "/admin/:storeId/dashboard/analytics",
                  "/:storeId/dashboard/analytics",
                ]}
                component={Analytics}
              />
              <Route
                exact
                path={["/admin/:storeId/customers", "/:storeId/customers"]}
                component={Customers}
              />
              <Route
                exact
                path={[
                  "/admin/:storeId/customers/:customerid/wallet",
                  "/:storeId/customers/:customerid/wallet",
                ]}
                component={CustomerWalletTransactions}
              />

              <Route
                exact
                path={[
                  "/admin/:storeId/customers/:customerid/transactions",
                  "/:storeId/customers/:customerid/transactions",
                ]}
                component={CustomerStoreTransactions}
              />
              <Route
                exact
                path={[
                  "/admin/:storeId/customers/wallet-settings",
                  "/:storeId/customers/wallet-settings",
                ]}
                component={WalletSettings}
              />

              <Route
                exact
                path={[
                  "/admin/:storeId/customers/add",
                  "/:storeId/customers/add",
                ]}
                component={AddCustomer}
              />
              <Route
                exact
                path={[
                  "/admin/:storeId/customers/:customerid",
                  "/:storeId/customers/:customerid",
                ]}
                component={ViewCustomer}
              />
              <Route
                exact
                path={[
                  "/admin/:storeId/customers/:customerid/edit",
                  "/:storeId/customers/:customerid/edit",
                ]}
                component={EditCustomer}
              />

              <Route
                exact
                path={["/admin/:storeId/invoices", "/:storeId/invoices"]}
                component={Invoices}
              />
              <Route
                exact
                path={[
                  "/admin/:storeId/invoices/create",
                  "/:storeId/invoices/create",
                ]}
                component={CreateInvoice}
              />

              <Route
                exact
                path={[
                  "/admin/:storeId/invoices/:invoiceid",
                  "/:storeId/invoices/:invoiceid",
                ]}
                component={InvoicePage}
              />
              <Route
                exact
                path={[
                  "/admin/:storeId/invoices/:invoiceid/edit",
                  "/:storeId/invoices/:invoiceid/edit",
                ]}
                component={EditInvoice}
              />
              <Route
                exact
                path={["/admin/:storeId/products", "/:storeId/products"]}
                component={Products}
              />
              <Route
                exact
                path={["/admin/:storeId/categories", "/:storeId/categories"]}
                component={Categories}
              />

              <Route
                exact
                path={[
                  "/admin/:storeId/orders/create",
                  "/:storeId/orders/create",
                ]}
                component={CreateOrder}
              />
              <Route
                exact
                path={[
                  "/admin/:storeId/orders/:orderid/edit",
                  "/:storeId/orders/:orderid/edit",
                ]}
                component={EditOrder}
              />
              <Route
                exact
                path={["/admin/:storeId/orders", "/:storeId/orders"]}
                component={Orders}
              />
              <Route
                exact
                path={[
                  "/admin/:storeId/purchases/create",
                  "/:storeId/purchases/create",
                ]}
                component={CreatePurchase}
              />
              <Route
                exact
                path={[
                  "/admin/:storeId/purchases/:purchaseid",
                  "/:storeId/purchases/:purchaseid",
                ]}
                component={ViewPurchase}
              />
              <Route
                exact
                path={[
                  "/admin/:storeId/purchases/:purchaseid/edit",
                  "/:storeId/purchases/:purchaseid/edit",
                ]}
                component={EditPurchase}
              />
              <Route
                exact
                path={["/admin/:storeId/purchases", "/:storeId/purchases"]}
                component={Purchases}
              />
              <Route
                exact
                path={[
                  "/admin/:storeId/estimates/create",
                  "/:storeId/estimates/create",
                ]}
                component={CreateEstimate}
              />
              <Route
                exact
                path={[
                  "/admin/:storeId/estimates/:estimateid",
                  "/:storeId/estimates/:estimateid",
                ]}
                component={ViewEstimate}
              />
              <Route
                exact
                path={[
                  "/admin/:storeId/estimates/:estimateid/edit",
                  "/:storeId/estimates/:estimateid/edit",
                ]}
                component={EditEstimate}
              />
              <Route
                path={[
                  "/admin/:storeId/:settingSlug/menu",
                  "/:storeId/:settingSlug/menu",
                ]}
                component={Menu}
              />
              <Route
                path={["/admin/:storeId/estimates", "/:storeId/estimates"]}
                component={Estimates}
              />

              <Route
                path={["/admin/:storeId/settings", "/:storeId/settings"]}
                component={SettingsLayout}
              />
              <Route
                path={["/admin/:storeId/display", "/:storeId/display"]}
                component={Display}
              />

              <Route
                path={[
                  "/admin/:storeId/coupons/:operation",
                  "/:storeId/coupons/:operation",
                ]}
                component={CouponForm}
              />
              <Route
                path={["/admin/:storeId/coupons", "/:storeId/coupons"]}
                component={Coupons}
              />
              <Route
                path={[
                  "/admin/:storeId/apps/checkout-fields/configure",
                  "/:storeId/apps/checkout-fields/configure",
                ]}
                component={CheckoutFields}
              />
              <Route
                path={[
                  "/admin/:storeId/apps/razorpay/configure",
                  "/:storeId/apps/razorpay/configure",
                ]}
                component={RazorpayConnect}
              />

              <Route
                path={[
                  "/admin/:storeId/apps/razorpay/authorize",
                  "/:storeId/apps/razorpay/authorize",
                ]}
                component={RazorpayOAuth}
              />
              <Route
                path={["/admin/apps/razorpay/auth", "/apps/razorpay/auth"]}
                component={RazorpayOAuthCallback}
              />
              <Route
                path={[
                  "/admin/:storeId/apps/indiamart/configure",
                  "/:storeId/apps/indiamart/configure",
                ]}
                component={IndiamartConnect}
              />
              <Route
                path={[
                  "/admin/:storeId/apps/xpressbees/configure",
                  "/:storeId/apps/xpressbees/configure",
                ]}
                component={XpressbeesConfig}
              />
              <Route
                path={[
                  "/admin/:storeId/apps/baat/configure",
                  "/:storeId/apps/baat/configure",
                ]}
                component={BaatConfig}
              />
              <Route
                path={[
                  "/admin/:storeId/apps/whatsapp-chat-bubble/configure",
                  "/:storeId/apps/whatsapp-chat-bubble/configure",
                ]}
                component={WhatsappChatBubble}
              />

              <Route
                path={[
                  "/admin/:storeId/apps/shiprocket/configure",
                  "/:storeId/apps/shiprocket/configure",
                ]}
                component={ShiprocketConfig}
              />

              <Route
                path={[
                  "/admin/:storeId/apps/facebook-domain-verification/configure",
                  "/:storeId/apps/facebook-domain-verification/configure",
                ]}
                component={FacebookDomConfig}
              />

              <Route
                path={[
                  "/admin/:storeId/apps/facebook-conversion-api/configure",
                  "/:storeId/apps/facebook-conversion-api/configure",
                ]}
                component={ConversionApiConfig}
              />
              <Route
                path={[
                  "/admin/:storeId/apps/delhivery/configure",
                  "/:storeId/apps/delhivery/configure",
                ]}
                component={DelhiveryLogistics}
              />

              <Route
                path={[
                  "/admin/:storeId/apps/gmc/configure",
                  "/:storeId/apps/gmc/configure",
                ]}
                component={GmcConfig}
              />

              <Route
                path={[
                  "/admin/:storeId/apps/google-analytics/configure",
                  "/:storeId/apps/google-analytics/configure",
                ]}
                component={GoogleAnalyticsConfig}
              />

              <Route
                path={[
                  "/admin/:storeId/apps/facebook-pixel/configure",
                  "/:storeId/apps/facebook-pixel/configure",
                ]}
                component={FacebookPixelConfig}
              />
              <Route
                path={[
                  "/admin/:storeId/apps/tawkto/configure",
                  "/:storeId/apps/tawkto/configure",
                ]}
                component={TawkToConfig}
              />

              <Route
                path={[
                  "/admin/:storeId/apps/google-site-verification/configure",
                  "/:storeId/apps/google-site-verification/configure",
                ]}
                component={GoogleSiteConfig}
              />
              <Route
                path={[
                  "/admin/:storeId/apps/google-ads-pixel/configure",
                  "/:storeId/apps/google-ads-pixel/configure",
                ]}
                component={GoogleAdsPixelConfig}
              />
              <Route
                path={[
                  "/admin/:storeId/apps/clarity/configure",
                  "/:storeId/apps/clarity/configure",
                ]}
                component={MicrosoftClarityConfig}
              />
              <Route
                path={[
                  "/admin/:storeId/apps/ithink/configure",
                  "/:storeId/apps/ithink/configure",
                ]}
                component={IThinkCfg}
              />

              <Route
                path={[
                  "/admin/:storeId/apps/shipway/configure",
                  "/:storeId/apps/shipway/configure",
                ]}
                component={Shipway}
              />
              <Route
                path={[
                  "/admin/:storeId/apps/eshipz/configure",
                  "/:storeId/apps/eshipz/configure",
                ]}
                component={Eshipz}
              />

              <Route
                path={[
                  "/admin/:storeId/apps/:slug/configure",
                  "/:storeId/apps/:slug/configure",
                ]}
                component={AppPage}
              />
              <Route
                path={["/admin/:storeId/apps/:slug", "/:storeId/apps/:slug"]}
                component={AppPage}
              />
              <Route
                path={["/admin/:storeId/apps", "/:storeId/apps"]}
                component={AppsStore}
              />
              <Route
                path={["/admin/:storeId/reports", "/:storeId/reports"]}
                component={Reports}
              />
              <Route
                path={[
                  "/admin/:storeId/billing/plans/confirmation/:orderId",
                  "/:storeId/billing/plans/confirmation/:orderId",
                ]}
                component={RenewConfirmPage}
              />
              <Route
                path={[
                  "/admin/:storeId/billing/plans/info",
                  "/:storeId/billing/plans/info",
                ]}
                component={RenewalPage}
              />
              <Route
                path={[
                  "/admin/:storeId/billing/plans",
                  "/:storeId/billing/plans",
                ]}
                component={Plans}
              />
              <Route
                path={["/admin/:storeId/carts", "/:storeId/carts"]}
                component={Carts}
              />
              <Route
                path={["/admin/:storeId/banners/add", "/:storeId/banners/add"]}
                component={AddBanner}
              />
              <Route
                path={[
                  "/admin/:storeId/banners/:bannerid",
                  "/:storeId/banners/:bannerid",
                ]}
                component={EditBanner}
              />
              <Route
                path={["/admin/:storeId/banners", "/:storeId/banners"]}
                component={Banners}
              />
              <Route
                path={[
                  "/admin/:storeId/refer-and-earn/settings",
                  "/:storeId/refer-and-earn/settings",
                ]}
                component={ReferralConfig}
              />
              <Route
                path={[
                  "/admin/:storeId/refer-and-earn",
                  "/:storeId/refer-and-earn",
                ]}
                component={ReferAndEarn}
              />
              <Route
                path={["/admin/:storeId/users/add", "/:storeId/users/add"]}
                component={AddUser}
              />
              <Route
                path={["/admin/:storeId/users", "/:storeId/users"]}
                component={Users}
              />

              <Route
                exact
                path={[
                  "/admin/:storeId/collections/create",
                  "/:storeId/collections/create",
                ]}
                component={CollectionCreate}
              />
              <Route
                exact
                path={[
                  "/admin/:storeId/collections/:collectionId",
                  "/:storeId/collections/:collectionId",
                ]}
                component={CollectionEdit}
              />
              <Route
                path={["/admin/:storeId/collections", "/:storeId/collections"]}
                component={Collections}
              />
              <Route
                path={["/admin/:storeId/image-test", "/:storeId/image-test"]}
                component={ImageTest}
              />
              <Route
                path={[
                  "/admin/:storeId/themes/preview",
                  "/:storeId/themes/preview",
                ]}
                component={ThemePreview}
              />
              <Route
                path={["/admin/:storeId/themes/", "/:storeId/themes/"]}
                component={ThemeSetting}
              />
              <Route
                path={[
                  "/admin/:storeId/blog/posts/:postId",
                  "/:storeId/blog/posts/:postId",
                ]}
                component={BlogPage}
              />
              <Route
                path={["/admin/:storeId/blog/posts", "/:storeId/blog/posts"]}
                component={Blogs}
              />
              <Route
                path={[
                  "/admin/:storeId/pages/:pageId",
                  "/:storeId/pages/:pageId",
                ]}
                component={ContentPage}
              />
              <Route
                path={["/admin/:storeId/pages", "/:storeId/pages"]}
                component={Pages}
              />
              <Route
                exact
                path={["/admin/:storeId/wallet", "/:storeId/wallet"]}
                component={Wallet}
              />
            </Switch>
          </div>
        </Suspense>
      </div>
      {showToast && (
        <PushNotification
          title={payload.notification.title}
          subTitle={payload.notification.body}
          onClick={() => {
            history.push(
              getHostedSubPath(storeId, `orders?refetch=${Date.now()}`)
            );
            toggleToast(false);
          }}
          close={() => toggleToast(false)}
        />
      )}
      <PaidPopUpV2
        open={openPlanModal}
        close={handleLogOut}
        subtxt="Upgrade to our paid plans to access amazing Desktop App features."
        outlinedBtnText="Sign Out"
        hideCloseIcon={true}
      />
    </>
  );
};
export default withRouter(Layout);
