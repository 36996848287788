export const widgetIconGenerator = (bg, color, size = 48) => {
  const WeightIcons = {
    style1: `
          <svg style="position: absolute;top: 0;left: 0;right: 0;bottom: 0;width: 70%;height: 70%;margin: auto;" viewBox="0 0 18 18" fill="${color}" xmlns="http://www.w3.org/2000/svg">
          <mask id="mask0_4554_21995" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="18" height="18">
          <rect x="0.272949" y="0.272705" width="17.4545" height="17.4545" fill="#D9D9D9"/>
          </mask>
          <g mask="url(#mask0_4554_21995)">
          </g>
          <path d="M14.1273 3.84364C13.4605 3.17013 12.6663 2.63613 11.791 2.27277C10.9157 1.90941 9.97685 1.72398 9.02913 1.72728C5.05822 1.72728 1.82185 4.96364 1.82185 8.93455C1.82185 10.2073 2.1564 11.4436 2.78185 12.5345L1.76367 16.2727L5.58185 15.2691C6.6364 15.8436 7.82185 16.1491 9.02913 16.1491C13 16.1491 16.2364 12.9127 16.2364 8.94182C16.2364 7.01455 15.4873 5.20364 14.1273 3.84364ZM9.02913 14.9273C7.95276 14.9273 6.89822 14.6364 5.97458 14.0909L5.7564 13.96L3.48731 14.5564L4.09094 12.3455L3.94549 12.12C3.34749 11.1651 3.02996 10.0613 3.02913 8.93455C3.02913 5.63273 5.72004 2.94182 9.02185 2.94182C10.6219 2.94182 12.1273 3.56728 13.2546 4.70182C13.8127 5.25743 14.2551 5.9183 14.556 6.64613C14.8568 7.37396 15.0102 8.15426 15.0073 8.94182C15.0219 12.2436 12.3309 14.9273 9.02913 14.9273ZM12.3164 10.4473C12.1346 10.36 11.2473 9.92364 11.0873 9.85818C10.92 9.8 10.8037 9.77091 10.68 9.94546C10.5564 10.1273 10.2146 10.5345 10.1128 10.6509C10.0109 10.7745 9.90185 10.7891 9.72003 10.6945C9.53822 10.6073 8.9564 10.4109 8.27276 9.8C7.73458 9.32 7.37822 8.73091 7.26913 8.54909C7.16731 8.36728 7.25458 8.27273 7.34913 8.17818C7.42913 8.09819 7.53094 7.96728 7.61822 7.86546C7.70549 7.76364 7.74185 7.68364 7.80004 7.56728C7.85822 7.44364 7.82913 7.34182 7.78549 7.25455C7.74185 7.16728 7.37822 6.28 7.23276 5.91637C7.08731 5.56728 6.93458 5.61091 6.82549 5.60364H6.4764C6.35276 5.60364 6.16367 5.64728 5.9964 5.82909C5.8364 6.01091 5.37094 6.44728 5.37094 7.33455C5.37094 8.22182 6.01822 9.08 6.10549 9.19637C6.19276 9.32 7.37822 11.1382 9.18185 11.9164C9.61094 12.1055 9.94549 12.2145 10.2073 12.2945C10.6364 12.4327 11.0291 12.4109 11.3419 12.3673C11.6909 12.3164 12.4109 11.9309 12.5564 11.5091C12.7091 11.0873 12.7091 10.7309 12.6582 10.6509C12.6073 10.5709 12.4982 10.5345 12.3164 10.4473Z"/>
          </svg>
      `,

    style2: `
          <svg style="position: absolute;top: 0;left: 0;right: 0;bottom: 0;width: 70%;height: 70%;margin: auto;" viewBox="0 0 18 18" fill="${color}" xmlns="http://www.w3.org/2000/svg">
          <mask id="mask0_4554_21995" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="18" height="18">
          <rect x="0.272949" y="0.272705" width="17.4545" height="17.4545" fill="#D9D9D9"/>
          </mask>
          <g mask="url(#mask0_4554_21995)">
          </g>
          <path d="M14.1273 3.84364C13.4605 3.17013 12.6663 2.63613 11.791 2.27277C10.9157 1.90941 9.97685 1.72398 9.02913 1.72728C5.05822 1.72728 1.82185 4.96364 1.82185 8.93455C1.82185 10.2073 2.1564 11.4436 2.78185 12.5345L1.76367 16.2727L5.58185 15.2691C6.6364 15.8436 7.82185 16.1491 9.02913 16.1491C13 16.1491 16.2364 12.9127 16.2364 8.94182C16.2364 7.01455 15.4873 5.20364 14.1273 3.84364ZM9.02913 14.9273C7.95276 14.9273 6.89822 14.6364 5.97458 14.0909L5.7564 13.96L3.48731 14.5564L4.09094 12.3455L3.94549 12.12C3.34749 11.1651 3.02996 10.0613 3.02913 8.93455C3.02913 5.63273 5.72004 2.94182 9.02185 2.94182C10.6219 2.94182 12.1273 3.56728 13.2546 4.70182C13.8127 5.25743 14.2551 5.9183 14.556 6.64613C14.8568 7.37396 15.0102 8.15426 15.0073 8.94182C15.0219 12.2436 12.3309 14.9273 9.02913 14.9273ZM12.3164 10.4473C12.1346 10.36 11.2473 9.92364 11.0873 9.85818C10.92 9.8 10.8037 9.77091 10.68 9.94546C10.5564 10.1273 10.2146 10.5345 10.1128 10.6509C10.0109 10.7745 9.90185 10.7891 9.72003 10.6945C9.53822 10.6073 8.9564 10.4109 8.27276 9.8C7.73458 9.32 7.37822 8.73091 7.26913 8.54909C7.16731 8.36728 7.25458 8.27273 7.34913 8.17818C7.42913 8.09819 7.53094 7.96728 7.61822 7.86546C7.70549 7.76364 7.74185 7.68364 7.80004 7.56728C7.85822 7.44364 7.82913 7.34182 7.78549 7.25455C7.74185 7.16728 7.37822 6.28 7.23276 5.91637C7.08731 5.56728 6.93458 5.61091 6.82549 5.60364H6.4764C6.35276 5.60364 6.16367 5.64728 5.9964 5.82909C5.8364 6.01091 5.37094 6.44728 5.37094 7.33455C5.37094 8.22182 6.01822 9.08 6.10549 9.19637C6.19276 9.32 7.37822 11.1382 9.18185 11.9164C9.61094 12.1055 9.94549 12.2145 10.2073 12.2945C10.6364 12.4327 11.0291 12.4109 11.3419 12.3673C11.6909 12.3164 12.4109 11.9309 12.5564 11.5091C12.7091 11.0873 12.7091 10.7309 12.6582 10.6509C12.6073 10.5709 12.4982 10.5345 12.3164 10.4473Z"/>
          </svg>
      `,

    style3: `
      <svg style="position: absolute;top: 0;left: 0;right: 0;bottom: 0;width: 68%;height: 68%;margin: auto;" viewBox="0 0 18 18" fill="${color}" xmlns="http://www.w3.org/2000/svg">
          <path d="M15.1875 3.375H2.81252C2.51415 3.375 2.228 3.49353 2.01702 3.7045C1.80605 3.91548 1.68752 4.20163 1.68752 4.5V15.75C1.68625 15.9644 1.74687 16.1747 1.86211 16.3555C1.97734 16.5364 2.14231 16.6801 2.33721 16.7695C2.48614 16.8387 2.64832 16.8747 2.81252 16.875C3.07662 16.8744 3.33195 16.7802 3.53322 16.6092C3.53658 16.607 3.53965 16.6044 3.54236 16.6015L5.8008 14.625H15.1875C15.4859 14.625 15.772 14.5065 15.983 14.2955C16.194 14.0845 16.3125 13.7984 16.3125 13.5V4.5C16.3125 4.20163 16.194 3.91548 15.983 3.7045C15.772 3.49353 15.4859 3.375 15.1875 3.375ZM15.1875 13.5H5.8008C5.53588 13.4999 5.27941 13.5933 5.07658 13.7637L5.06814 13.7714L2.81252 15.75V4.5H15.1875V13.5ZM6.18752 7.875C6.18752 7.72582 6.24678 7.58274 6.35227 7.47725C6.45776 7.37176 6.60084 7.3125 6.75002 7.3125H11.25C11.3992 7.3125 11.5423 7.37176 11.6478 7.47725C11.7533 7.58274 11.8125 7.72582 11.8125 7.875C11.8125 8.02418 11.7533 8.16726 11.6478 8.27275C11.5423 8.37824 11.3992 8.4375 11.25 8.4375H6.75002C6.60084 8.4375 6.45776 8.37824 6.35227 8.27275C6.24678 8.16726 6.18752 8.02418 6.18752 7.875ZM6.18752 10.125C6.18752 9.97582 6.24678 9.83274 6.35227 9.72725C6.45776 9.62176 6.60084 9.5625 6.75002 9.5625H11.25C11.3992 9.5625 11.5423 9.62176 11.6478 9.72725C11.7533 9.83274 11.8125 9.97582 11.8125 10.125C11.8125 10.2742 11.7533 10.4173 11.6478 10.5227C11.5423 10.6282 11.3992 10.6875 11.25 10.6875H6.75002C6.60084 10.6875 6.45776 10.6282 6.35227 10.5227C6.24678 10.4173 6.18752 10.2742 6.18752 10.125Z"/>
          </svg>
      `,

    style4: `
          <svg style="position: absolute;top: 0;left: 0;right: 0;bottom: 0;width: 53%;height: 53%;margin: auto;" viewBox="0 0 12 3" fill="${color}" xmlns="http://www.w3.org/2000/svg">
          <circle cx="1.5" cy="1.5" r="1.5"/>
          <circle cx="6" cy="1.5" r="1.5"/>
          <circle cx="10.5" cy="1.5" r="1.5"/>
          </svg>
      `,

    style5: `
          <svg style="position: absolute;top: 0;left: 0;right: 0;bottom: 0;width: 53%;height: 53%;margin: auto;" viewBox="0 0 12 3" fill="${color}" xmlns="http://www.w3.org/2000/svg">
          <circle cx="1.5" cy="1.5" r="1.5"/>
          <circle cx="6" cy="1.5" r="1.5"/>
          <circle cx="10.5" cy="1.5" r="1.5"/>
          </svg>
      `,

    style6: `
          <svg style="position: absolute;top: 0;left: 0;right: 0;bottom: 0;width: 53%;height: 53%;margin: auto;" viewBox="0 0 13 7" fill="${color}" xmlns="http://www.w3.org/2000/svg">
          <path d="M1.69875 4.47748C1.17478 4.47748 0.75 4.90229 0.75 5.42623C0.75 5.95017 1.17478 6.37498 1.69875 6.37498H8.65625C9.18019 6.37498 9.605 5.95017 9.605 5.42623C9.605 4.90229 9.18019 4.47748 8.65625 4.47748H1.69875Z"/>
          <path d="M1.69875 0.0499878C1.17478 0.0499878 0.75 0.474763 0.75 0.998738C0.75 1.52268 1.17478 1.94749 1.69875 1.94749H11.8187C12.3427 1.94749 12.7675 1.52268 12.7675 0.998738C12.7675 0.474763 12.3427 0.0499878 11.8187 0.0499878H1.69875Z"/>
          </svg>
      `,

    style7: `
          <svg style="position: absolute;top: 0;left: 0;right: 0;bottom: 0;width: 60%;height: 60%;margin: auto;" viewBox="0 0 13 3" fill="${color}" xmlns="http://www.w3.org/2000/svg">
          <path d="M5.34961 1.5004C5.34961 2.13552 5.8645 2.6504 6.49961 2.6504C7.13472 2.6504 7.64961 2.13552 7.64961 1.5004C7.64961 0.865289 7.13472 0.350403 6.49961 0.350403C5.8645 0.350403 5.34961 0.865289 5.34961 1.5004Z"/>
          <path d="M0.75 1.5004C0.75 2.13552 1.26488 2.6504 1.9 2.6504C2.53512 2.6504 3.05 2.13552 3.05 1.5004C3.05 0.865289 2.53512 0.350403 1.9 0.350403C1.26488 0.350403 0.75 0.865289 0.75 1.5004Z"/>
          <path d="M9.94971 1.5004C9.94971 2.13552 10.4646 2.6504 11.0997 2.6504C11.7348 2.6504 12.2497 2.13552 12.2497 1.5004C12.2497 0.865289 11.7348 0.350403 11.0997 0.350403C10.4646 0.350403 9.94971 0.865289 9.94971 1.5004Z"/>
          </svg>
      `,
  };

  const WeightIconsWithBG = {
    style1: `
      <div style="width: ${size}px;height: ${size}px;position: relative;">
          <svg style="width: 100%; height: 100%;" viewBox="0 0 24 24" fill="${bg}" xmlns="http://www.w3.org/2000/svg">
          <path d="M0 4C0 1.79086 1.79086 0 4 0H20C22.2091 0 24 1.79086 24 4V20C24 22.2091 22.2091 24 20 24H4C1.79086 24 0 22.2091 0 20V4Z"/>
          </svg>
          
          ${WeightIcons.style1}
      </div>
      `,

    style2: `
      <div style="width: ${size}px;height: ${size}px;position: relative;">
          <svg style="width: 100%; height: 100%;" viewBox="0 0 24 24" fill="${bg}" xmlns="http://www.w3.org/2000/svg">
          <path d="M0 12C0 5.37258 5.37258 0 12 0C18.6274 0 24 5.37258 24 12C24 18.6274 18.6274 24 12 24C5.37258 24 0 18.6274 0 12Z"/>
          </svg>
  
          ${WeightIcons.style2}
      </div>
      `,

    style3: `
      <div style="width: ${size}px;height: ${size}px;position: relative;">
          <svg style="width: 100%; height: 100%;" viewBox="0 0 24 24" fill="${bg}" xmlns="http://www.w3.org/2000/svg">
          <path d="M0 4C0 1.79086 1.79086 0 4 0H20C22.2091 0 24 1.79086 24 4V20C24 22.2091 22.2091 24 20 24H4C1.79086 24 0 22.2091 0 20V4Z"/>
          </svg>
  
          ${WeightIcons.style3}
      </div>
      `,

    style4: `
      <div style="width: ${size}px;height: ${size}px;position: relative;">
          <svg style="width: 100%; height: 100%;" viewBox="0 0 24 24" fill="${bg}" xmlns="http://www.w3.org/2000/svg">
          <path d="M0 4C0 1.79086 1.79086 0 4 0H20C22.2091 0 24 1.79086 24 4V20C24 22.2091 22.2091 24 20 24H4C1.79086 24 0 22.2091 0 20V4Z"/>
          </svg>
  
          ${WeightIcons.style4}
      </div>
      `,

    style5: `
      <div style="width: ${size}px;height: ${size}px;position: relative;">
          <svg style="width: 100%; height: 100%;" viewBox="0 0 24 24" fill="${bg}" xmlns="http://www.w3.org/2000/svg">
          <path d="M0 12C0 5.37258 5.37258 0 12 0C18.6274 0 24 5.37258 24 12V19.2C24 21.851 21.851 24 19.2 24H12C5.37258 24 0 18.6274 0 12Z"/>
          </svg>
      
          ${WeightIcons.style5}
      </div>
      `,

    style6: `
      <div style="width: ${size}px;height: ${size}px;position: relative;">
          <svg style="width: 100%; height: 100%;" viewBox="0 0 23 23" fill="${bg}" xmlns="http://www.w3.org/2000/svg">
          <path d="M11.5 23C17.8512 23 23 17.8512 23 11.5C23 5.14872 17.8512 0 11.5 0C5.14872 0 0 5.14872 0 11.5C0 13.3397 0.431963 15.0783 1.19998 16.6204C1.40408 17.0301 1.47201 17.4985 1.35369 17.9407L0.668737 20.5007C0.371393 21.6119 1.38806 22.6285 2.49935 22.3313L5.0593 21.6463C5.50152 21.528 5.96989 21.596 6.37966 21.8C7.92161 22.5681 9.66034 23 11.5 23Z"/>
          </svg>
      
          ${WeightIcons.style6}
      </div>
      `,

    style7: `
      <div style="width: ${size}px;height: ${size}px;position: relative;">
          <svg style="width: 100%; height: 100%;" viewBox="0 0 23 23" fill="${bg}" xmlns="http://www.w3.org/2000/svg">
          <path d="M11.5 23C17.8512 23 23 17.8512 23 11.5C23 5.14872 17.8512 0 11.5 0C5.14872 0 0 5.14872 0 11.5C0 13.3397 0.431963 15.0783 1.19998 16.6204C1.40408 17.0301 1.47201 17.4985 1.35369 17.9407L0.668737 20.5007C0.371393 21.6119 1.38806 22.6285 2.49935 22.3313L5.0593 21.6463C5.50152 21.528 5.96989 21.596 6.37966 21.8C7.92161 22.5681 9.66034 23 11.5 23Z"/>
          </svg>
      
          ${WeightIcons.style7}
      </div>
      `,
  };

  return {
    WeightIcons,
    WeightIconsWithBG,
  };
};
