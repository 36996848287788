import React, { useContext } from "react";
import Input from "../../../../../../../../../../../../components/controls/Input";
import { MenuItem } from "@material-ui/core";
import fontFamily from "../../../../../../../../assets/FontFamily.json";
import { ChatWeightContext } from "../../../../../../../../context/Context";

const TextFont = () => {
  const {
    chatWindowStates: { textFont, textFontDispatch },
  } = useContext(ChatWeightContext);
  return (
    <Input
      select
      name="text_font"
      label="Text Font"
      value={textFont}
      onChange={(e) => textFontDispatch(e.target.value)}
      fullWidth
      labelFontWeight={400}
      labelPlacement="top"
    >
      {fontFamily.map((family) => (
        <MenuItem
          key={family}
          value={family}
          style={{ fontFamily: `"${family}", sans-serif` }}
        >
          {family}
        </MenuItem>
      ))}
    </Input>
  );
};

export default TextFont;
