import React, { useEffect, useState } from "react";
import { List } from "@material-ui/core";
import SidebarLink from "./components/SidebarLink";
import useStyles from "./styles";
import { withRouter } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  CatalogueIcon,
  CustomersIcon,
  AppStoreIcon,
  CouponIcon,
  ReportsIcon,
  DashboardIcon,
  ShoopyWhiteLongLogo,
} from "../../icons";
import { customerLogout } from "../../redux/customer/customerActions";
import { CLEAR_COMPLETE } from "../../redux/commom/commonTypes";
import { getDefaultStore, getStoreIdFromUrl } from "../../utils/storeUtil";

import InvoiceIcon from "../../icons/InvoiceIcon";
import OrdersIcon from "../../icons/OrdersIcon";
import Toast from "../Layout/Toast";
import { setBulkToast } from "../../redux/product/productActions";
import SubscriptionIcon from "../../icons/SubscriptionIcon";
import { rowFlexCenterAll } from "../../styles/common";
import StoreLink from "../common/StoreLink";
import OnlineStoreIcon from "../../icons/OnlineStoreIcon";
import { isCustomDomain } from "../../utils/utils";
import { cdnUrl } from "../../utils/cdnUtils";
import WalletLink from "../common/WalletLink";

const Sidebar = ({ location, history }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const storeId = getStoreIdFromUrl();
  const storeSu = getDefaultStore();
  const customDomain = isCustomDomain();
  const pathPrefix = customDomain ? `admin/${storeId}` : storeId;
  const initStructure = [
    {
      id: 0,
      label: "Dashboard",
      path: `/${pathPrefix}/dashboard`,
      icon: <DashboardIcon />,
    },
    {
      id: 1,
      label: "Invoices",
      path: `/${pathPrefix}/invoices`,
      icon: <InvoiceIcon />,
      allowedRoles: ["SUPER_ADMIN", "ADMIN", "BILLING_MANAGER"],
    },
    {
      id: 2,
      label: "Orders",
      path: `/${pathPrefix}/orders`,
      icon: <OrdersIcon />,
      allowedRoles: [
        "SUPER_ADMIN",
        "ADMIN",
        "BILLING_MANAGER",
        "DELIVERY_ADMIN",
      ],
      children: [
        { id: 101, label: "Online", path: `/${pathPrefix}/orders` },
        { id: 102, label: "Purchase", path: `/${pathPrefix}/purchases` },
        { id: 103, label: "Estimate", path: `/${pathPrefix}/estimates` },
        { id: 104, label: "Abandoned Carts", path: `/${pathPrefix}/carts` },
      ],
    },
    {
      id: 3,
      label: "Catalog",
      path: `/${pathPrefix}/products`,
      icon: <CatalogueIcon />,
      children: [
        { id: 301, label: "Products", path: `/${pathPrefix}/products` },
        { id: 302, label: "Categories", path: `/${pathPrefix}/categories` },
        { id: 303, label: "Collections", path: `/${pathPrefix}/collections` },
      ],
    },
    {
      id: 5,
      label: "Customers",
      path: `/${pathPrefix}/customers`,
      icon: <CustomersIcon />,
      allowedRoles: ["SUPER_ADMIN", "ADMIN", "BILLING_MANAGER"],
    },
    {
      id: 6,
      label: "Promotions",
      path: `/${pathPrefix}/coupons`,
      icon: <CouponIcon />,
      children: [
        { id: 601, label: "Coupons", path: `/${pathPrefix}/coupons` },
        { id: 602, label: "Banners", path: `/${pathPrefix}/banners` },
        {
          id: 603,
          label: "Refer & Earn",
          path: `/${pathPrefix}/refer-and-earn`,
        },
      ],
    },
    {
      id: 7,
      label: "Reports",
      path: `/${pathPrefix}/reports`,
      icon: <ReportsIcon />,
      allowedRoles: ["SUPER_ADMIN", "ADMIN"],
    },
    {
      id: 8,
      label: "Online Store",
      path: `/${pathPrefix}/settings/store-details`,
      icon: <OnlineStoreIcon />,
      allowedRoles: ["SUPER_ADMIN", "ADMIN"],
      children: [
        {
          id: 801,
          label: "Store Settings",
          path: `/${pathPrefix}/settings/store-details`,
          subpaths: [
            `/${pathPrefix}/settings/product-and-order`,
            `/${pathPrefix}/settings/store-domain`,
            `/${pathPrefix}/settings/delivery`,
            `/${pathPrefix}/settings/payments`,
            `/${pathPrefix}/settings/return-order`,
            `/${pathPrefix}/settings/label`,
            `/${pathPrefix}/settings/seo`,
            `/${pathPrefix}/settings/notifications`,
            `/${pathPrefix}/settings/login`,
          ],
        },
        {
          id: 802,
          label: "Display Settings",
          path: `/${pathPrefix}/display`,
        },
        {
          id: 803,
          label: "Themes",
          path: `/${pathPrefix}/themes`,
        },
        {
          id: 804,
          label: "Users And Roles",
          path: `/${pathPrefix}/users`,
          allowedRoles: ["SUPER_ADMIN"],
        },
        {
          id: 805,
          label: "Store Blog",
          path: `/${pathPrefix}/blog/posts`,
          allowedRoles: ["SUPER_ADMIN", "ADMIN"],
        },
        {
          id: 806,
          label: "Store Pages",
          path: `/${pathPrefix}/pages`,
          allowedRoles: ["SUPER_ADMIN", "ADMIN"],
        },
      ],
    },
    {
      id: 9,
      label: "App Store",
      hideOnCustom: true,
      path: `/${pathPrefix}/apps`,
      icon: <AppStoreIcon />,
      allowedRoles: ["SUPER_ADMIN", "ADMIN"],
    },
    {
      id: 10,
      label: "Billing Plans",
      hideOnCustom: true,
      path: `/${pathPrefix}/billing/plans`,
      icon: <SubscriptionIcon />,
      allowedRoles: ["SUPER_ADMIN"],
    },
  ];

  const [structure, setStructure] = useState(initStructure);

  useEffect(() => {
    window.addEventListener("storage", (e) => {
      if (e.key === "access_token" && e.oldValue && !e.newValue) {
        dispatch(customerLogout());
        dispatch({
          type: CLEAR_COMPLETE,
          payload: {},
        });
        history.push(customDomain ? "/admin/login" : "/login");
      }
    });
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    let storeUser = getDefaultStore();
    if (storeUser && storeUser.role) {
      let options = [];
      initStructure.forEach((struct) => {
        if (
          !struct.allowedRoles ||
          struct.allowedRoles.includes(storeUser.role)
        ) {
          let newStruct = { ...struct };
          if (struct.children) {
            let modChildren = struct.children.filter(
              (chld) =>
                !chld.allowedRoles || chld.allowedRoles.includes(storeUser.role)
            );
            newStruct.children = modChildren;
          }
          options.push(newStruct);
        }
      });
      setStructure(options);
    }
    // eslint-disable-next-line
  }, [storeId]);

  const bulkToast = useSelector((state) => state.products.bulkToast);

  return (
    <div className={classes.root}>
      <div>
        <div className={`${rowFlexCenterAll} ${classes.logo}`}>
          <StoreLink href="/dashboard">
            {customDomain && storeSu.logo_url ? (
              <div>
                <img
                  alt="Logo"
                  style={{ width: "unset", height: 48 }}
                  src={cdnUrl(storeSu.logo_url, null, 200, 1)}
                />
              </div>
            ) : (
              <ShoopyWhiteLongLogo />
            )}
          </StoreLink>
        </div>
        <List className={classes.sidebarlist}>
          {structure
            .filter(
              (link) =>
                (!customDomain && !link.hide) ||
                (customDomain && !link.hideOnCustom)
            )
            .map((link) => (
              <SidebarLink key={link.id} location={location} {...link} />
            ))}
        </List>
      </div>
      <WalletLink pathPrefix={pathPrefix} location={location} />
      <Toast
        open={Boolean(bulkToast.message)}
        close={() => {
          dispatch(setBulkToast({ message: "", severity: "" }));
        }}
        message={bulkToast.message}
        severity={bulkToast.severity}
        autoClose={false}
      />
    </div>
  );
};

export default withRouter(Sidebar);
