import { useOutputData } from "../../Right/hooks/useOutputData";
import { useChatBubbleGenerator } from "../../Right/hooks/useChatBubbleGenerator";
import { useChatWindowGenerator } from "../../Right/hooks/useChatWindowGenerator";

export const useOutputHtml = () => {
  const windowHtml = useChatWindowGenerator();
  const bubbleHtml = useChatBubbleGenerator();
  const { cssData, jsdata } = useOutputData();

  const html = `<style>${cssData}</style><script>setTimeout(()=>{function isMobile(){const e=navigator.userAgent||navigator.vendor||window.opera;return/android|iphone|ipad|ipod|blackberry|iemobile|opera mini|mobile/i.test(e)}if(isMobile()){const e=document.getElementById("cb12_window_wrapper"),t=document.getElementById("cb12_bubble_wrapper"),o=document.querySelector(".sh-bottom-nav-main");e&&t&&o&&(e.style.bottom=\`calc(var(--cb-size) + 36px + \${o.offsetHeight}px)\`,t.style.bottom=\`\${o.offsetHeight+16}px\`)}}, 0);</script><div id="cb12_window_wrapper" class="cb12_wrapper"><style>#cb12_window_wrapper.active {bottom: calc(var(--cb-size) + 36px);} #cb12_window_wrapper {visibility: hidden;opacity: 0;transition: 150ms ease-in-out;${
    jsdata.cb_position === "left"
      ? "transform: translate(-100%, 0); left: 16px;"
      : "transform: translate(100%, 0); right: 16px;"
  }} #cb12_window_wrapper.active {visibility: visible;opacity: 1;transform: translate(0, 0);}</style>${windowHtml}</div><div class="cb12_wrapper ${
    jsdata.cb_position === "left" ? "cb12_left" : "cb12_right"
  }" id="cb12_bubble_wrapper"><div onclick="(()=>{const chatWindow=document.getElementById('cb12_window_wrapper');const chatBubble=document.getElementById('cb12_bubble_wrapper');const outsideChatWindowClickListner=(e)=>{if(chatWindow&&chatBubble&&!(chatWindow.contains(e.target)||chatBubble.contains(e.target))){chatWindow.classList.remove('active');}};if(chatWindow&&chatBubble){if(chatWindow.classList.contains('active')){chatWindow.classList.remove('active');document.removeEventListener('click',outsideChatWindowClickListner);}else{chatWindow.classList.add('active');document.addEventListener('click',outsideChatWindowClickListner);}}})()">${bubbleHtml}</div></div>`;

  return html;
};
