export const SHOOPY_HOST = "store.shoopy.in";

export const isCustomDomain = () => {
  let domain = window.location.hostname;
  return !(
    domain === "localhost" ||
    domain.startsWith("127.0.0.1") ||
    domain.endsWith(".shoopy.in")
  );
};

export const getGSTTaxGroupById = (taxGroups, taxId) => {
  let matches = taxGroups.filter((tg) => tg.id === taxId);
  let taxGroup = null;
  if (matches && matches.length > 0) {
    taxGroup = matches[0];
  }
  return taxGroup;
};

export const getItemTaxCodes = (taxGroups) => {
  return taxGroups
    .filter((tg) => tg.name && tg.name.startsWith("GST"))
    .sort(function (tg1, tg2) {
      return tg1.total - tg2.total;
    })
    .map((tg) => {
      return { id: tg.total.toString(), title: tg.name };
    });
};

export const getTaxCodes = (taxGroups) => {
  return taxGroups
    .filter((tg) => tg.name && tg.name.startsWith("GST"))
    .sort(function (tg1, tg2) {
      return tg1.total - tg2.total;
    })
    .map((tg) => {
      return { id: tg.id, title: tg.name };
    });
};

export const getTaxPercentage = (taxGroups, code) => {
  return taxGroups.filter((tg) => tg.id === code);
};

export const getMeasuringUnits = () => [
  { id: "bag", title: "Bags" },
  { id: "bal", title: "Bale" },
  { id: "bou", title: "Billions of Units" },
  { id: "btl", title: "Bottles" },
  { id: "box", title: "Box" },
  { id: "bkl", title: "Buckles" },
  { id: "bck", title: "Buckets" },
  { id: "bun", title: "Bunches" },
  { id: "bdl", title: "Bundles" },
  { id: "can", title: "Cans" },
  { id: "ctn", title: "Cartons" },
  { id: "cms", title: "Centimeter" },
  { id: "ccm", title: "Cubic Centimeter" },
  { id: "cbm", title: "Cubic Meter" },
  { id: "doz", title: "Dozen" },
  { id: "drm", title: "Drum" },
  { id: "gms", title: "Grams" },
  { id: "grs", title: "Gross" },
  { id: "gyd", title: "Gross Yards" },
  { id: "kgs", title: "Kilogram" },
  { id: "ltr", title: "Litre" },
  { id: "mtr", title: "Meters" },
  { id: "mlt", title: "Millilitre" },
  { id: "nos", title: "Numbers" },
  { id: "pac", title: "Packs" },
  { id: "prs", title: "Pairs" },
  { id: "pcs", title: "Pieces" },
  { id: "pound", title: "Pound" },
  { id: "qtl", title: "Quintal" },
  { id: "rol", title: "Rolls" },
  { id: "sets", title: "Sets" },
  { id: "sqf", title: "Square Feet" },
  { id: "sqm", title: "Square Meters" },
  { id: "sqy", title: "Square Yards" },
  { id: "tbs", title: "Tablets" },
  { id: "tgm", title: "Ten Gross" },
  { id: "thd", title: "Thousands" },
  { id: "ton", title: "Tonnes" },
  { id: "tub", title: "Tubes" },
  { id: "unt", title: "Units" },
  { id: "ugs", title: "US Gallons" },
  { id: "yds", title: "Yards" },
];

export const paymentOptions = [
  { id: "none", title: "Default" },
  { id: "COD", title: "COD Only" },
  { id: "ONLINE", title: "Prepaid Only" },
];

export const PRODUCTS_KEY = "productsPerPage";
export const DEFAULT_PRODUCTS_PER_PAGE = 6;
export const PRODUCTS_PER_PAGE =
  parseInt(localStorage.getItem(PRODUCTS_KEY)) || DEFAULT_PRODUCTS_PER_PAGE;
export const INVOICES_KEY = "invoicesPerPage";
export const DEFAULT_INVOICES_PER_PAGE = 8;
export const INVOICES_PER_PAGE =
  parseInt(localStorage.getItem(INVOICES_KEY)) || DEFAULT_INVOICES_PER_PAGE;
export const ORDERS_KEY = "ordersPerPage";
export const DEFAULT_ORDERS_PER_PAGE = 8;
export const ORDERS_PER_PAGE =
  parseInt(localStorage.getItem(ORDERS_KEY)) || DEFAULT_ORDERS_PER_PAGE;
export const PURCHASES_KEY = "perchasesPerPage";
export const DEFAULT_PURCHASES_PER_PAGE = 8;
export const PURCHASES_PER_PAGE =
  parseInt(localStorage.getItem(PURCHASES_KEY)) || DEFAULT_PURCHASES_PER_PAGE;
export const ESTIMATES_KEY = "perchasesPerPage";
export const DEFAULT_ESTIMATES_PER_PAGE = 8;
export const ESTIMATES_PER_PAGE =
  parseInt(localStorage.getItem(ESTIMATES_KEY)) || DEFAULT_ESTIMATES_PER_PAGE;
export const CUSTOMERS_KEY = "customersPerPage";
export const DEFAULT_CUSTOMERS_PER_PAGE = 8;
export const STORE_CUSTOMERS_PER_PAGE =
  parseInt(localStorage.getItem(CUSTOMERS_KEY)) || DEFAULT_CUSTOMERS_PER_PAGE;
export const COUPONS_KEY = "couponsPage";
export const DEFAULT_COUPONS_PER_PAGE = 8;
export const COUPONS_PER_PAGE =
  parseInt(localStorage.getItem(COUPONS_KEY)) || DEFAULT_COUPONS_PER_PAGE;
export const REPORTS_KEY = "reportsPerPage";
export const DEFAULT_REPORTS_PER_PAGE = 5;
export const REPORTS_PER_PAGE =
  parseInt(localStorage.getItem(REPORTS_KEY)) || DEFAULT_REPORTS_PER_PAGE;
export const CARTS_KEY = "cartsPerPage";
export const DEFAULT_CARTS_PER_PAGE = 8;
export const CARTS_PER_PAGE =
  parseInt(localStorage.getItem(CARTS_KEY)) || DEFAULT_CARTS_PER_PAGE;

export const USERS_KEY = "usersPerPage";
export const DEFAULT_USERS_PER_PAGE = 8;
export const USERS_PER_PAGE =
  parseInt(localStorage.getItem(USERS_KEY)) || DEFAULT_USERS_PER_PAGE;

export const REFER_EARN_TRANSACTIONS_KEY = "referAndEarnTransactionsPerPage";
export const DEFAULT_REFER_EARN_TRANSACTIONS_PER_PAGE = 8;
export const REFER_EARN_TRANSACTIONS_PER_PAGE =
  parseInt(localStorage.getItem(REFER_EARN_TRANSACTIONS_KEY)) ||
  DEFAULT_REFER_EARN_TRANSACTIONS_PER_PAGE;

export const getCouponsPerPage = () => {
  return (
    parseInt(localStorage.getItem(COUPONS_KEY)) || DEFAULT_COUPONS_PER_PAGE
  );
};

export const THEME_COLORS = [
  {
    code: "#F30020",
    name: "Color F30020",
  },
  {
    code: "#412F3F",
    name: "Color 412F3F",
  },
  {
    code: "#64584B",
    name: "Color 64584B",
  },
  {
    code: "#654321",
    name: "Color 654321",
  },
  {
    code: "#363636",
    name: "Color 363636",
  },
  {
    code: "#5D801A",
    name: "Color 5D801A",
  },
  {
    code: "#444444",
    name: "Color 444444",
  },
  {
    code: "#F6740A",
    name: "Color F6740A",
  },
  {
    code: "#296FC2",
    name: "Color 296FC2",
  },
  {
    code: "#E02858",
    name: "Color E02858",
  },
  {
    code: "#2A5EE1",
    name: "Color 2A5EE1",
  },
  {
    code: "#29A56C",
    name: "Color 29A56C",
  },
  {
    code: "#F6740C",
    name: "Color F6740C",
  },
];

export const BUSSINESS_TYPES = [
  {
    id: "INDIVIDUAL",
    title: "Individual",
  },
  {
    id: "PARTNERSHIP",
    title: "Partnership",
  },
  {
    id: "PROPRIETORSHIP",
    title: "Proprietorship",
  },
  {
    id: "LLP",
    title: "Limited Liability Partnership",
  },
  {
    id: "PRIVATE_LIMITED",
    title: "Private Limited",
  },
  {
    id: "PUBLIC_LIMITED",
    title: "Public Limited",
  },
  {
    id: "NOT_YET_REGISTERED",
    title: "Not yet Registered",
  },
  {
    id: "TRUST",
    title: "Trust",
  },
  {
    id: "SOCIETY",
    title: "Society",
  },
  {
    id: "EDUCATIONAL_INSTITUTES",
    title: "Educational Institutes",
  },
  {
    id: "OTHER",
    title: "Other",
  },
  {
    id: "NGO",
    title: "NGO",
  },
];

// F30020 -> default
// 412F3F
// 64584B
// 654321 -> FAF3EC
// 363636
// 5D801A
// 444444 -> F7F7F7
// F6740A -> 3A3E46
// 296FC2
// e02858
// 2a5ee1 -> FCE750

export const copyToClipboard = (text) => {
  if (window.clipboardData && window.clipboardData.setData) {
    // Internet Explorer-specific code path to prevent textarea being shown while dialog is visible.
    return window.clipboardData.setData("Text", text);
  } else if (
    document.queryCommandSupported &&
    document.queryCommandSupported("copy")
  ) {
    var textarea = document.createElement("textarea");
    textarea.textContent = text;
    textarea.style.position = "fixed"; // Prevent scrolling to bottom of page in Microsoft Edge.
    document.body.appendChild(textarea);
    textarea.select();
    try {
      return document.execCommand("copy"); // Security exception may be thrown by some browsers.
    } catch (ex) {
      console.warn("Copy to clipboard failed.", ex);
      return false;
    } finally {
      document.body.removeChild(textarea);
    }
  }
};

export const getParticularDayTimestamp = (lastWeekDay) => {
  let currentWeekMonday = new Date().getDate() - new Date().getDay() + 1;
  return new Date().setDate(currentWeekMonday - lastWeekDay);
};

export const getDateRange = (range, returnType = "ISO") => {
  let { aoe, boe } = getDateByRange(range);
  if (aoe) {
    aoe = returnType == "ISO" ? aoe.toISOString() : aoe.getTime();
  }
  if (boe) {
    boe = returnType == "ISO" ? boe.toISOString() : boe.getTime();
  }
  return { aoe, boe };
};

export const getDateByRange = (range) => {
  let aoe = null;
  let boe = null;
  if (range === "All") {
    aoe = null;
    boe = null;
  } else if (range === "Today") {
    aoe = new Date(new Date().setHours(0, 0, 0, 0));
    boe = new Date(new Date().setHours(23, 59, 59, 59));
  } else if (range === "Yesterday") {
    aoe = new Date(
      new Date(new Date().setHours(0, 0, 0, 0)).setDate(
        new Date().getDate() - 1
      )
    );
    boe = new Date(
      new Date(new Date().setHours(23, 59, 59, 59)).setDate(
        new Date().getDate() - 1
      )
    );
  } else if (range === "This Month") {
    aoe = new Date(
      new Date(new Date().getFullYear(), new Date().getMonth(), 1).setHours(
        0,
        0,
        0,
        0
      )
    );
    boe = new Date(new Date().setHours(23, 59, 59, 59));
  } else if (range === "This Week") {
    let sow = new Date();
    let dayOfWeek = sow.getDay();
    let diff = sow.getDate() - dayOfWeek + (dayOfWeek === 0 ? -6 : 1);
    sow.setDate(diff);
    sow.setHours(0, 0, 0, 0);
    aoe = sow;
    boe = new Date(new Date().setHours(23, 59, 59, 59));
  } else if (range === "Last Week") {
    aoe = new Date(new Date(getParticularDayTimestamp(7)).setHours(0, 0, 0, 0));
    boe = new Date(
      new Date(getParticularDayTimestamp(1)).setHours(23, 59, 59, 59)
    );
  } else if (range === "Last Month") {
    aoe = new Date(
      new Date(new Date().getFullYear(), new Date().getMonth() - 1, 1).setHours(
        0,
        0,
        0,
        0
      )
    );
    boe = new Date(
      new Date(new Date().getFullYear(), new Date().getMonth(), 0).setHours(
        23,
        59,
        59,
        59
      )
    );
  }
  return { aoe, boe };
};

export const timeSince = (date) => {
  var seconds = Math.floor((new Date() - date) / 1000);

  var interval = seconds / 31536000;

  if (interval > 1) {
    return Math.floor(interval) + "y ago";
  }
  interval = seconds / 2592000;
  if (interval > 1) {
    return Math.floor(interval) + "m ago";
  }
  interval = seconds / 86400;
  if (interval > 1) {
    return Math.floor(interval) + "d ago";
  }
  interval = seconds / 3600;
  if (interval > 1) {
    return Math.floor(interval) + "h ago";
  }
  interval = seconds / 60;
  if (interval > 1) {
    return Math.floor(interval) + "min ago";
  }
  return (Math.floor(seconds) > 0 ? Math.floor(seconds) : 0) + "s ago";
};

export const timeConvert = (time) => {
  time = time.toString().match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [
    time,
  ];
  if (time.length > 1) {
    time = time.slice(1);
    time[5] = +time[0] < 12 ? " AM" : " PM";
    time[0] = +time[0] % 12 || 12;
  }
  return time.join("");
};
