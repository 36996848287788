import React, { useContext, useEffect, useRef, useState } from "react";
import { ChatWeightContext } from "../../../../../../context/Context";
import ImageHandler from "../../../../../../../../../../components/ImageHandler";
import { RemoveCircleIcon } from "../../../../../../../../../../icons";
import { uploadImage } from "../../../../../../../../../../utils/imageUploader";
import Toast from "../../../../../../../../../../components/Layout/Toast";

const defaultCrop = { x: 0, y: 0, width: 250, height: 250 };

const ProfilePicture = () => {
  const { formData, formDispatch } = useContext(ChatWeightContext);

  const [openImageHandler, setOpenImageHandler] = useState(false);
  const [openImageHandlerIndex, setOpenImageHandlerIndex] = useState(0);
  const [openModal, setOpenModal] = useState(false);
  const [showError, setShowError] = useState(false);
  const [loading, setLoading] = useState(false);

  const [image, setImage] = useState("");
  const [filename, setFilename] = useState("");

  const handleRemove = () => {
    formDispatch("value", {
      name: "profile_icon",
      value: "",
    });
    setImage("");
  };

  useEffect(() => {
    (async () => {
      if (filename && image) {
        setLoading(true);
        try {
          let cloudFile = await uploadImage(filename, image);
          const imageUrl = cloudFile.payload.location;
          formDispatch("value", {
            name: "profile_icon",
            value: imageUrl,
          });
          formDispatch("error", {
            name: "profile_icon",
            error: null,
          });
        } catch (error) {
          handleRemove();
          setShowError(true);
        }
        setLoading(false);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filename, image]);

  return (
    <div>
      <div style={{ display: "flex", alignItems: "center" }}>
        <div
          style={{
            paddingBottom: 24,
            marginTop: 16,
            marginRight: 16,
            position: "relative",
          }}
        >
          <ImageHandler
            cropImg={formData.profile_icon.value}
            setCropImg={(image) => {
              setImage(image);
            }}
            defaultCrop={defaultCrop}
            setFilename={(name) => {
              setFilename(name);
            }}
            defaultAspect="1:1"
            imgFormat="image/png"
            maxSize={600}
            openImageHandler={openImageHandler}
            setOpenImageHandler={setOpenImageHandler}
            openImageHandlerIndex={openImageHandlerIndex}
            openModal={openModal}
            setOpenModal={setOpenModal}
          />
          {formData.profile_icon.value && (
            <div style={{ width: 60, position: "relative" }}>
              <div
                style={{
                  position: "absolute",
                  top: -11,
                  right: -11,
                  cursor: "pointer",
                }}
                onClick={handleRemove}
              >
                <RemoveCircleIcon />
              </div>
              <img
                src={formData.profile_icon.value}
                alt=""
                style={{
                  width: 60,
                  height: 60,
                  borderRadius: 6,
                  objectFit: "contain",
                  cursor: "pointer",
                }}
                onClick={() => {
                  setOpenImageHandler(true);
                  setOpenImageHandlerIndex(0);
                }}
              />
            </div>
          )}
          {formData.profile_icon.error && (
            <span style={{ color: "#f44336", fontSize: 12, marginTop: 8 }}>
              {formData.profile_icon.error}
            </span>
          )}
        </div>
        {loading && (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <img
              style={{
                width: "22px",
                height: "22px",
              }}
              src="https://i.imgur.com/AAR7hrM.gif"
              alt=""
            />
          </div>
        )}
      </div>
      <Toast
        autoClose
        message={"Image uploading failed!"}
        open={showError}
        close={() => setShowError(false)}
        severity="error"
      />
    </div>
  );
};

export default ProfilePicture;
