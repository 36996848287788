import { createColor } from "material-ui-color";
import { useState } from "react";

export const useChatBubbleState = () => {
  const [bubbleStyle, setBubbleStyle] = useState("style2");

  const [chatBubbleColors, setChatBubbleColors] = useState({
    chat_bubble_color: {
      label: "Chat Bubble Color",
      value: createColor("#4DC247").css.backgroundColor,
      name: "chat_bubble_color",
      touched: false,
      error: "",
      color: createColor("#4DC247"),
    },
    chat_icon_text_color: {
      label: "Bubble Icon and text Color",
      value: createColor("#ffffff").css.backgroundColor,
      name: "chat_icon_text_color",
      touched: false,
      error: "",
      color: createColor("#ffffff"),
    },
  });

  const [chatBubblePosition, setChatBubblePosition] = useState("right");

  const [bubbleTextConfig, setBubbleTextConfig] = useState({
    bubble_text: false,
    extend_bubble_when_hovered: false,
  });

  const [bubbleText, setBubbleText] = useState({
    value: "",
    error: null,
  });

  /* Color */
  const chatBubbleColorDispatch = (name, payload) => {
    setChatBubbleColors((chatBubbleColors) => ({
      ...chatBubbleColors,
      [name]: {
        ...chatBubbleColors[name],
        ...payload,
      },
    }));
  };

  return {
    bubbleStyle,
    bubbleStyleDispatch: setBubbleStyle,
    chatBubbleColors,
    setChatBubbleColors,
    chatBubbleColorDispatch,
    chatBubblePosition,
    chatBubblePositionDispatch: setChatBubblePosition,
    bubbleTextConfig,
    bubbleTextConfigDispatch: setBubbleTextConfig,
    bubbleText,
    bubbleTextDispatch: setBubbleText,
  };
};
