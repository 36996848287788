import { Paper } from "@material-ui/core";
import React, { useContext } from "react";
import { layoutCenterContainer } from "../../../styles/common";
import HeaderBreadcrumbs from "../../../components/common/HeaderBreadcrumbs";
import useStyle from "../components/ConfigStyles";
import Provider from "./chat-widget/context/Provider";
import ChatWindow from "./chat-widget/components/Left/components/ChatWindow";
import ChatBubble from "./chat-widget/components/Left/components/ChatBubble";
import Right from "./chat-widget/components/Right";
import Saving from "./chat-widget/components/Saving/Saving";
import PluginSwitch from "./chat-widget/components/Left/components/PluginSwitch/PluginSwitch";
import WhatsappNumber from "./chat-widget/components/Left/components/WhatsappNumber/WhatsappNumber";
import { ChatWeightContext } from "./chat-widget/context/Context";
import PageLoader from "../../../components/Layout/PageLoader";
import { useFontFamilyLoader } from "./chat-widget/hooks/useFontFamilyLoader";

const list = [
  {
    label: "App Store",
    link: "/apps",
  },
  { label: "Whatsapp Chat Bubble", link: "/apps/whatsapp-chat-bubble" },
  {
    label: "Configure",
  },
];

export const WhatsappChatBubble = () => {
  return (
    <Provider>
      <Components />
    </Provider>
  );
};

const Components = () => {
  const classes1 = useStyle();
  const { loading } = useContext(ChatWeightContext);

  useFontFamilyLoader();
  if (loading) return <PageLoader />;

  return (
    <div className={layoutCenterContainer}>
      <div style={{ width: "100%" }}>
        <div className={classes1.breadcrumbsContainer}>
          <div className={classes1.backDiv}>
            <HeaderBreadcrumbs list={list} />
          </div>
        </div>
        <div style={{ display: "flex", margin: "-10px" }}>
          <div style={{ width: "50%" }}>
            <div style={{ padding: "10px" }}>
              <Paper
                elevation={0}
                style={{
                  padding: "20px",
                  boxShadow: "0px 1px 3px 0px #0000001A",
                  borderRadius: 8,
                }}
              >
                <PluginSwitch />
              </Paper>
            </div>
            <div style={{ padding: "10px" }}>
              <Paper
                elevation={0}
                style={{
                  padding: "20px",
                  boxShadow: "0px 1px 3px 0px #0000001A",
                  borderRadius: 8,
                }}
              >
                <WhatsappNumber />
              </Paper>
            </div>
            <div style={{ padding: "10px" }}>
              <Paper
                elevation={0}
                style={{
                  padding: "20px",
                  boxShadow: "0px 1px 3px 0px #0000001A",
                  borderRadius: 8,
                }}
              >
                <ChatWindow />
              </Paper>
            </div>
            <div style={{ padding: "10px" }}>
              <Paper
                elevation={0}
                style={{
                  padding: "20px",
                  boxShadow: "0px 1px 3px 0px #0000001A",
                  borderRadius: 8,
                }}
              >
                <ChatBubble />
              </Paper>
            </div>
            <div style={{ padding: "10px" }}>
              <Paper
                elevation={0}
                style={{
                  padding: "20px",
                  boxShadow: "0px 1px 3px 0px #0000001A",
                  borderRadius: 8,
                }}
              >
                <Saving />
              </Paper>
            </div>
          </div>
          <div style={{ width: "50%" }}>
            <div style={{ padding: "10px", position: "sticky", top: 84 }}>
              <Paper
                elevation={0}
                style={{
                  padding: "20px",
                  boxShadow: "0px 1px 3px 0px #0000001A",
                  borderRadius: 8,
                }}
              >
                <div
                  style={{
                    padding: "12px 0",
                    borderRadius: 8,
                    position: "relative",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <Right />
                </div>
              </Paper>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
