import React, { useEffect, useState } from "react";
import useStyles from "../components/ConfigStyles";
import { IconButton, InputAdornment } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchApps,
  getPluginConfig,
  postPluginConfig,
} from "../../../redux/apps/appActions";
import PageLoader from "../../../components/Layout/PageLoader";
import { centerContainer, layoutCenterContainer } from "../../../styles/common";
import HeaderBreadcrumbs from "../../../components/common/HeaderBreadcrumbs";
import { Paper, Typography } from "@material-ui/core";
import Toast from "../../../components/Layout/Toast";
import Controls from "../../../components/controls/Controls";
import FilledEditButton from "../../../components/buttons/FilledEditButton";
import CombinedButtons from "../../../components/buttons/CombinedButtons";
import { copyToClipboard } from "../../../utils/utils";
import {
  RemoveRedEyeOutlined,
  VisibilityOffOutlined,
} from "@material-ui/icons";
import WebhookDetails from "./WebhookDetails";

const list = [
  {
    label: "App Store",
    link: "/apps",
  },
  {
    label: "eshipz",
    link: "/apps/eshipz",
  },
  {
    label: "Configure",
  },
];

const eShipZLink =
  "https://app.eshipz.com/login?utm_source=homepage&utm_medium=login";

export default function Eshipz() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();

  const storeData = useSelector((state) => state.store);
  const appState = useSelector((state) => state.apps);
  const apps = appState.apps;

  const initialState = {
    email: {
      value: "",
      touched: false,
      error: null,
    },
    password: {
      value: "",
      touched: false,
      error: null,
    },
    vendor_id: {
      value: "",
      touched: false,
      error: null,
    },
  };

  const [formState, setFormState] = useState(initialState);
  const [isEditing, setIsEditing] = useState(true);
  const [loading, setLoading] = useState(true);
  const [showPassword, setShowPassword] = useState(false);
  const [pluginLoading, setPluginLoading] = useState(true);
  const [savingConfig, setSavingConfig] = useState(false);
  const [success, setSuccess] = useState(null);
  const [error, setError] = useState(null);
  console.log(formState);
  useEffect(() => {
    if (apps.length === 0) {
      dispatch(fetchApps());
    }
    // eslint-disable-next-line
  }, [apps.length]);

  useEffect(() => {
    if (storeData.fetched) {
      dispatch(getPluginConfig("eshipz"));
    }
    //eslint-disable-next-line
  }, [storeData]);
  useEffect(() => {
    if (appState.eshipz && typeof appState.eshipz === "object") {
      if (!Object.keys(appState.eshipz).length) {
        setPluginLoading(false);
      }
      if (Object.keys(appState.eshipz).length > 0) {
        setPluginLoading(false);
        const formData = Object.keys(appState.eshipz).reduce((acc, key) => {
          acc[key] = {
            value: appState.eshipz[key],
            touched: false,
            error: null,
          };
          return acc;
        }, {});

        setIsEditing(false);
        setFormState(formData);
      }
    } else {
      setPluginLoading(false);
    }
    setLoading(false);
  }, [appState.eshipz]);

  const handleChange = (event) => {
    let { name, value } = event.target;

    setFormState((prevState) => ({
      ...prevState,
      [name]: {
        ...prevState[name],
        value: value,
        touched: true,
      },
    }));
  };
  const handleCopyToClipboard = (text) => () => {
    const result = copyToClipboard(text);
    if (!result) {
      setError("Could not copy to clipboard");
    } else {
      setSuccess("Copied to clipboard");
    }
  };
  const getMessage = (name) => {
    const messages = {
      email: "please enter valid email",
      licenseKey: "Please enter valid License Key",
      vendorId: "Please enter a valid Vendor ID",
    };
    return messages[name] || null;
  };

  const onBlur = (event) => {
    const { name } = event.target;
    setFormState((prevState) => ({
      ...prevState,
      [name]: {
        ...prevState[name],
        error: !prevState[name].value ? getMessage(name) : "",
      },
    }));
  };

  const onFocus = (event) => {
    const { name } = event.target;
    setFormState((prevState) => ({
      ...prevState,
      [name]: {
        ...prevState[name],
        error: null,
      },
    }));
  };
  const handleEdit = () => {
    setIsEditing(true);
    setFormState((prevState) => ({
      ...prevState,
      password: {
        ...prevState.password,
        touched: true, // Remove the value reset
      },
    }));
  };

  const validatation = () => {
    let errorFlag = false;
    const updatedFormData = Object.keys(formState).reduce((acc, key) => {
      let isEmpty = !formState[key].value;
      let errorMessage;

      if (isEmpty) {
        errorFlag = true;
        errorMessage = getMessage(key);
      }

      const updatedField = {
        ...formState[key],
        error: errorMessage,
      };

      return { ...acc, [key]: updatedField };
    }, {});
    setFormState(updatedFormData);
    return errorFlag;
  };

  const handleOutlined = () => {
    if (isEditing && Object.keys(appState.eshipz).length > 0) {
      const formData = Object.keys(appState.eshipz).reduce((acc, key) => {
        acc[key] = {
          value: appState.eshipz[key],
          touched: false,
          error: null,
        };
        return acc;
      }, {});
      setFormState(formData);
      setIsEditing(false);
    } else {
      history.goBack();
    }
  };

  const handleSave = () => {
    let isError = validatation();
    if (isError) return;

    setSavingConfig(true);
    dispatch(
      postPluginConfig("eshipz", {
        email: formState.email.value,
        password: formState.password.value,
        vendor_id: formState.vendor_id.value,
      })
    ).then((configObj) => {
      if (configObj.error) {
        setError(configObj.message);
        setSavingConfig(false);
      } else {
        setSuccess("eshipz configuration settings saved successfully.");
        setSavingConfig(false);
      }
    });
  };
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  if (loading || pluginLoading) return <PageLoader />;

  return (
    <div className={layoutCenterContainer}>
      <div className={centerContainer}>
        <div className={classes.breadcrumbsContainer}>
          <div className={classes.backDiv}>
            <HeaderBreadcrumbs list={list} />
          </div>
        </div>
        <Paper elevation={0} className={classes.paper}>
          <div>
            <div className={classes.headingButtonSection}>
              <Typography className={classes.formHead}>
                eShipz Configuration Settings
              </Typography>
              {!isEditing && <FilledEditButton onClick={handleEdit} />}
            </div>
            <div>
              <Controls.Input
                name="email"
                label="Email"
                labelPlacement="top"
                value={formState.email.value}
                onChange={handleChange}
                onFocus={onFocus}
                onBlur={onBlur}
                error={formState.email.error}
                helperText={
                  !formState.email.error &&
                  "Enter your registered email with eshipz."
                }
                extraSpace={formState.email.error && true}
              />

              <Controls.Input
                name="password"
                label="Password"
                labelPlacement="top"
                value={formState.password.value}
                onChange={handleChange}
                onFocus={onFocus}
                onBlur={onBlur}
                error={formState.password.error}
                type={showPassword ? "text" : "password"} // Toggle between 'text' and 'password'
                InputProps={{
                  endAdornment: (
                    <InputAdornment
                      position="end"
                      style={{ marginRight: "-15px" }}
                    >
                      <IconButton onClick={togglePasswordVisibility}>
                        {showPassword ? (
                          <VisibilityOffOutlined />
                        ) : (
                          <RemoveRedEyeOutlined />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />

              <Controls.Input
                name="vendor_id"
                label="Vendor ID"
                labelPlacement="top"
                value={formState.vendor_id.value}
                onChange={handleChange}
                onFocus={onFocus}
                onBlur={onBlur}
                error={formState.vendor_id.error}
                helperText={
                  !formState.vendor_id.error &&
                  "Enter your Vendor ID provided by eshipz."
                }
                extraSpace={formState.vendor_id.error && true}
              />
            </div>
          </div>
          {!isEditing && (
            <WebhookDetails
              formData={formState}
              handleCopyToClipboard={handleCopyToClipboard}
            />
          )}
          {isEditing && (
            <div className={classes.button}>
              <CombinedButtons
                outlinedBtnText="Back"
                outlinedBtnAction={handleOutlined}
                solidBtnAction={handleSave}
                loading={savingConfig}
              />
            </div>
          )}
        </Paper>
      </div>
      <Toast
        autoClose
        message={success}
        open={!!success}
        close={() => setSuccess(null)}
      />

      <Toast
        autoClose
        message={error}
        open={!!error}
        close={() => setError(null)}
        severity="error"
      />
    </div>
  );
}
