export const messageTemplates = [
  { id: "order_placed", title: "Order Placed" },
  { id: "order_confirmed", title: "Order Confirmed" },
  { id: "order_dispatched", title: "Order Dispatched" },
  { id: "order_delivered", title: "Order Delivered" },
  /*
  { id: "otp_message", title: "OTP Message" },
*/
  {
    id: "order_cancelled",
    title: "Order Cancelled",
  },
  {
    id: "abandoned_cart",
    title: "Abandoned Cart",
  },
  {
    id: "new_order_received",
    title: "New Order Received (Admin)",
  },
];

export const requestChannels = async (appState) => {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_BAAT_API}/v1/remote/account/channels`,
      {
        method: "GET",
        headers: {
          "X-API-KEY": appState.baat["X-API-KEY"],
        },
      }
    );
    const data = await response.json();
    return data;
  } catch (error) {
    console.log(error);
  }
};

export const requestTemplates = async (appState, channelId) => {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_BAAT_API}/v1/remote/account/channels/${channelId}/whatsapp-templates`,
      {
        method: "GET",
        headers: {
          "X-API-KEY": appState.baat["X-API-KEY"],
        },
      }
    );
    const { data } = await response.json();
    const result = data.filter((template) => {
      return template.status === "APPROVED";
    });
    return result;
  } catch (error) {
    console.log(error);
  }
};

export const requestresult = (appState, templatesData) => {
  const getParameter = (key) => {
    if (!appState.baat[key]) {
      return {};
    }
    const { body_params, header_params, button_params } = JSON.parse(
      appState.baat[key]
    ).message;
    const { send_duration } = JSON.parse(appState.baat[key]);
    const result = {};
    if (body_params && body_params.length > 0) {
      body_params.forEach((param, index) => {
        result[`${key}|BODY|{{${index + 1}}}`] = {
          value: param,
          error: false,
        };
      });
    }
    if (header_params && header_params.length > 0) {
      header_params.forEach((param, index) => {
        result[`${key}|HEADER|{{${index + 1}}}`] = {
          value: param,
          error: false,
        };
      });
    }
    if (button_params && button_params.length > 0) {
      button_params.forEach((param, index) => {
        result[`${key}|BUTTONS|{{${index + 1}}}`] = {
          value: param,
          error: false,
        };
      });
    }
    if (key === "abandoned_cart" && send_duration) {
      Object.keys(send_duration).forEach((param) => {
        if (send_duration[param] > 0) {
          result[`${key}|TIME|{{1}}`] = {
            value: send_duration[param],
            error: false,
          };
          result[`${key}|TIME|{{2}}`] = {
            value: param,
            error: false,
          };
        }
      });
    }
    return result;
  };

  const result = Object.keys(templatesData).reduce((acc, key) => {
    return {
      ...acc,
      [key]: {
        ...templatesData[key],
        show: appState.baat[key] ? true : false,
        template_name: appState.baat[key]
          ? JSON.parse(appState.baat[key]).message.template_name
          : "",
        parameters: getParameter(key),
      },
    };
  }, {});

  return result;
};

export const getInitialParameters = (templates, templateName, templateId) => {
  const initialParam = {};
  templates &&
    templates.forEach((temp) => {
      if (temp.name === templateId) {
        const { components } = temp;
        components.forEach((component) => {
          if (component.text) {
            let matches = component.text.match(/{{\d+}}/g);
            if (matches) {
              matches.forEach((match) => {
                initialParam[`${templateName}|${component.type}|${match}`] = {
                  value: "",
                  error: false,
                };
              });
            }
          }

          if (
            !component.text &&
            component.type === "HEADER" &&
            component.format === "IMAGE"
          ) {
            initialParam[`${templateName}|${component.type}|{{1}}`] = {
              value: "",
              format: "IMAGE",
              error: false,
            };
          }

          if (component.buttons && component.type === "BUTTONS") {
            let matches;
            component.buttons.forEach((button) => {
              matches = button.url && button.url.match(/{{\d+}}/g);
            });

            if (matches) {
              matches.forEach((match) => {
                initialParam[`${templateName}|${component.type}|${match}`] = {
                  value: "",
                  error: false,
                };
              });
            }
          }

          if (templateName === "abandoned_cart") {
            for (let i = 1; i <= 2; i++) {
              if (i === 2) {
                initialParam[`${templateName}|TIME|{{${i}}}`] = {
                  value: "hours",
                  error: false,
                };
              } else {
                initialParam[`${templateName}|TIME|{{${i}}}`] = {
                  value: "",
                  error: false,
                };
              }
            }
          }
        });
      }
    });
  return initialParam;
};

export const getChannelsOptions = (channels) => {
  const getChannelNumber = (channel) => {
    const { channel_properties } = channel;
    let number;
    channel_properties.forEach((property) => {
      if (property.name === "display_phone_number") {
        number = property.value;
      }
    });
    return number;
  };

  const getChannelTitle = (channel) => {
    let number = getChannelNumber(channel);
    if (channel.type === "WAC_API") {
      return `WhatsApp  ${number}`;
    }
  };

  const options = [];
  channels.length > 0 &&
    channels.forEach((channel) => {
      options.push({
        id: channel.id,
        type: channel.type,
        title: getChannelTitle(channel),
        img: "https://storage.googleapis.com/shy-pub/_static/app-img/whatsapp-business.png ",
      });
    });

  return options;
};

export const FieldExist = (templates, templateName, type) => {
  let exist = false;
  templates.forEach((template) => {
    if (template.name === templateName) {
      const { components } = template;
      components.forEach((comp) => {
        if (comp.type === type) {
          exist = true;
        }
      });
    }
  });
  return exist;
};

export const headerFormat = (templates, templateName) => {
  let format;
  templates.forEach((template) => {
    if (template.name === templateName) {
      const { components } = template;
      components.forEach((comp) => {
        if (comp.type === "HEADER") {
          format = comp.format;
        }
      });
    }
  });

  return format;
};

export const requestText = (templates, templateName, type) => {
  if (type === "BUTTONS") {
    const buttons = [];
    templates.forEach((template) => {
      if (template.name === templateName) {
        const { components } = template;
        components.forEach((component) => {
          if (component.type === type) {
            component.buttons.forEach((button) => {
              buttons.push(button);
            });
          }
        });
      }
    });
    return buttons;
  } else {
    let text;
    templates.forEach((temp) => {
      if (temp.name === templateName) {
        const { components } = temp;
        components.forEach((comp) => {
          if (comp.text && comp.type === type) {
            text = comp.text;
          }

          if (comp.buttons && comp.type === type) {
            text = comp.buttons[0].url;
          }
        });
      }
    });

    return text ? text : null;
  }
};

export const requestParams = (templatesData, templateName, type) => {
  let params = [];
  if (Object.keys(templatesData[templateName].parameters).length > 0) {
    const { parameters } = templatesData[templateName];
    let typeExists = false;
    Object.keys(parameters).forEach((param) => {
      if (param.split("|")[1] === type) {
        typeExists = true;
        params.push(parameters[param].value);
      }
    });
    if (typeExists) {
      return params;
    } else {
      return [];
    }
  } else {
    return [];
  }
};

export const requestLanguage = (templates, templateName) => {
  let language;
  templates.forEach((temp) => {
    if (temp.name === templateName) {
      language = temp.language;
    }
  });
  return language;
};

export const getLabel = (param) => {
  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  const type = capitalizeFirstLetter(param.split("|")[1].toLowerCase());
  const title = param.split("|")[2];

  if (title === "{{1}}") {
    return `${type} Parameter`;
  } else {
    return "";
  }
};

export const getPlaceholder = (param, format) => {
  const template = param.split("|")[0];
  const type = param.split("|")[1].toLowerCase();
  const title = param.split("|")[2];

  if (
    template === "abandoned_cart" &&
    type === "header" &&
    format !== undefined
  ) {
    return `Select ${format.toLowerCase()} parameter`;
  } else {
    return `Select ${type} parameter ${title}`;
  }
};

export const getTimePlaceholder = (param, templateData) => {
  const template = param.split("|")[0];
  const type = param.split("|")[1];
  if (templateData.parameters[`${template}|${type}|{{2}}`].value === "hours") {
    return "Hours";
  } else if (
    templateData.parameters[`${template}|${type}|{{2}}`].value === "days"
  ) {
    return "Days";
  } else {
    return "Mintues";
  }
};

export const validateTimeDuration = (param, unit) => {
  const validateNumber = (number) => {
    return isNaN(number) ? true : false;
  };

  let err = "";
  if (validateNumber(param.value)) {
    param.error = true;
    err = "Invalid number";
  }
  if (unit === "days" && param.value !== "") {
    if (param.value > 30 || param.value < 1) {
      param.error = true;
      err = "Days should be between 1 to 30";
    }
  }
  if (unit === "hours" && param.value !== "") {
    if (param.value > 24 || param.value < 1) {
      param.error = true;
      err = "Hours should be between 1 to 24";
    }
  }

  if (unit === "minutes" && param.value !== "") {
    if (param.value > 60 || param.value < 1) {
      param.error = true;
      err = "Minutes should be between 1 to 60";
    }
  }

  return err;
};
