import React from "react";
import { useChatBubbleGenerator } from "../../hooks/useChatBubbleGenerator";
import { useOutputData } from "../../hooks/useOutputData";
import BubbleBackdrop from "./assets/BubbleBackdrop";

const ChatBubble = () => {
  const chatBubbleHtml = useChatBubbleGenerator();
  const { jsdata } = useOutputData();
  return (
    <div style={{ display: "flex", justifyContent: "center" }}>
      <div style={{ position: "relative" }}>
        <BubbleBackdrop />
        <div
          className="cb12_wrapper"
          style={{
            position: "absolute",
            bottom: "12px",
            ...(jsdata.cb_position === "left"
              ? { left: "12px" }
              : { right: "12px" }),
            width: "fit-content",
          }}
          dangerouslySetInnerHTML={{ __html: chatBubbleHtml }}
        ></div>
      </div>
    </div>
  );
};

export default ChatBubble;
