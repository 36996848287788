import React, { useContext } from "react";
import Input from "../../../../../../../../components/controls/Input";
import { ChatWeightContext } from "../../../../context/Context";

const WhatsappNumber = () => {
  const { formData, formDispatch, onBlur, onFocus } =
    useContext(ChatWeightContext);

  const handleChange = (e) => {
    formDispatch("value", {
      name: e.target.name,
      value: e.target.value,
    });
  };

  return (
    <div>
      <Input
        label="Whatsapp Number"
        value={formData.whatsapp_number.value}
        onChange={handleChange}
        onBlur={onBlur}
        onFocus={onFocus}
        name="whatsapp_number"
        error={!!formData.whatsapp_number.error}
        helperText={formData.whatsapp_number.error}
        placeholder="+91 0000000000"
        labelFontWeight={400}
        labelPlacement="top"
        hideHelperText
        type="number"
      />
    </div>
  );
};

export default WhatsappNumber;
