import { style } from "typestyle";

export const layoutContainer = style({
  width: "100%",
});

export const layoutCenterContainer = style({
  width: "100%",
  display: "flex",
  justifyContent: "center",
});

export const centerContainer = style({
  width: "50%",
});

export const layoutSubHeader = style({
  width: "100%",
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  marginTop: 10,
});

export const inputTag = style({
  marginLeft: 8,
  borderRadius: 6,
  color: "#1641DB",
  backgroundColor: "#E9EEFF",
  padding: "2px 8px 4px 8px",
});

export const blueButton = style({
  outline: "none !important",
  background: "#1641DB",
  color: "white",
  borderRadius: 6,
  height: 46,
  fontWeight: 600,
  fontSize: 16,
  padding: 16,
  textTransform: "none",
  minWidth: "9rem",
  whiteSpace: "nowrap",
  boxShadow: "none !important",
  "&:hover": {
    background: "#1641DB",
  },
});

export const outlineButton = style({
  border: "1px solid #1641DB",
  color: "#1641DB",
  background: "#FFFFFF",
  borderRadius: 6,
  fontWeight: 600,
  fontSize: 16,
  padding: 16,
  minWidth: 149,
  textTransform: "none",
  height: 46,
  boxShadow: "none !important",
  outline: "none !important",
  whiteSpace: "nowrap",
  "&:hover": {
    background: "#FFFFFF",
  },
});

export const ContactSalesLink = style({
  color: "blue",
  textDecoration: "none",
  marginLeft: "5px",
  "&:hover": {
    color: "blue",
    textDecoration: "none",
  },
});
export const transparentButton = style({
  color: "#1641DB",
  background: "none",
  fontWeight: 600,
  padding: 16,
  textTransform: "none",
  height: 46,
  boxShadow: "none !important",
  outline: "none !important",
  whiteSpace: "nowrap",
  width: "100%",
  fontSize: 15,
  "&:hover": {
    background: "none",
  },
});

export const delButton = style({
  background: "#ED0000",
  color: "white",
  borderRadius: 6,
  height: 46,
  fontWeight: 600,
  fontSize: 16,
  padding: 16,
  textTransform: "none",
  minWidth: 149,
  boxShadow: "none !important",
  outline: "none !important",
  "&:hover": {
    background: "#ED0000",
    color: "white",
  },
});

export const textButton = style({
  textTransform: "none",
  textDecoration: "underline",
  fontWeight: "600",
  fontSize: "16px",
  lineHeight: "20px",
  padding: "0px",
  "&:focus": {
    outline: "none !important",
  },
});

export const layoutHeading = style({
  fontWeight: 600,
  fontSize: 20,
  lineHeight: "28px",
  color: "#1A1A1A",
  userSelect: "none",
});

export const layoutBodyBox = style({
  marginTop: 30,
  backgroundColor: "#ffffff",
  boxShadow: "0px 8px 20px rgba(0,0,0,0.04)",
  borderRadius: 10,
  padding: "24px 0px 0px 0px",
  width: "100%",
});

export const rowFlexAlignCenter = style({
  display: "flex",
  alignItems: "center",
});

export const rowFlexAlignBaseline = style({
  display: "flex",
  alignItems: "baseline",
});

export const rowFlexAlignStart = style({
  display: "flex",
  alignItems: "flex-start",
});

export const rowFlexCenterAll = style({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
});

export const rowFlexJustifyBetween = style({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
});

export const rowFlexOnlyJustifyBetween = style({
  display: "flex",
  justifyContent: "space-between",
  width: "100%",
});

export const rowFlexOnlyJustifyEnd = style({
  display: "flex",
  justifyContent: "flex-end",
});

export const rowFlexOnlyJustifyBetweenCenter = style({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
});

export const rowFlexOnlyJustifyBetweenStart = style({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "start",
});

export const colFlexJustifyBetween = style({
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
});

export const clickable = style({
  cursor: "pointer",
});

export const container = style({
  paddingLeft: 0,
  paddingRight: 0,
  paddingTop: 64,
  minHeight: "calc(100vh * var(--device-pixel-ratio))",
});
export const progressbar = style({
  position: "fixed",
  top: "50%",
  left: "50%",
});

export const circular = style({ color: "#1641DB" });

export const tag = style({
  padding: "4px 8px",
  marginLeft: 4,
  borderRadius: 16,
  fontSize: 10,
  lineHeight: "12px",
  background: "rgba(34,215,255,0.15)",
  color: "#1a1a1a",
  whiteSpace: "nowrap",
});

export const layoutLoaderHeader = style({
  display: "flex",
  justifyContent: "center",
  marginTop: 17,
  // background: "#FFFFFF",
});
export const allplansHeader = style({
  background: "#FFFFFF",
  marginTop: "20px",
});
export const nopicctn = style({
  border: "1px solid #E5E5E5",
  boxSizing: "border-box",
  borderRadius: "6px",
  width: 60,
  height: 60,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
});

export const iconStyle = style({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  border: "1.5px solid #3D5FDB",
  width: 30,
  height: 30,
  borderRadius: "50%",
  marginLeft: 14,
  cursor: "pointer",
});

export const loadingCircular = style({
  position: "fixed",
  left: "50%",
  top: "50%",
  zIndex: "900",
});

export const listPoints = style({
  listStyle: "disc",
  marginLeft: 16,
  "& .MuiListItem-root": {
    display: "list-item",
    paddingLeft: 4,
  },
});

export const primaryPoint = style({
  "& span": {
    fontWeight: "bold",
  },
});

export const row = style({
  display: "grid",
  gap: "28px",
  gridTemplateColumns: "1fr 1fr",
  marginBottom: "16px",
  borderBottom: "1px solid #cccccc",
});

export const anchor = style({
  "& li": {
    color: "#1641DB",
    fontSize: 12,
    lineHeight: "16px",
  },
});
