import React from "react";
import { InputAdornment, TextField, makeStyles } from "@material-ui/core";

const useCustomStyles = makeStyles({
  input: {
    fontSize: "12px",
    "&::placeholder": {
      fontSize: 12,
    },
    "&::-webkit-inner-spin-button": {
      "-webkit-appearance": "none",
      margin: 0,
    },
  },
});

export const DimensionField = ({ value, placeholder, onChange, onBlur }) => {
  const classes = useCustomStyles();
  return (
    <TextField
      style={{ flexBasis: "24.5%" }}
      variant="outlined"
      placeholder={placeholder}
      type="number"
      value={value}
      color="secondary"
      onChange={onChange}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <p
              style={{
                margin: 0,
                color: "#666",
                fontSize: "10px",
                lineHeight: "20px",
              }}
            >
              Cms
            </p>
          </InputAdornment>
        ),
        classes: {
          input: classes.input,
        },
      }}
    />
  );
};
