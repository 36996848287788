import React from "react";
import { useChatWindowGenerator } from "../../hooks/useChatWindowGenerator";

const ChatWindow = () => {
  const chatWindowHtml = useChatWindowGenerator();
  return (
    <div
      className="cb12_wrapper"
      style={{
        position: "static",
        margin: "auto",
        width: "fit-content",
        marginBottom: "24px",
      }}
      dangerouslySetInnerHTML={{ __html: chatWindowHtml }}
    ></div>
  );
};

export default ChatWindow;
