import { Typography } from "@material-ui/core";
import React, { useContext } from "react";
import Switch from "../../../../../../../../../../../../components/controls/Switch";
import { ChatWeightContext } from "../../../../../../../../context/Context";
import Input from "../../../../../../../../../../../../components/controls/Input";

const BubbleText = () => {
  const {
    chatBubbleStates: {
      bubbleTextConfig,
      bubbleTextConfigDispatch,
      bubbleText,
      bubbleTextDispatch,
    },
  } = useContext(ChatWeightContext);

  const handleEnableDisableBubbleText = () => {
    if (bubbleTextConfig.bubble_text) {
      bubbleTextConfigDispatch({
        ...bubbleTextConfig,
        bubble_text: !bubbleTextConfig.bubble_text,
        extend_bubble_when_hovered: false,
      });
      bubbleTextDispatch({
        value: "",
        error: null,
      });
    } else {
      bubbleTextConfigDispatch({
        ...bubbleTextConfig,
        bubble_text: !bubbleTextConfig.bubble_text,
        extend_bubble_when_hovered: false,
      });
    }
  };

  return (
    <div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          gap: "12px",
        }}
      >
        <Typography style={{ fontSize: "14px", fontWeight: "400" }}>
          Bubble Text
        </Typography>
        <Switch
          value={bubbleTextConfig.bubble_text}
          onChange={handleEnableDisableBubbleText}
        />
      </div>

      {bubbleTextConfig.bubble_text && (
        <div style={{ paddingTop: "24px" }}>
          <Input
            label="Add Bubble Text"
            value={bubbleText.value}
            onChange={(e) =>
              bubbleTextDispatch({
                ...bubbleText,
                value: e.target.value,
              })
            }
            onBlur={(e) =>
              bubbleTextDispatch({
                ...bubbleText,
                error:
                  bubbleText.value.trim() === ""
                    ? "This field is required"
                    : null,
              })
            }
            onFocus={(e) =>
              bubbleTextDispatch({
                ...bubbleText,
                error: null,
              })
            }
            name="bubble_text"
            error={!!bubbleText.error}
            helperText={bubbleText.error}
            style={{ marginBottom: "24px" }}
            placeholder="Start Chat"
            autoFocus
            labelFontWeight={400}
            labelPlacement="top"
            hideHelperText
          />

          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              gap: "12px",
            }}
          >
            <Typography style={{ fontSize: "14px", fontWeight: "400" }}>
              Extend Bubble when hovered
            </Typography>
            <Switch
              value={bubbleTextConfig.extend_bubble_when_hovered}
              onChange={() =>
                bubbleTextConfigDispatch({
                  ...bubbleTextConfig,
                  extend_bubble_when_hovered:
                    !bubbleTextConfig.extend_bubble_when_hovered,
                })
              }
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default BubbleText;
