import axios from "axios";
import { isCustomDomain } from "./utils";

export const NUMBER_OF_FREE_TRIAL_DAYS = 14;

export const setupStores = (stores) => {
  localStorage.setItem("stores", JSON.stringify(stores));
  if (stores.length > 0) {
    localStorage.setItem("store", stores[0].store_id);
  }
};

export const storeCreateDays = (store) => {
  let currTime = new Date().getTime();
  let storeTime = new Date(store.created_at).getTime();
  let dayUsed = (currTime - storeTime) / (1000 * 3600 * 24);
  return Math.floor(dayUsed);
};

export const switchStore = (storeId) => {
  localStorage.setItem("store", storeId);
};

export const isLoggedIn = () => {
  let token = localStorage.getItem("access_token");
  return token !== null && token !== "";
};

export const getStores = () => {
  let value = localStorage.getItem("stores");
  let stores = [];
  if (value) {
    stores = JSON.parse(value);
  }
  return stores;
};

export const getDefaultStore = () => {
  let stores = getStores();
  let storeId = getStoreIdFromUrl();
  let store = {};
  // eslint-disable-next-line
  let matchRecs = stores.filter((st) => st.store_id == storeId);
  if (matchRecs.length > 0) {
    store = matchRecs[0];
  }
  return store;
};

export const getStoreIdFromUrl = () => {
  let path = window.location.pathname;
  let lookIndex = 1;
  if (path.startsWith("/admin")) {
    lookIndex = 2;
  }
  const toks = path.split("/");
  let storeId = "";
  if (toks && toks.length > lookIndex) {
    storeId = toks[lookIndex];
  }
  return storeId;
};

export const getHostedSubPath = (storeId, subPath) => {
  let customDomain = isCustomDomain();
  return customDomain ? `/admin/${storeId}${subPath}` : `/${storeId}${subPath}`;
};

export const cleanupStores = () => {
  localStorage.removeItem("stores");
  localStorage.removeItem("store");
};

export const getStoreUrl = (store, addslug = false) => {
  let match = store.domain?.endsWith("shoopy.in");
  return (
    "https://" +
    (match ? process.env.REACT_APP_WEB : store.domain) +
    "/" +
    (match || addslug ? store.slug : "")
  );
};

export const updateAttributes = async (
  slug,
  groupNames,
  payload,
  ui = false
) => {
  let accessToken = localStorage.getItem("access_token");
  try {
    let response = await axios.post(
      `${
        process.env.REACT_APP_API
      }/api/v1/org/store/${slug}/group-names/${groupNames}/attributes${
        ui ? "/ui" : ""
      }`,
      payload,
      {
        headers: {
          "X-Requested-With": "XMLHttpRequest",
          Authorization: `Bearer ${accessToken}`,
          "Content-Type": "application/json",
        },
      }
    );
    return response.data;
  } catch (err) {
    console.log("Api Failure", err);
  }
};

export const getStoreAttributes = async (slug, groupName) => {
  let accessToken = localStorage.getItem("access_token");
  let response = await axios.get(
    `${process.env.REACT_APP_API}/api/v1/org/store/${slug}/group-names/${groupName}/attributes`,
    {
      headers: {
        "X-Requested-With": "XMLHttpRequest",
        Authorization: `Bearer ${accessToken}`,
      },
    }
  );
  let attrs = response.data.payload;
  return attrs.reduce(function (map, obj) {
    map[obj.name] = obj.value;
    return map;
  }, {});
};

export const toMap = (records, key) => {
  return records.reduce(function (map, obj) {
    map[obj[key]] = obj;
    return map;
  }, {});
};

export const getStoreAttribute = async (slug, groupName, name) => {
  let accessToken = localStorage.getItem("access_token");
  let response = await axios.get(
    `${process.env.REACT_APP_API}/api/v1/org/store/${slug}/group-names/${groupName}/attributes/${name}`,
    {
      headers: {
        "X-Requested-With": "XMLHttpRequest",
        Authorization: `Bearer ${accessToken}`,
      },
    }
  );
  return response.data.payload;
};
